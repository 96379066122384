export default function reducer(
    state = {
        list: [],
        showCreate: false,
        showImport: false,
        selectedReport: null,
        newname: "",
        newdesciption: "",
        repo: {
            id: 0,
            name: "",
            description: ""
        },
        repoShow: false
    }, action) {
    switch (action.type) {
        case "STORE_MV_REPORTS": {
            return { ...state, list: action.reports }
        }
        case "MV-SHOW-CREATE": {
            return { ...state, showCreate: action.show }
        }
        case "MV-SHOW-IMPORT": {
            return { ...state, showImport: action.show }
        }
        case "MV-SELECT-REPORT": {
            return { ...state, selectedReport: action.reportId }
        }
        case "HANDLE_CHANGE_REPORT": {
            return { ...state, [action.name]: action.value }
        }
        case "CLEAR_DATA": {
            return { ...state, newname: "", newdesciption: "" }
        }
        case "REPO_DATA_SELECT": {
            return { ...state,
                repo: {
                    ...state.repo,
                    id: action.repSel.id,
                    name: action.repSel.name,
                    description: action.repSel.description
                },
                repoShow: true }
        }
        case "REPO_DATA_ENTER": {
            return { ...state,
                repo: {
                    ...state.repo,
                    [action.name]: action.value
                }}
        }
        case "CLEAR_DATA_SELECT": {
            return { ...state,
                repo: {
                    ...state.repo,
                    id: 0,
                    name: "",
                    description: ""
                },
                repoShow: false }
        }
        default:
            break
    }
    return state
}
