import { functions } from "lodash"
import { API_URL, xauth } from "../../../Constants"

export function startLoading() {
    return { type: "LOADING_START" }
}

export function stopLoading() {
    return { type: "LOADING_STOP" }
}

export function storeReport(report) {
    return { type: "STORE_MV_REPORT", report }
}

export function updateReportField(key, value) {
    return { type: "UPDATE_MV_REPORT", key, value }
}
export function updateExpenseField(key, value) {
    return { type: "UPDATE_EXPENSE_FIELDS", key, value
    }
}

// Expence Data Add Pop
export function handleExpenseNew(con, data) {
    return { type: "STORE_EXPENSE_NAME" ,con ,data}
}
// Expence handle Change description
export function updateSectionField(key, value) {
    return { type: "UPDATE_MV_SECTION", key, value
    }
}
// Expence Account Pop Show
export function accountSecPopShow(parentId) {
    return { type: "ACCOUNT_SEC_POP_SHOW",parentId
    }
}
// Expence Account Pop Hide
export function accSectionPopHide() {
    return { type: "ACCOUNT_SEC_POP_HIDE"
    }
}

// Expence Data Add Open Pop
export function closeExpenseNewPop() {
    return { type: "CLOSE_EXPENSE_NEW_POP"}
}

// Handle accounr section change
export function handleAccSectionChange(event) {
    return { type: "HANDLE_ACCC_SECT_CHANGE", des: event.target.value}
}



export function showNewSectionDialogAction(show, data) {
    return { type: "SHOW_MV_SECTION", show, data
    }
}
// Revenue Sub Head Hide
export function hideRevenueSubHead() {
    return { type: "HIDE_REVENUE_SUB_HEADING"
    }
}

export function showExpenseUpdateDialog(show, data) {
    return { type: "SHOW_UPDATE_EXPENSE", show, data
    }
}


export function resetSelectedReport() {
    return function(dispatch) {
        dispatch(storeReport(null))
    }
}


export function storeBooks(list) {
    return { type: "STORE_MV_BOOKS", books: list
    }
}

export function selectDefaultBook(book, period) {
    return { type: "STORE_MV_SELECTED_BOOK", book, period }
}
export function apiSlow(num) {
    return { type: "API_GETTING_DELAY",num }
}

export function getReport(reportdID, bookId, periodId) {
    return function(dispatch) {
        dispatch(startLoading())
        dispatch(apiSlow(1))
        fetch(API_URL + "/mvr/get?rid=" + reportdID + "&b=" + bookId + "&p=" + periodId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeReport(json))
                dispatch(inputChange(true))
                dispatch(apiSlow(0))
                dispatch(stopLoading())
            })
    }
}
export function changeLimProf(data) {
    return { type: "CHANGE_LIMIT_PROF", data }
}
export function handleLimProf(reportdID, bookId, periodId, data) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportdID + "/option", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({report_option:data})
        }).then(response => response.json())
            .then((json) => {
                dispatch(getReport(reportdID, bookId, periodId))
                dispatch(stopLoading())
            })
    }
}


export function updateReport(reportData) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(reportData)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportData.id))
                dispatch(stopLoading())
            })
    }
}

export function inputChange(bool) {
    return {
        type: "INPUT_CHNANGE_Change",
        bool
    }
}

export function saveSection(reportId, data, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        dispatch(closeExpenseNewPop())
        fetch(API_URL + "/mvr/" + reportId + "/expense/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({ ...data,
                book: bId,
                period: rId
            })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bId, rId))
                dispatch(getAccounts(bId, rId,reportId))
                dispatch(stopLoading())
            })
    }
}

export function deleteSection(reportId, id, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/delete/section/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bId, rId))
                dispatch(getAccounts(bId, rId,reportId))
                dispatch(stopLoading())
            })
    }
}


export function secttionPopUp(pop) {
    return {
        type: "SECTION_POP",
        pop
    }
}

export function handleSectionDataType(dataType) {
    return {
        type: "SECTION_DATA_TYPE",
        dataType
    }
}

export function handleChangeData(name, data) {
    return { type: "INPUT_DATA_CHANGE", name, data
    }
}
export function handleChangeDataSwitch(value) {
    return { type: "INPUT_SWITCH_DATA_CHANGE", value:value === true ? 1:0
    }
}

export function createExpense(expencedata, id,book, period) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + id + "/revenue/create/accounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({data:expencedata})
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(id, book, period))
                dispatch(getAccounts(book, period,id))
                dispatch(stopLoading())
            })
        }}

export function createExpenseAccount(expencedata, id,book, period) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + id + "/expense/create/accounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({data:expencedata})
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(id, book, period))
                dispatch(getAccounts(book, period,id))
                dispatch(accSectionPopHide())
                dispatch(stopLoading())
            })
    }
}


export function createSubSection(data, reportId,book,period) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/save/transactions", { 
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({ 
                data
                
            })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, book, period))
                dispatch(getAccounts(book, period,reportId))
                dispatch(stopLoading())
            })
    }
}
export function updateRevenue(secData, id, slcBk, sPrd) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + id + "/revenue/create", { method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(secData)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(id, slcBk, sPrd))
                dispatch(getAccounts(slcBk, sPrd,id))
                dispatch(stopLoading())
            })
    }
}


export function sectionId(data) {
    return { type: "SECTION_ID_PASS", data }
}

export function getBooks() {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/params/books", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeBooks(json))

                if (json && json.length > 0) {
                    dispatch(selectDefaultBook(json[json.length - 1].value, json[json.length - 1].periods[0].value))
                }

                dispatch(stopLoading())
            })
    }
}

export function expensePopUp(pop,groupId, gropData) {
    return { type: "EXPENSE_POP", pop,groupId, gropData }
}

export function deleteExpense(reportId, id, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/delete/expense/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bId, rId))
                dispatch(getAccounts(bId, rId, reportId))
                dispatch(stopLoading())
            })
    }
}


export function handleClearSectionData() {
    return { type: "CLEAR_SECTION_DATA" }
}

export function handleTransPopUpdate(data) {
    return { type: "UPDATE_SECTION_DATA", data
    }
}

export function handleDeleteSection(reportId, id, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/delete/section/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bId, rId))
                dispatch(getAccounts(bId, rId, reportId))
                dispatch(stopLoading())
            })
    }
}

export function handleDeleteTransactions(reportId, id, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/delete/transaction/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bId, rId))
                dispatch(getAccounts(bId, rId, reportId))
                dispatch(stopLoading())
            })
    }
}

export function addArrayRow(data) {
    return { type: "ARRAY_SEC_DATA_UPDATE", data
    }
}

export function handleChangeRowData(target, index) {
    return { type: "CHANGE_ROW_DATA", target, index
    }
}

export function handleDeleteDataRow(index) {
    return { type: "DELETE_ROW_DATA", index
    }
}

export function handleAddExpense(data) {
    return { type: "ADDING_EXPENSE_DATA", data }
}

export function handleExpenseRowUpdate(target, index) {
    return { type: "EXPENCE_EVENT_CHANGE", target, index }
}

export function handleDeleteExpenceRow(index) {
    return { type: "DELETE_EXPENSE_ROW", index }
}

export function handleExpenseEdit(show, data, allData) {
    return { type: "EXPENSE_DATA_EDIT", show, data, allData }
}


export function handleUpdateExpense(data, id, book, period) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + id + "/expense/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({
                ...data,
                book: book,
                period: period
            })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(id, book, period))
                dispatch(getAccounts(book, period, id))
                dispatch(stopLoading())
            })
    }
}

export function clearEcpenceFill() {
    return { type: "CLEAR_EXPENCE_FILL" }
}

export function accDataShowHide() {
    return { type: "REVENUE_DATA_SHOW_HIDE" }
}

export function handleSingleChange(event, data) {
    return { type: "HANDLE_SINGLE_CHANGE", event, data }
}


export function singleChangeUpdate(data, reportId, book, period) {
    return function(dispatch) {
        
        dispatch(resetExpensesUpdateFlag())
        fetch(API_URL + "/mvr/" + reportId + "/add/expense", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({
                ...data                
            })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, book, period))
                dispatch(getAccounts(book, period, reportId))
            })
    }
}

export function storeAccounts(list) {
    return {
        type: "STORE_DNA_ACCOUNTS",
        accounts: list
    }
}


export function getAccounts(sBkV, sPdv, repId, aId) {
    return function(dispatch) {
        if(aId=== undefined) {
            aId = 0
        }
        dispatch(startLoading())
        fetch(API_URL + "/mvr/get/accounts?b=" + sBkV + "&p=" + sPdv + "&repId=" + repId+ "&aId=" + aId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeAccounts(json))
                dispatch(stopLoading())
            })
    }
}

export function closeNewSectionDialog() {
    return { type: "CLOSE_NEW_SECTION_DIALOG" }
}

export function handleGroup(con, data) {
    return { type: "STORE_GROUP_HEADER" , con, data}
}

export function handleGroupTitle(con, title) {
    return { type: "STORE_GROUP_TITLE", con, title}
}

export function resetExpensesUpdateFlag() {
    return { type: "RESET_EXPENSES_UPDATE_FLAG"}
}

export function handleExpenseTextSave(con, title) {
    return { type: "STORE_EXPENSE_TITLE", con, title}
}

export function handleExpenseDesUpdate(event, index) {
    return { type: "UPDATE_EXPANSE_EDIT_ALL_UPDATE", event, index}
}
export function handleSwitchUpdate(value, index) {
    return { type: "UPDATE_SWITCH_UPDATE", value:value === true ? 1:0 , index}
}

export function ChangeGroupText(data) {
    return { type: "CHANGE_GROUP_TEXT", data}
}


export function expanceChangeText(data) {
    return { type: "CHANGE_EXPENSE_TEXT", data}
}



export function addRevenueg(data,reportId, bookId, periodId) { 
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/"+reportId+"/revenue/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId, reportId))
                dispatch(stopLoading())
            })
    }
}

export function addExpense(data,reportId, bookId, periodId) { 
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/"+reportId+"/add/expensesg", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({
                id: data.id,
                name: data.name
            })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId, reportId))
                dispatch(stopLoading())
            })
    }
}

// Delete expense group 
export function deleteExpenseGroup(reportId, groupID, bId, rId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/"+reportId+"/expense/delete/"+groupID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
        .then(() => {
            dispatch(getReport(reportId, bId, rId))
            dispatch(getAccounts(bId, rId,reportId))
            dispatch(stopLoading())
        })
}
}


// Revenue Group Delete
export function handleDeleteRevenGroup(repoId, revGrpId, bookId, periodId ) { 
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/"+ repoId +"/revenue/delete/"+ revGrpId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(repoId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId,repoId))
                dispatch(stopLoading())
            })
    }
}

// expennse add with data type change
export function handleChangeExpence(name,value) {
    if (name=== "bold") {
        return { type: "HANDLE_CHANGE_EXPENSE", name,value:value ===  true ? 1:0}
    }
    else {
        return { type: "HANDLE_CHANGE_EXPENSE", name,value}
    }
}

// Pop Up Accounts Set
export function handleAccountsSet() {
    return { type: "HANDLE_ACCOUNTS_BUNDLE"}
}

// arange account 
export function storeArrangeAcc(data) {
    return { type: "STORE_ARRANGE_ACCOUNT", data}
}


export function saveArrangeAcc(data,reportId, bookId, periodId) { 
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/revenue/organize", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({data})
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId,reportId))
                dispatch(stopLoading())
            })
    }
}

// arange expence
export function storeArrangeExp(data) {
    return { type: "STORE_EXPANCE_ACCOUNT", data}
}

export function saveArrangeExp(data,reportId, bookId, periodId) { 
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/" + reportId + "/expense/organize", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({data})
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(reportId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId,reportId))
                dispatch(stopLoading())
            })
    }
}

// handleRepoSelect
export function handleRepoSelect(bool) {
    return { type: "HANDLE_BOOL_POP", bool}
}
// handleCompanyChange
export function handleCompanyChange(target) {
    return { type: "SELECT_REPO_COUNTRY", target}
}


export function importReports(companyId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/web-dash/imt?cid=" + companyId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                // window.location.reload();
                dispatch(stopLoading())
            })
    }
}

export function handleSwitchChange(swtch) {
    return { type: "HANDLE_SWITCH_CHANGE", swtch:swtch === true ? 1:0}
}

