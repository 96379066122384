import { Alert, Badge, Button, Container, Grid, Icon, IconButton, MenuItem, Paper, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import './vf.css';
import { AuditTable, HeaderNumberWidget, ModalChecklist, ModalSettings, ModalTemplate, ModalTemplate2, ModalTemplate4, ModalTemplate5, ModalTemplate6, ModalTemplate7 } from './components';
import { useState, useEffect } from 'react';
import { API_URL } from './config';
import { Check, Settings } from '@mui/icons-material';
import ModalAnnex from './components/modal-annex';




function Audit() {

    const [openTemplate, setOpenTemplate] = useState(false);
    const [openAnnex, setOpenAnnex] = useState(false);
    const [openChecklist, setOpenCheclist] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    //const [openTemplate3, setOpenTemplate3] = useState(false);   
    const [data, setMainData] = useState([]);
    const [auditId, setAuditId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)
    const [currentDate, setcurrentDate] = useState(null)
    const [previousDate, setPreviousDate] = useState(null)
    const [model, setModel] = useState(0)
    const [periodMonth,setPeriodMonth] = useState("12");
    const [periodYear,setPeriodYear] = useState("2023");
    const [searchText,setSearchText] = useState("");
    const [accountCheckFilter,setAccountCheckFilter] = useState(false);
    const [accountMatchFilter,setAccountMatchFilter] = useState(null);
    const [periodUpdated,setAccountPeriodUpdated] = useState(false);

    function onChangeMonth(event){
        setPeriodMonth(event.target.value)
        setAccountPeriodUpdated(true)
    }

    function onChangeYear(event){
        setPeriodYear(event.target.value);
        setAccountPeriodUpdated(true)
        
    }

    function loadMainData() {
        if (isLoading === true) {
            return false;
        }
       
        setIsLoading(true)

        fetch(API_URL + `/audit/main?date=${periodYear}-${periodMonth}-01`)
            .then((response) => response.json())
            .then((response) => {
                //setcurrentDate(response.current_date)
                //setPreviousDate(response.previous_date)
                setMainData(response);
                //setAuditId(response.id)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error('Error fetching data:', error);
                // Display a user-friendly error message
                setError(error)
            });
    }

    function Dot(props){
        return <div class={props.status === 1 ? "green-dot": "grey-dot"}></div>;
    }
    
    function AccountCheckDot() {   
        return <div class={"blue-dot"}></div>;
    }
    

    function markAccountToggle(row) {


        fetch(API_URL + "/audit/tempalte/mark-account?account=" + row.accountNumber+"&tid="+ row.template_id)
            .then((response) => response.json())
            .then((data) => {
                loadMainData()
            })
            .catch((error) => {

                console.error('Error fetching data:', error);
                // Display a user-friendly error message
                setError(error)
            });
    }

    
    var lock = false;
    useEffect(() => {
        if (data.length === 0 && lock === false ) {
            lock = true;
            loadMainData()
        }

        if(periodUpdated){
            loadMainData()
        }
    }, [periodMonth,periodYear])

    function onAccountClick(data) {
        
        if(data.rowType === "annex"){
            setModel(data)
            setOpenAnnex(true)
        }

        
        if (data.template === 1) {
            setModel(data)
            setOpenTemplate(true);
        }

        if (data.template === 2) {
            setModel(data)
            setOpenTemplate(true);
        }

        if (data.template === 3) {
            setModel(data)
            setOpenTemplate(true);
        }
        if (data.template === 4) {
            setModel(data)
            setOpenTemplate(true);
        }
        if (data.template === 5) {
            setModel(data)
            setOpenTemplate(true);
        }
        if (data.template === 6) {
            setModel(data)
            setOpenTemplate(true);
        }
        if (data.template === 7) {
            setModel(data)
            setOpenTemplate(true);
        }
    }






    return (
        <Container maxWidth="lg">

            <Grid container spacing={3} >
                
                <div className='add_top_margine_50px'></div>
                <Grid item md={12} >
                <Grid container spacing={2} alignItems="center" >
                            {/* Left Side - Icon and Title */}
                            <Grid item xs={6} md={7}>
                                <Grid container spacing={2} alignItems="center">
                                
                                </Grid>
                            </Grid>

                            {/* Right Side - Next and Previous Buttons */}
                            <Grid item xs={6} md={5}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                <br/>
                                        <Button size="small" onClick={()=>setOpenSettings(true)} variant="outlined" color="primary" endIcon={<Settings/>}  >
                                            Settings                                            
                                        </Button>                                    
                                </Grid>  
                                  
                                </Grid>
                            </Grid>

                        </Grid>    
                </Grid>    
                <Grid item md={12}>
                    <Paper className='widget-paper '>
                        <Grid container spacing={2} alignItems="center" >
                            {/* Left Side - Icon and Title */}
                            <Grid item xs={6} md={7}>
                                <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                        <Button size="small" onClick={()=>setOpenCheclist(true)} variant="outlined" color="primary" endIcon={<Check></Check>}  >
                                            Checklist                                            
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Right Side - Next and Previous Buttons */}
                            <Grid item xs={6} md={5}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Select
                                        value={periodMonth}
                                        onChange={onChangeMonth}
                                    >
                                        <MenuItem value={"01"}>Jan</MenuItem>
                                        <MenuItem value={"02"}>Feb</MenuItem>
                                        <MenuItem value={"03"}>Mar</MenuItem>
                                        <MenuItem value={"04"}>Apr</MenuItem>
                                        <MenuItem value={"05"}>May</MenuItem>
                                        <MenuItem value={"06"}>Jun</MenuItem>
                                        <MenuItem value={"07"}>Jul</MenuItem>
                                        <MenuItem value={"08"}>Aug</MenuItem>
                                        <MenuItem value={"09"}>Sep</MenuItem>
                                        <MenuItem value={"10"}>Oct</MenuItem>
                                        <MenuItem value={"11"}>Nov</MenuItem>
                                        <MenuItem value={"12"}>Dec</MenuItem>
                                    </Select>
                                    <Select
                                            value={periodYear}
                                            onChange={onChangeYear}
                                        >                                    
                                        <MenuItem value={"2022"}>2022</MenuItem>
                                        <MenuItem value={"2023"}>2023</MenuItem>
                                        <MenuItem value={"2024"}>2024</MenuItem>                                        
                                    </Select>
                                </Grid>  
                                  
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>

                </Grid>

                <Grid item xs={12} md={4}>
                    <HeaderNumberWidget lable="ACTIF" amount={data.actif} prevAmount={data.prv_actif} difference={data.diff_actif} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HeaderNumberWidget lable="PASSIF" amount={data.passif} prevAmount={data.prv_passif} difference={data.diff_passif} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HeaderNumberWidget lable="COMPTE DE RESULTAT" amount="0,00"/>
                </Grid>

                <Grid item md={12}>
                    <Paper className='widget-paper'>
                        <Grid container spacing={2} alignItems="center" >
                            {/* Left Side - Icon and Title */}
                            <Grid item xs={8} md={10}>
                                <Grid container spacing={0} alignItems="center">
                                    <Grid item md={12}>
                                        <TextField size="small" onChange={(event)=>setSearchText(event.target.value)} fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Right Side - Next and Previous Buttons */}
                            <Grid item xs={4} md={2}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <ToggleButtonGroup  fullWidth>
                                        <ToggleButton selected={accountMatchFilter === 1} onClick={()=>setAccountMatchFilter(accountMatchFilter == 1 ? null : 1)}  fullWidth size="medium">
                                            <Dot status={1}/>
                                        </ToggleButton>
                                        <ToggleButton selected={accountMatchFilter === 0} onClick={()=>setAccountMatchFilter(accountMatchFilter == 0 ? null : 0)}  size="medium">
                                            <Dot status={0}/>                                         
                                        </ToggleButton>
                                        <ToggleButton selected={accountCheckFilter} onClick={()=>setAccountCheckFilter(!accountCheckFilter)}  size="medium">
                                            <AccountCheckDot />                                         
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    
                                </Grid>  
                                  
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>

                </Grid>

                <Grid item md={12}>
                    <AuditTable
                        data={data}
                        previousDate={data.previous_date}
                        currentDate={data.current_date}
                        onMarkClick={markAccountToggle}
                        onClick={onAccountClick}
                        searchText={searchText}
                        accountStatusFilter={accountMatchFilter}
                        accountCheckFilter={accountCheckFilter}
                    />
                </Grid>
            </Grid>
            {openTemplate ? <ModalTemplate open={openTemplate} auditId={data.id} model={model} handleClose={() => { setOpenTemplate(false); loadMainData(); }} /> : null}
            {openAnnex ? <ModalAnnex open={openAnnex} auditId={data.id} model={model} handleClose={() => { setOpenAnnex(false); loadMainData(); }} /> : null}
            {openChecklist ? <ModalChecklist open={openChecklist} auditId={data.id} data={data} handleClose={() => { setOpenCheclist(false) }} /> : null}
            {openSettings ? <ModalSettings open={openSettings} auditId={data.id} data={data} handleClose={() => { setOpenSettings(false) }} /> : null}
            
            {/* <ModalTemplate2 open={openTemplate2} handleClose={() => { setOpenTemplate2(false) }} />
            <ModalTemplate4 open={openTemplate4} handleClose={() => { setOpenTemplate4(false) }} />
            <ModalTemplate5 open={openTemplate5} handleClose={() => { setOpenTemplate5(false) }} />
            <ModalTemplate6 open={openTemplate6} handleClose={() => { setOpenTemplate6(false) }} />
            <ModalTemplate7 open={openTemplate7} handleClose={() => { setOpenTemplate7(false) }} /> */}
            <Snackbar open={isLoading} autoHideDuration={6000} >
                <Alert

                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Loading...
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Audit;
