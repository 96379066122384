import { CheckCircle, CheckCircleOutline, DeleteOutline, PushPin, PushPinOutlined } from "@mui/icons-material";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react";

function SidePanel(props) {

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position
      const scrollPosition = window.scrollY;
      // Check if the scroll position exceeds a certain threshold
      if (scrollPosition > 200) {
        setIsSticky(true); // Set the component to stick
      } else {
        setIsSticky(false); // Set the component to not stick
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <Paper className='widget-paper add_top_margine_50px' style={{position: isSticky ? "fixed" : "inherit", width: isSticky ? "21.7%" : "inherit"}} >
    <Grid container>
      <Grid item md={12}>
        <Grid container spacing={2} alignItems="center" >
          {/* Left Side - Icon and Title */}
          <Grid item xs={6} md={7}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
              <Typography>Checklist</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Side - Next and Previous Buttons */}
          <Grid item xs={6} md={5}>
            <Grid container justifyContent="flex-end" spacing={2}>

              <Grid item>
                  <IconButton onClick={()=>setIsSticky(!isSticky)} size="small">
                      {isSticky ? <PushPin/> : <PushPinOutlined/> }
                  </IconButton>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
       
      </Grid>
      <Grid item md={12}>
        <Table aria-label="collapsible table" className="audit-table" style={{ fontSize: "11px" }}>
          <TableBody>
            {props.data !== null ? props.data.items.map((item, index) => <TableRow key={"cl-" + index}>
              <TableCell className="actions" >
                <IconButton onClick={() => props.toggleStatus(item.id)}>
                  {item.status == 0 ? <CheckCircleOutline /> : <CheckCircle color="success" />}

                </IconButton>
              </TableCell>
              <TableCell className="account-name">{item.description}</TableCell>
            </TableRow>) : null}



          </TableBody>
        </Table>
      </Grid>
    </Grid>


  </Paper>
}

export default SidePanel