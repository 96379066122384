import React, { Fragment } from "react"
import styled from '@emotion/styled'
import DeleteIcon from "@mui/icons-material/Delete"
import DoneIcon from "@mui/icons-material/Done"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@mui/material/Grid";
// import DateFnsUtils from "@date-io/date-fns"
import DeleteConfirm from "../../Common/delete"
import "./styles.css"
// import "react-datepicker/dist/react-datepicker.css"
import TextareaAutosize from "@mui/material/TextareaAutosize"

const useStyles = styled({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        display: "flex"
    },
    divIcon: {
        paddingRight: "15px"
    },
    divData: {
        paddingBottom: "50px",
        textAlign: "left"
    },
    formControl: {
        width: "220px"
    },
    formControlWinBook: {
        width: "450px"
    },
    type: {
        width: "140px"
    },
    sourcetop: {
        paddingBottom: "30px"
    },
    tableData: {
        position: "relative",
        top: "-12px"
    },
    lastChild: {
        position: "relative",
        top: "-10px"
    },
    tablealign: {
        textAlign: "right"
    }
})


export default function LowyerDataRow(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [deletePop, setDeletePop] = React.useState(false)

    function closeRow() {
        if(props.data.fromPeriod==="NaN-NaN-NaN"||props.data.toPeriod==="NaN-NaN-NaN") {
            return false
        }
        props.viewProps.contProps.updateStart(true)
        setOpen(false)
    }

    const deleteShowTrue = (data, title) => {
        setDeletePop(true)
    }

    const confirmDelete = (confirm) => {
        if (confirm === true) {
            props.viewProps.contProps.handleDeleteLoyers(props.index)
        }
        setDeletePop(false)
    }

    return (
        <Fragment className="tableRow" >{
            open === true ?
                <TableRow>
                    <TableCell id="cal-align">
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                            <Grid container justify="space-around" className="dCreate-loyer">
                                <input onChange={(e) => props.viewProps.handleRowFromDate(e.target.value, props.index)} value={props.data.fromPeriod} />
                                
                                <input  className="dateC-to" onChange={(e) => props.viewProps.handleRowToDate(e.target.value, props.index)} value={props.data.toPeriod} />
                                {/* <KeyboardDatePicker
                                    value={props.data.fromPeriod}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.viewProps.handleRowFromDate(date, props.index)}
                                    className="datePicker"
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    error={false}
                                />
                                <KeyboardDatePicker
                                    value={props.data.toPeriod}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.viewProps.handleRowToDate(date, props.index)}
                                    className="datePicker"
                                    disableToolbar
                                    minDate={props.data.fromPeriod != null ? props.data.fromPeriod : new Date()}
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    error={false}
                                /> */}
                            </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </TableCell>
                    <TableCell className={classes.tablealign}>
                        <textarea type="text" name="beneficiaryInfo" onChange={(e) => props.viewProps.contProps.handleEditData(e, props.index)} value={props.data.beneficiaryInfo} cols={30} />
                    </TableCell>
                    <TableCell className={classes.tablealign} >
                        <textarea type="text" name="location" onChange={(e) => props.viewProps.contProps.handleEditData(e, props.index)} value={props.data.location} cols={30} />
                    </TableCell>
                    <TableCell className="mont-pad" >
                        <input style={{ height: 30 }} value={props.data.amountFormatted} name="amount" onChange={(e) => props.viewProps.contProps.handleEditData(e, props.index)} />
                    </TableCell>
                    <TableCell>
                        <IconButton size="small" aria-label="Edit" onClick={() => closeRow()} > <DoneIcon fontSize="small" /> </IconButton>
                    </TableCell>

                </TableRow>
                :
                <TableRow>
                    <TableCell>{props.data.fromPeriodFormatted} - {props.data.toPeriodFormatted}</TableCell>
                    <TableCell className={classes.tablealign}>
                        <TextareaAutosize className="loyers-ta" style={{minHeight:20}} disabled={true}  value={props.data.beneficiaryInfo} />
                    </TableCell>
                    <TableCell className={classes.tablealign}>
                        <TextareaAutosize className="loyers-ta" style={{minHeight:20}} disabled={true} value={props.data.location}  />
                    </TableCell>
                    <TableCell className={classes.tablealign}>{props.data.amountFormatted}</TableCell>
                    <TableCell>
                        <IconButton size="small" aria-label="Edit" onClick={() => setOpen(true)} > <EditIcon fontSize="small" /> </IconButton>
                        <IconButton size="small" aria-label="Delete" onClick={deleteShowTrue} > <DeleteIcon fontSize="small" /> </IconButton>
                    </TableCell>
                </TableRow>
        }

            <DeleteConfirm
                open={deletePop}
                title={"ligne"}
                confirmDelete={confirmDelete}
            />
        </Fragment>
    )
}