import { IconButton, Paper, Grid, Button } from "@mui/material";
import React from "react";
import '../vf.css';
import { Add, DeleteOutline } from "@mui/icons-material";



function Model3(props) {
  console.log(props)
  return <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={3} >
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          <Button size="small" onClick={()=>props.onSave()} variant="outlined" color="primary"  >
                Save
          </Button>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
      <Grid item xs={12} md={12}>
        <h5>reconciliation</h5>
      </Grid>
      <Grid item xs={12} md={12}>
        <table className="audit-model-1 model-table">

          <tbody>
            <tr>
              <td class="date" style={{ width: '6%' }}>Date</td>
              <td class="amount" style={{ width: '7%' }}><input type="text" onChange={(event)=>props.changeFields(0,"tr_date",event.target.value)} value={props.data.tr_date}  /></td>
              <td class="amount" style={{ width: '40%' }}>&nbsp;</td>
              <td class="libelle" style={{ width: '7%' }}>&nbsp;</td>
            </tr>
            <tr>
              <td class="date" style={{ width: '6%' }}>Total du stock au</td>
              <td class="amount" style={{ width: '7%' }}>&nbsp;</td>
              <td class="amount" style={{ width: '40%' }}></td>
              <td class="libelle" style={{ width: '7%' }}>&nbsp;<input type="text" onChange={(event)=>props.changeFields(0,"tr_amount",event.target.value)} value={props.data.tr_amount}  /></td>
            </tr>
            <tr>
            <td class="date" >&nbsp;</td>
              <td class="date" colSpan={4} style={{paddingTop:"8px",width:"100%"}} ><textarea style={{width:"100%"}} onChange={(event)=>props.changeFields(0,"tr_comments",event.target.value)} value={props.data.tr_comments} ></textarea></td>
              <td class="libelle" style={{ width: '7%' }}>&nbsp;</td>              
            </tr>
          </tbody>

        </table>
      </Grid>


    </Grid>





  </Paper>

    ;
}

export default Model3