import React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export default function GroupDialog(props) {
    return (
        <Dialog className="sal-group" aria-labelledby="form-dialog-title" open={props.viewProps.contProps.reducerSalaires.group.show} onClose={() => props.viewProps.contProps.handleGroup({ show: false, id: 0, description: "" })}>
            <DialogTitle id="form-dialog-title">Group settings</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    value={props.viewProps.contProps.reducerSalaires.group.description}
                    onChange={props.viewProps.contProps.handleGroupHeading}
                    name="description"
                    id="Group-name"
                    label="Group Name"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => props.viewProps.contProps.handleGroup({ show: false, id: 0, description: "" })} >
                    Cancel
                </Button>
                <Button color="primary" onClick={() => props.viewProps.contProps.updateGroupName(props.viewProps.contProps.reducerMvrList.selectedReport,props.viewProps.contProps.reducerSalaires.group)} >
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
}
