import React from "react"

import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export default function GroupDialog(props) {
    return (
        <Dialog aria-labelledby="form-dialog-title" open={props.viewProps.contProps.dnaReducer.mainTitle.show} onClose={() => props.viewProps.contProps.handleGroup({ trsType: "", id: 0, bold: 1, description: "", show: false })} >
            <DialogTitle id="form-dialog-title">Group settings</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onChange={(e) => props.viewProps.contProps.handleGroupEvent("description", e.target.value)}
                    value={props.viewProps.contProps.dnaReducer.mainTitle.description}
                    name="description"
                    margin="dense"
                    id="Group-name-DNA"
                    label="Group Name"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                
                <Button color="primary" onClick={() => props.viewProps.contProps.handleGroup({ trsType: "", id: 0, bold: 1, description: "", show: false })}>
                    Cancel
                </Button>
                <Button color="primary" onClick={props.viewProps.AddGroupTitle} >
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
}
