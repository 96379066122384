import React, { PureComponent } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@material-ui/core/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "./view"
import {
    getAccounts, handleGroup, handleGroupHeading, handleSetDataPopUp, handleDataArraySalary,
    handleAddSalary, handleRowData, deleteRowData, addChildrenToGroup, getSalRepo, createGroup, updateGroupName,
    handleDeleteRepo, handleDataArraySalaryBold, addChildrenToGroupDeduct, stopSalryArrayUpdate, addDataArray,
    showUpdatePopUp, hideUpdatePopUp, changeUpdateDataAdd, saveUpdatedDateAdd, saveUpdatedDateDetuct, deleteData,
} from "./actions"
import { refresh } from "../../../Actions/Common"

const mapStateToProps = (state) => ({
    reducerSalaires: state.reducerSalaires,
    route: state.PathRouterReducer,
    reducerMvrList: state.reducerMvrList,
    reducerMvrSettings: state.reducerMvrSettings,
    dnaReport: state.DNAReport,

})

const mapDispatchToProps = dispatch => ({
    getAccounts: (auditId) => dispatch(getAccounts(auditId)),
    getSalRepo: (templateId,auditId) => dispatch(getSalRepo(templateId,auditId)),
    handleGroup: (data) => dispatch(handleGroup(data)),
    handleGroupHeading: (event) => dispatch(handleGroupHeading(event)),
    handleSetDataPopUp: (array) => dispatch(handleSetDataPopUp(array)),
    handleDataArraySalary: (event) => dispatch(handleDataArraySalary(event)),
    handleAddSalary: (obj) => dispatch(handleAddSalary(obj)),
    handleRowData: (event, index) => dispatch(handleRowData(event, index)),
    deleteRowData: (index) => dispatch(deleteRowData(index)),
    addChildrenToGroup: (array, index) => dispatch(addChildrenToGroup(array, index)),
    addChildrenToGroupDeduct: (array, index) => dispatch(addChildrenToGroupDeduct(array, index)),
    createGroup: (templateId,auditId) => dispatch(createGroup(templateId,auditId)),
    updateGroupName: (repId, data) => dispatch(updateGroupName(repId, data)),
    handleDeleteRepo: (repId, dataId) => dispatch(handleDeleteRepo(repId, dataId)),
    handleDataArraySalaryBold: (check) => dispatch(handleDataArraySalaryBold(check)),
    stopSalryArrayUpdate: () => dispatch(stopSalryArrayUpdate()),
    addDataArray: (templateId,auditId, data) => dispatch(addDataArray(templateId,auditId, data)),
    showUpdatePopUp: (add, index, trsType, mainIndex) => dispatch(showUpdatePopUp(add, index, trsType, mainIndex)),
    hideUpdatePopUp: () => dispatch(hideUpdatePopUp()),
    changeUpdateDataAdd: (value,name,index) => dispatch(changeUpdateDataAdd(value,name,index)),
    saveUpdatedDateAdd: (data) => dispatch(saveUpdatedDateAdd(data)),
    saveUpdatedDateDetuct: (data) => dispatch(saveUpdatedDateDetuct(data)),
    deleteData: (index, sIndex, name,array) => dispatch(deleteData(index, sIndex, name,array)),
    refresh: () => dispatch(refresh())
})

// const styles = theme => ({
//     progress: {
//         margin: theme.spacing(2)
//     }
// })

class SettingsView extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {

        }
        this.props.getSalRepo(this.props.model.template_id,this.props.aid)
    }

    handleGoBack = () => {
        //this.props.history.goBack()
    }

    componentDidUpdate = () => {
        if(this.props.reducerSalaires.salryArrayUpdate)
        {
            this.props.stopSalryArrayUpdate()
            this.props.addDataArray(this.props.model.template_id,this.props.aid,this.props.reducerSalaires.salryArray)
        }
    }

    componentDidMount = () => {
        this.props.getAccounts(this.props.aid)
        // else if (this.props.route.selectedBook !== null && this.props.route.selectedPeriod !== null) {
        //     
        // }
    }

    handleAddSalary = () => {
        if (this.props.reducerSalaires.dataArray.list_obj.trsType !== "winbook") {
            this.props.handleAddSalary(
                {
                    id: this.props.reducerSalaires.dataArray.list_obj.id,
                    parentId: this.props.reducerSalaires.dataArray.list_obj.parentId,
                    bold: this.props.reducerSalaires.dataArray.list_obj.bold,
                    trsType: this.props.reducerSalaires.dataArray.list_obj.trsType,
                    description: this.props.reducerSalaires.dataArray.list_obj.description,
                    source: this.props.reducerSalaires.dataArray.list_obj.source,
                    value: this.props.reducerSalaires.dataArray.list_obj.value 
                    }
            )
        }
        else {
            for (let x = 0; x < this.props.reducerSalaires.dataArray.list_obj.winArray.length; x++) {
                this.props.handleAddSalary({id:0, parentId: this.props.reducerSalaires.dataArray.list_obj.parentId, description: "", bold: this.props.reducerSalaires.dataArray.list_obj.bold, trsType: "winbook", source: this.props.reducerSalaires.dataArray.list_obj.winArray[x].NUMBER, value: 0 })
            }
        }
    }

    addChildrenToGroup = () => {
        this.props.reducerSalaires.dataArray.trsType === "add" ?
            this.addChildrenToGroupAdd() :
            this.addChildrenToGroupDeduct()
    }

    addChildrenToGroupAdd = () => {
        for (let x = 0; x < this.props.reducerSalaires.dataArray.list.length; x++) {
            this.props.addChildrenToGroup(this.props.reducerSalaires.dataArray.list[x], this.props.reducerSalaires.dataArray.index)
        }
    }
    addChildrenToGroupDeduct = () => {
        for (let x = 0; x < this.props.reducerSalaires.dataArray.list.length; x++) {
            this.props.addChildrenToGroupDeduct(this.props.reducerSalaires.dataArray.list[x], this.props.reducerSalaires.dataArray.index)
        }
    }

    saveUpdateData = () => {
        if(this.props.reducerSalaires.updateData.trsType === "add") {
            this.props.saveUpdatedDateAdd(this.props.reducerSalaires.updateData)
        }
        else if (this.props.reducerSalaires.updateData.trsType === "deduct") {
            this.props.saveUpdatedDateDetuct(this.props.reducerSalaires.updateData)
        }
    }

    deleteData = (index, dedIndex,array, name) => {
        let arr = array
        arr.splice(dedIndex, 1)
        this.props.deleteData(index, dedIndex, name ,arr)
    }

    render() {
        return (
            <View
                contProps={this.props}
                //handleGoBack={this.props.history.goBack}
                handleAddSalary={this.handleAddSalary}
                addChildrenToGroup={this.addChildrenToGroup}
                saveUpdateData={this.saveUpdateData}
                deleteData={this.deleteData}
                aid={this.props.aid}
                templateid={this.props.model.template_id}


            />
        )
    }
}

SettingsView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps),)(SettingsView)
