import React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export default function Section(props) {
    return (
        <Dialog open={props.sectionProps.sectionAllData.expenseHeadingPopShow} onClose={props.sectionProps.closeExpenseNewPop} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">SaSection settings</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onChange={props.handleSectionChanges("description")}
                    value={props.sectionProps.sectionAllData.expenseData.description}
                    margin="dense"
                    id="reaport-name"
                    label="Report Name"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.sectionProps.closeExpenseNewPop} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.handleSaveSectionHead()} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
}
