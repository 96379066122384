import React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import InputBase from "@mui/material/InputBase"
import Badge from "@mui/material/Badge"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
// import { fade } from "@mui/material/styles/colorManipulator"
// import { withStyles } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"
import SearchIcon from "@mui/icons-material/Search"
import AccountCircle from "@mui/icons-material/AccountCircle"
import MailIcon from "@mui/icons-material/Mail"
import NotificationsIcon from "@mui/icons-material/Notifications"
import MoreIcon from "@mui/icons-material/MoreVert"
import AddIcon from "@mui/icons-material/Add"
import Button from "@mui/material/Button"
import Alert from "../../Layouts/Common/Alert"
import GetAppIcon from "@mui/icons-material/GetApp"


const drawerWidth = 240
// const styles = theme => ({
//     root: {
//         width: "100%"
//     },
//     grow: {
//         flexGrow: 1
//     },
//     menuButton: {
//         marginLeft: -12,
//         marginRight: 20
//     },
//     title: {
//         display: "none",
//         [theme.breakpoints.up("sm")]: {
//             display: "block"
//         }
//     },
//     search: {
//         position: "relative",
//         borderRadius: theme.shape.borderRadius,
//         backgroundColor: fade(theme.palette.common.white, 0.15),
//         "&:hover": {
//             backgroundColor: fade(theme.palette.common.white, 0.25)
//         },
//         marginRight: theme.spacing(2),
//         marginLeft: 0,
//         width: "100%",
//         [theme.breakpoints.up("sm")]: {
//             marginLeft: theme.spacing(3),
//             width: "auto"
//         }
//     },
//     searchIcon: {
//         width: theme.spacing(9),
//         height: "100%",
//         position: "absolute",
//         pointerEvents: "none",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center"
//     },
//     inputRoot: {
//         color: "inherit",
//         width: "100%"
//     },
//     inputInput: {
//         paddingTop: theme.spacing(),
//         paddingRight: theme.spacing(),
//         paddingBottom: theme.spacing(),
//         paddingLeft: theme.spacing(10),
//         transition: theme.transitions.create("width"),
//         width: "100%",
//         [theme.breakpoints.up("md")]: {
//             width: 200
//         }
//     },
//     sectionDesktop: {
//         display: "none",
//         [theme.breakpoints.up("md")]: {
//             display: "flex"
//         }
//     },
//     sectionMobile: {
//         display: "flex",
//         [theme.breakpoints.up("md")]: {
//             display: "none"
//         }
//     },
//     appBarShift: {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(["width", "margin"], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen
//         })
//     }
// })

class MainAppBar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null
        }
    }
    shouldComponentUpdate() {
        this.a = "a"
        return false
    }


  handleProfileMenuOpen = event => {
      this.setState({ anchorEl: event.currentTarget })
  };

  handleMenuClose = () => {
      this.setState({ anchorEl: null })
      this.handleMobileMenuClose()
  };

  handleMobileMenuOpen = event => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget })
  };

  handleMobileMenuClose = () => {
      this.setState({ mobileMoreAnchorEl: null })
  };

  render() {
      const { anchorEl, mobileMoreAnchorEl } = this.state
      const { classes } = this.props
      const isMenuOpen = Boolean(anchorEl)
      const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

      const renderMenu = (
          <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={isMenuOpen}
              onClose={this.handleMenuClose}
          >
              <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
          </Menu>
      )

      const renderMobileMenu = (
          <Menu
              anchorEl={mobileMoreAnchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={isMobileMenuOpen}
              onClose={this.handleMobileMenuClose}
          >
              <MenuItem>
                  <IconButton color="inherit">
                      <Badge badgeContent={4} color="secondary">
                          <MailIcon />
                      </Badge>
                  </IconButton>
                  <p>Messages</p>
              </MenuItem>
              <MenuItem>
                  <IconButton color="inherit">
                      <Badge badgeContent={11} color="secondary">
                          <NotificationsIcon />
                      </Badge>
                  </IconButton>
                  <p>Notifications</p>
              </MenuItem>
              <MenuItem onClick={this.handleProfileMenuOpen}>
                  <IconButton color="inherit">
                      <AccountCircle />
                  </IconButton>
                  <p>Profile</p>
              </MenuItem>
          </Menu>
      )

      return (
          <div className={classes.root}>
              <AppBar position={this.props.position} >
                  <Toolbar>
                      <IconButton className={classes.menuButton} onClick={this.props.menuAction} color="inherit" aria-label="Open drawer">
                          <MenuIcon />
                      </IconButton>
                      <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                          {this.props.title}
                      </Typography>
                      {this.props.search === true ? (
                          <div className={classes.search}>
                              <div className={classes.searchIcon}>
                                  <SearchIcon />
                              </div>
                              <InputBase
                                  placeholder="Search…"
                                  onChange={this.props.onSearch}
                                  classes={{
                                      root: classes.inputRoot,
                                      input: classes.inputInput
                                  }}
                              />
                          </div>) : null}

                      <div className={classes.grow} />
                      <div className={classes.sectionDesktop}>
                          {this.props.createReport !== null ? (
                              <Button onClick={this.props.createReport} color="inherit">
                                  <AddIcon />
                                  ADD REPORT
                              </Button>) : null}

                          

                      </div>
                      <div className={classes.sectionMobile}>
                          <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                              <MoreIcon />
                          </IconButton>
                      </div>
                  </Toolbar>
              </AppBar>
              <Alert />
          </div>)
  }
}

MainAppBar.propTypes = {
    classes: PropTypes.object.isRequired
}

export default (MainAppBar)
