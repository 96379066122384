import { IconButton, Paper, Grid, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../vf.css';
import { Add, DeleteOutline } from "@mui/icons-material";




function Model7(props) {
  const [sumColOne, setSumColOne] = useState(0);
  const [sumColTwo, setSumColTwo] = useState(0);
  const [sumColThree, setSumColThree] = useState(0);
  const [sumColFour, setSumColFour] = useState(0);
  const [sumColFive, setSumColFive] = useState(0);
  const [sumColSix, setSumColSix] = useState(0);

  const formatToEuropeanCurrency = (number) => {
    return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
  };

  const convertToEnglishFormat = (europeanNumber) => {
    if(typeof europeanNumber === 'undefined'){
      return "0.00";
    }

    // check if the . is available
    if (europeanNumber.includes(".")) {
      europeanNumber = europeanNumber.replace(".", "");
    }
    if (europeanNumber.includes(",")) {
      europeanNumber = europeanNumber.replace(",", ".");
    } else {
      europeanNumber = europeanNumber + ".00";
    }

    return europeanNumber;
  };

  useEffect(() => {
    var colOne = 0;
    var colTwo = 0;
    var colThree=0;
    var colFour=0;
    var colFive=0;
    var colSix=0;



    if (props.data.length > 0) {
      colOne = props.data.reduce((colOne, transaction) => {
        var tr_end_cost = convertToEnglishFormat(transaction.tr_end_cost);
        tr_end_cost = parseFloat(tr_end_cost);        
        return colOne + tr_end_cost;
      }, 0);
    }
    
    colOne = isNaN(colOne) ? 0 : colOne;
    setSumColOne(colOne);

    if (props.data.length > 0) {
      colTwo = props.data.reduce((colTwo, transaction) => {
        var tr_provision = convertToEnglishFormat(transaction.tr_provision);
        tr_provision = parseFloat(tr_provision);        
        return colTwo + tr_provision;
      }, 0);
    }
    
    colTwo = isNaN(colTwo) ? 0 : colTwo;
    setSumColTwo(colTwo);

    if (props.data.length > 0) {
      colTwo = props.data.reduce((colThree, transaction) => {
        var tr_provision = convertToEnglishFormat(transaction.tr_provision);
        tr_provision = parseFloat(tr_provision);        
        return colThree + tr_provision;
      }, 0);
    }
    
    colThree = isNaN(colThree) ? 0 : colThree;
    setSumColThree(colThree);

    if (props.data.length > 0) {
      colThree = props.data.reduce((colThree, transaction) => {
        var tr_provision = convertToEnglishFormat(transaction.tr_provision);
        tr_provision = parseFloat(tr_provision);        
        return colThree + tr_provision;
      }, 0);
    }
    
    colThree = isNaN(colThree) ? 0 : colThree;
    setSumColThree(colThree);

    if (props.data.length > 0) {
      colFour = props.data.reduce((colFour, transaction) => {
        var tr_cur_deduction = convertToEnglishFormat(transaction.tr_cur_deduction);
        tr_cur_deduction = parseFloat(tr_cur_deduction);        
        return colFour + tr_cur_deduction;
      }, 0);
    }
    
    colFour = isNaN(colFour) ? 0 : colFour;
    setSumColFour(colFour);

    if (props.data.length > 0) {
      colFive = props.data.reduce((colFive, transaction) => {
        var tr_cur_estimation = convertToEnglishFormat(transaction.tr_cur_estimation);
        tr_cur_estimation = parseFloat(tr_cur_estimation);        
        return colFive + tr_cur_estimation;
      }, 0);
    }
    
    colFive = isNaN(colFive) ? 0 : colFive;
    setSumColFive(colFive);

    if (props.data.length > 0) {
      colSix = props.data.reduce((colSix, transaction) => {
        var tr_cur_adjustments = convertToEnglishFormat(transaction.tr_cur_adjustments);
        tr_cur_adjustments = parseFloat(tr_cur_adjustments);        
        return colSix + tr_cur_adjustments;
      }, 0);
    }
    
    colSix = isNaN(colSix) ? 0 : colSix;
    setSumColSix(colSix);

  });

  function calcTotal(row){
      var initTotal = convertToEnglishFormat(row.tr_debts_init_total);
          initTotal = parseFloat(initTotal);
      var modification = convertToEnglishFormat(row.tr_modification);
          modification = parseFloat(modification);

      return  (initTotal+modification)
  }

  return <div>
    <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={3} >
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          <Button size="small" onClick={()=>props.onSave()} variant="outlined" color="primary"  >
                Save
          </Button>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
      <Grid item xs={12} md={12}>
        <h5>Constitution et augmentation</h5>
      </Grid>
      <Grid item xs={12} md={12}>
      <table className="audit-model-1 model-table">
          <thead>          
            <tr>
              <td class="date" style={{ width: '7%' }}>N° d'odre</td>
              <td class="amount" style={{ width: '7%' }}>type</td>
              <td class="libelle" style={{ width: '7%' }}>Nature</td>
              <td class="account-number" style={{ width: '8%' }}>Probable coût fin</td>
              <td class="amount" style={{ width: '8%' }}>Provision début</td>
              <td class="amount" style={{ width: '8%' }}>Provision fin</td>
              <td class="amount" style={{ width: '8%' }}>diminution prise en charge</td>
              <td class="amount" style={{ width: '8%' }}>diminution estimation</td>
              <td class="amount" style={{ width: '8%' }}>augmentation</td>
              <td class="amount">justification</td>
              <td ></td>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr>
                <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_order_num",event.target.value)} value={item.tr_order_num} /></td>
                <td>
                  <select onChange={(event)=>props.changeFields(index,"tr_type",event.target.value)} value={item.tr_type}>
                    <option value={1}>Nouvelle</option>
                    <option value={2}>inchangée</option>
                    <option value={3}>augmentée</option>
                    <option value={4}>diminuée</option>
                  </select>
                </td>
                <td>
                  <select onChange={(event)=>props.changeFields(index,"tr_nature",event.target.value)} value={item.tr_nature}>
                    <option value={1}>Activité ou événement</option>
                    <option value={2}>indemnité obtenue</option>
                    <option value={3}>grosse réparation</option>                    
                  </select>
                </td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_end_cost",event.target.value)} value={item.tr_end_cost} /></td>
                <td>
                <input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_provision",event.target.value)} value={item.tr_provision}  />
                </td>
                <td className="amount-field">{item.tr_provision}</td>
                <td> { item.tr_type === 4 ? <input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_cur_deduction",event.target.value)} value={item.tr_cur_deduction} />:null } </td>
                <td>{ item.tr_type === 4 ?  <input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_cur_estimation",event.target.value)} value={item.tr_cur_estimation} /> :null } </td>
                <td>{ item.tr_type === 3 ? <input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_cur_adjustments",event.target.value)} value={item.tr_cur_adjustments} /> : null }</td>
                <td><input type="text"   onChange={(event)=>props.changeFields(index,"tr_justification",event.target.value)} value={item.tr_justification}  /></td>
                <td><IconButton onClick={()=>props.onMainRemove(index)} ><DeleteOutline/></IconButton></td>
              </tr>
            ))}
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColOne)}</th>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColTwo)}</th>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColThree)}</th>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColFour)}</th>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColFive)}</th>
                <th style={{textAlign:"right"}}>{formatToEuropeanCurrency(sumColSix)}</th>
                <td>&nbsp;</td>
                <td><IconButton onClick={()=>props.addMainRow()} ><Add/></IconButton></td>
            </tr>
          </tbody>          
        </table>
      </Grid>
      
    </Grid>
    <Divider/>
    
  </Paper>
  <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={3} >
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
      <Grid item xs={12} md={12}>
        <h5>Réconciliation Comparative</h5>
      </Grid>
      <Grid item xs={12} md={12}>
      <table className="audit-model-1 model-table">
          <thead>          
            <tr>
              <td class="date" style={{ width: '7%' }}>Aneée</td>
              <td class="amount" style={{ width: '22%' }}>Provisions</td>                            
              <td class="amount" style={{ width: '8%' }}>Charge Probable</td>
              <td class="amount" style={{ width: '8%' }}>Utilisation/Reprise</td>
              <td class="amount" style={{ width: '8%' }}>Detation</td>
              <td class="amount" style={{ width: '8%' }}>Type</td>
              <td class="amount" style={{ width: '8%' }}>Solde</td>              
              
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr>
                <td>&nbsp;1</td>
                <td>&nbsp;2</td>
                <td>&nbsp;3</td>
                <td>&nbsp;4</td>
                <td>&nbsp;5</td>
                <td>&nbsp;6</td>
                <td>&nbsp;7</td>                
              </tr>
            ))}
            <tr>
                <td>&nbsp;1</td>
                <td>&nbsp;2</td>
                <td>&nbsp;3</td>
                <td>&nbsp;4</td>
                <td>&nbsp;5</td>
                <td>&nbsp;6</td>
                <td>&nbsp;7</td>                
              </tr>
          </tbody>          
        </table>
      </Grid>
      
    </Grid>
    <Divider/>
    
  </Paper>
  </div>;
}

export default Model7