import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/material/styles';
import { withRouter} from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


import { compose } from 'redux';
import { connect } from 'react-redux';




function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

const mapStateToProps = (state) => {
  return {
    loading:state.Common.loading,
  };
}

const mapDispatchToProps = dispatch => ({
    
})

class Loader extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };



  

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    

    

    return (
        <Dialog open={this.props.loading} onClose={this.handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText>
            
            </DialogContentText>

            <CircularProgress  />
            
          </DialogContent>
          <DialogActions>
            
          </DialogActions>
        </Dialog>
    );
  }
}

Loader.propTypes = {
  
};

export default compose(connect(mapStateToProps, mapDispatchToProps))((Loader));
