import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import CloseIcon from "@mui/icons-material/Close"
import { amber, green } from "@mui/material/colors"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"
import SnackbarContent from "@mui/material/SnackbarContent"
import WarningIcon from "@mui/icons-material/Warning"
// import { withStyles } from "@mui/material/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import { SnackBar } from "../Actions/Common"

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
}

const useStyles1 = theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: amber[700]
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 6
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
})

function MySnackbarContentWrapper(props) {

    const { parentProps, className, message, onClose, variant, ...other } = props
    const { classes } = parentProps
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    )
}


MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.any,
    onClose: PropTypes.func,
    parentProps: PropTypes.any,
    variant: PropTypes.any
}

MySnackbarContentWrapper.defaultProps = {
    className: "",
    parentProps: "",
    onClose(e) { return e },
    message: "",
    variant: ""
}


const mapStateToProps = (state) => ({
    loading: state.Common.loading,
    showAlert: state.Common.showAlert,
    alertMessage: state.Common.alertMessage,
    alertVarient: state.Common.alertVarient
})

const mapDispatchToProps = dispatch => ({
    SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient))
})

class AlertSticky extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showAlert: true,
            alertMessage: null,
            alertVarient: "info"
        }
    }


    handleClose = () => {

        this.props.SnackBar(false, null, this.props.alertVarient)
    }

    render() {

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={this.props.showAlert}
                    // onClose={this.handleClose}
                >
                    <MySnackbarContentWrapper
                        parentProps={this.props}
                        onClose={this.handleClose}
                        variant={this.props.alertVarient}
                        message={this.props.alertMessage}
                    />
                </Snackbar>
            </div>)
    }

}

AlertSticky.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.any,
    message: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
}

AlertSticky.defaultProps = {
    className: "",
    loading: ""
}

export default compose(connect(mapStateToProps, mapDispatchToProps), )(AlertSticky)
