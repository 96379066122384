import { Box, Paper } from "@mui/material";

function HeaderNumberWidget({lable,amount,prevAmount,difference}){
    return <Paper elevation={0} className="widget-paper">
                <Box width={"100%"}  >
                    <h6 className="widget-time">{lable}</h6>
                    <h3 className="widget-main-number">{amount}</h3>
                    <h4 className="widget-prev-number">{prevAmount} &nbsp;&nbsp;<span>{difference}</span></h4>
                </Box>
          </Paper>;
}

export default HeaderNumberWidget;


