// eslint-disable-next-line complexity
export default function reducer(
    state = {
        report: null,
        accSecttionPopUp: false,
        expense: {
            id: 0,
            description: "",
            account: [],
            private_percentage: "",
            tax_percentage: ""
        },
        showNewSectionDialog: false,
        sectionData: {
            id: 0,
            parentId: 0,
            trsType : "",
            description: "",
            source: "",
            value: 0,
            book: null,
            period: null
        },
        secData: {
            source: "",
            id: 0,
            parentId: 0,
            trsType : "",
            description: "",
            value: 0,
            book: null,
            period: null
        },
        secArray: [],
        expArray: [],
        expensePopUp: false,
        expenseSelectedGroup:null,
        expenseSelectedGroupData:[],
        books: [],
        selectedBook: null,
        selectedReport: null,
        selectedPeriod: null,
        editExpenceShow: false,
        editAccDataShow: false,
        eranda: {
            data: []
        },
        groupData: {
            id: 0,
            open: false,
            parentId: 0,
            trsType : "",
            bold: 0,
            description: "",
            source: null,
            value: 0,
            book: null,
            period: null
        },
        groupTitle: [],
        expenseHeadingPopShow: false,
        expenseData: {
            id: 0,
            parentId: 0,
            open: false,
            trsType: "",
            description: "",
            source: null,
            value: 0,
            personal: 0,
            dna: 0,
            mvReportId: null
        },
        expenseTitle: [],
        expensesGroupUpdated:false,
        expensesUpdatedGroupIndex:0,
        expensesUpdatedRowIndex:0,
        expenceData: {
            source: "",
            id: 0,
            open: false,
            bold: 0,
            parentId: 0,
            trsType : "winbook",
            description: "",
            value: 0,
            book: null,
            period: null
        },
        expenseAddParentId:0,
        accArrangeData: [],
        expArrangeData: [],
        apiDelay: false,
        company: false,
        importCompany: null,
        inputChange:false

    }, action) {
    switch (action.type) {
        case "STORE_MV_REPORT": {
            return { ...state, report: action.report }
        }
        case "API_GETTING_DELAY": {
            return { ...state, apiDelay: action.num === 1 ? true :false }
        }
        case "MV-SELECT-REPORT": {
            return { ...state, selectedReport: action.reportId }
        }
        case "ACCOUNT_SEC_POP_SHOW": {
            return { ...state, 
                accSecttionPopUp: true, 
                expenseAddParentId:action.parentId,
                expenceData: { 
                    id: 0,
                    parentId: action.parentId,
                    trsType: "winbook",
                    description: "",
                    bold: 0,
                    source: null,
                    value: 0,
                    personal: 0,
                    dna: 0,
                    mvReportId: null
                } }
        }
        case "ACCOUNT_SEC_POP_HIDE": {
            return { ...state, accSecttionPopUp: false, expArray: [],
                expense: { id: 0, description: "", account: [],  private_percentage: "", tax_percentage: ""
                        },
                expenceData:{
                ...state.expenceData,
                        source: "", id: 0, parentId: 0, trsType : "winbook", bold: 0,
                        description: "", value: 0, book: null,period: null
                        }
                    }
        }
        case "UPDATE_MV_REPORT": {
            return {
                ...state, report: {
                    ...state.report,
                    [action.key]: action.value
                }
            }
        }
        case "SHOW_MV_SECTION": {
            return { ...state, showNewSectionDialog: action.show, 
                sectionData: {
                    ...state.sectionData,
                    id: action.data.id,
                    description: action.data.description,
                    parentId: action.data.parentId,
                    trsType: action.data.trsType
                } }
        }
        case "HIDE_REVENUE_SUB_HEADING": {
            return { ...state, showNewSectionDialog: false, 
                sectionData: {
                    ...state.sectionData,
                    id: 0,
                    parentId: 0,
                    trsType : "",
                    description: "",
                    source: "",
                    value: 0,
                    book: null,
                    period: null
                } }
        }
        case "HANDLE_ACCC_SECT_CHANGE": {
            return { ...state,
                sectionData: {
                    ...state.sectionData,
                    description: action.des,
                } }
        }
        case "UPDATE_MV_SECTION": {
            return {
                ...state, expenseData: {
                    ...state.expenseData,
                    description: action.value
                }
            }
        }
        case "SECTION_POP": {
            return { ...state, secttionPopUp: action.pop }
        }

        case "SECTION_DATA_TYPE": {
            return {
                ...state, secData: {
                    ...state.secData,
                    trsType: action.dataType, description: "", source: "", value: 0
                }
            }
        }
        case "INPUT_SWITCH_DATA_CHANGE": {
            return {
                ...state, secData: {
                    ...state.secData,
                    "bold": action.value
                }
            }
        }
        case "INPUT_DATA_CHANGE": {
            return {
                ...state, secData: {
                    ...state.secData,
                    [action.name]: action.data,
                    [action.name === "row_type" ? "source" : null]: "",
                    [action.name === "row_type" ? "description" : null]: "",
                    [action.name === "row_type" ? "bold" : null]: 0,
                    [action.name === "row_type" && action.data !== 3 ? "value" : null]: 0
                }
            }
        }
        case "SECTION_ID_PASS": {
            return {
                ...state, expensePopUp:true,
                expenceData: {
                ...state.expenceData,
                    parentId: action.data.id
                }
            }
        }
        case "EXPENSE_POP": {
            return { ...state, expensePopUp: action.pop,expenseSelectedGroup:action.groupId, expenseSelectedGroupData: action.gropData}
        }

        case "UPDATE_EXPENSE_FIELDS": {

            return {
                ...state, expense: {
                    ...state.expense,
                    [action.key]: action.value
                }
            }


        }
        case "STORE_MV_BOOKS": {
            return { ...state, books: action.books }
        }
        case "STORE_MV_SELECTED_BOOK": {
            return { ...state, selectedBook: action.book, selectedPeriod: action.period }
        }
        case "SHOW_UPDATE_EXPENSE": {
            return { ...state, expensePopUp: action.show, expense: action.data, expArray: [],
                expenceData: {
                    ...state.expenceData,
                    source: "",
                    id: 0,
                    open: false,
                    bold: 0,
                    parentId: 0,
                    trsType : "winbook",
                    description: "",
                    value: 0,
                    book: null,
                    period: null
                } }
        }
        case "CLEAR_SECTION_DATA": {
            return {
                ...state, secData: {
                    ...state.secData,
                    id: 0,
                    section_id: 0,
                    row_type: 1,
                    description: "",
                    source: "",
                    value: 0
                }
            }
        }

        case "UPDATE_SECTION_DATA": {
            return {
                ...state, secData: {
                    ...state.secData,
                    source:action.data.trsType=="formula"? action.data.accountAmountFormatted:action.data.source,
                    id: action.data.id,
                    open: false,
                    parentId: action.data.parentId,
                    trsType : action.data.trsType,
                    description: action.data.description,
                    value: action.data.accountAmountFormatted,
                    book: action.data.book,
                    period: action.data.period,
                    bold:action.data.bold

                },
                editAccDataShow: true
            }
        }
        case "ARRAY_SEC_DATA_UPDATE": {
            return {
                ...state, secArray: [
                    ...state.secArray,
                    action.data
                ], secData: {
                    ...state.secData,
                    id: 0,
                    row_type: 1,
                    description: "",
                    source: "",
                    value: 0
                }

            }
        }
        case "CHANGE_ROW_DATA": {
            return {
                ...state,
                secArray: Object.values({
                    ...state.secArray,
                    [action.index]: {
                        ...state.secArray[action.index],
                        [action.target.name]: action.target.value,
                        [action.target.name === "row_type" ? "source" : null]: "",
                        [action.target.name === "row_type" ? "description" : null]: "",
                        [action.target.name === "row_type" && action.target.value !== 3 ? "value" : null]: 0
                    }
                })

            }
        }
        case "DELETE_ROW_DATA": {
            return {
                ...state,
                secArray: [
                    ...state.secArray.slice(0, action.index),
                    ...state.secArray.slice(action.index + 1)
                ]

            }
        }
        case "ADDING_EXPENSE_DATA": {
            return {
                ...state,
                expArray: [
                    ...state.expArray,
                    action.data
                ]
                ,
                expense: {
                    id: 0,
                    description: "",
                    account: "",
                    private_percentage: "",
                    tax_percentage: ""
                },
                expenceData: {
                    ...state.expenceData,
                    source: "",
                    id: 0,
                    open: false,
                    bold: 0,
                    trsType : "winbook",
                    description: "",
                    value: 0,
                    book: null,
                    period: null
                }
            }
        }
        case "EXPENCE_EVENT_CHANGE": {
            return {
                ...state,
                expArray: Object.values({
                    ...state.expArray,
                    [action.index]: {
                        ...state.expArray[action.index],
                        source: action.target.NUMBER
                    }
                })
            }
        }
        case "UPDATE_SWITCH_UPDATE": {
            return {
                ...state,
                expArray: Object.values({
                    ...state.expArray,
                    [action.index]: {
                        ...state.expArray[action.index],
                        "bold": action.value
                    }
                })
            }
        }
        case "UPDATE_EXPANSE_EDIT_ALL_UPDATE": {
            return {
                ...state,
                expArray: Object.values({
                    ...state.expArray,
                    [action.index]: {
                        ...state.expArray[action.index],
                        [action.event.target.name]: action.event.target.value,
                        [action.event.target.name === "trsType" ? "source" : null]: "",
                        [action.event.target.name === "trsType" ? "bold" : null]: 0,
                        [action.event.target.name === "trsType" ? "description" : null]: ""
                    }
                })
            }
        }
        case "DELETE_EXPENSE_ROW": {
            return {
                ...state,
                expArray: [
                    ...state.expArray.slice(0, action.index),
                    ...state.expArray.slice(action.index + 1)
                ]

            }
        }
        case "EXPENSE_DATA_EDIT": {
            return {
                ...state,
                editExpenceShow: action.show,
                expense: action.data,
                expenseSelectedGroupData: action.allData
            }
        }
        case "CLEAR_EXPENCE_FILL": {
            return {
                ...state,
                editExpenceShow: false,
                expense: {
                    id: 0,
                    description: "",
                    account: "",
                    private_percentage: "",
                    tax_percentage: ""
                }
            }
        }
        case "REVENUE_DATA_SHOW_HIDE": {
            return {
                ...state,
                editAccDataShow: false,
                secData: {
                    id: 0,
                    section_id: 0,
                    row_type: 1,
                    description: "",
                    source: "",
                    value: 0
                }
            }
        }
        case "HANDLE_SINGLE_CHANGE": {
            return {
                ...state,
                report: {
                    ...state.report,
                    expenses_groups:Object.values({
                        ...state.report.expenses_groups,
                        [action.groupIndex]:{
                            ...state.report.expenses_groups[action.groupIndex],
                            expenses: Object.values({
                                ...state.report.expenses_groups[action.groupIndex].expenses,
                                [action.index]:{
                                    ...state.report.expenses_groups[action.groupIndex].expenses[action.index],
                                    [action.target.name]: action.target.value
                                }
                            })
                        }
                    }),
                    
                },
                expensesGroupUpdated:true,
                expensesUpdatedGroupIndex:action.groupIndex,
                expensesUpdatedRowIndex:action.index
            }
        }
        case "RESET_EXPENSES_UPDATE_FLAG": {
            return {
                ...state,
                expensesGroupUpdated:false,
                expensesUpdatedGroupIndex:0,
                expensesUpdatedRowIndex:0
            }
        }
        case "CLOSE_NEW_SECTION_DIALOG": {
            return {
                ...state,
                secArray: [],
                secttionPopUp: false
            }
        }
        case "STORE_GROUP_HEADER": {
            return {
                ...state,
                groupData: {
                    id: action.data.id,
                    parentId: 0,
                    bold: action.data.bold,
                    trsType : action.data.trsType,
                    description: action.data.description,
                    source: null,
                    value: 0,
                    book: null,
                    period: null,
                    open: action.con
                }
            }
        }

        case "STORE_GROUP_TITLE": {
            return {
                ...state,
                groupTitle: [...state.groupTitle, {
                    title: action.title
                }],
                groupData: {
                    id: 0,
                    open: action.con,
                    name: "",
                }
                
            }
        }
        case "STORE_EXPENSE_NAME": {
            return {
                ...state,
                expenseData: {
                ...state.expenseData,
                    id: action.data.id,
                    description: action.data.description,
                    trsType: action.data.trsType,
                    parentId: action.data.parentId,
                    dna: action.data.dna,
                    personal: action.data.personal,
                    value: action.data.value,
                },
                expenseHeadingPopShow: true
            }
        }
        case "CLOSE_EXPENSE_NEW_POP": {
            return {
                ...state,
                expenseData: {
                    id: 0, parentId: 0, trsType: "", description: "", source: null,
                    value: null, personal: null, dna: null, book: null, period: null,
                    mvReportId: null
                },
                expenseHeadingPopShow: false
            }
        }
        case "STORE_EXPENSE_TITLE": {
            return {
                ...state,
                expenseTitle: [...state.expenseTitle, {
                    title: action.title
                }],
                expenseData: {
                    id: 0,
                    open: action.con,
                    name: "",
                }
            }
        }

        case "CHANGE_GROUP_TEXT": {
            return {
                ...state,
                groupData: {
                    id: action.data.id,
                    parentId: 0,
                    trsType : action.data.trsType,
                    description: action.data.description,
                    source: null,
                    value: 0,
                    book: null,
                    period: null,
                    open: action.data.con
                }
            }
        }
        case "CHANGE_EXPENSE_TEXT": {
            return {
                ...state,
                expenseData: {
                    id: action.data.id,
                    open: action.data.con,
                    name: action.data.name,
                }
            }
        }
        case "HANDLE_CHANGE_EXPENSE": {
            return {
                ...state,
                expenceData: {
                ...state.expenceData,
                [action.name]: action.value,
                [action.name === "trsType" ? "source" : null]: "",
                [action.name === "trsType" ? "bold" : null]: 0,
                [action.name === "trsType" ? "description" : null]: "",
                [action.name === "trsType" ? "value" : null]: 0
                }
            }
        }
        case "HANDLE_ACCOUNTS_BUNDLE": {
            return {
                    ...state,
                    expensePopUp: true
            }
        }
        case "STORE_ARRANGE_ACCOUNT": {
            return {
                    ...state,
                    accArrangeData: action.data
            }
        }
        case "STORE_EXPANCE_ACCOUNT": {
            return {
                    ...state,
                    expArrangeData: action.data
            }
        }
        case "HANDLE_BOOL_POP": {
            return {
                    ...state,
                    company: action.bool
            }
        }
        case "SELECT_REPO_COUNTRY": {
            return {
                    ...state,
                    importCompany: action.company.id
            }
        }
        case "HANDLE_SWITCH_CHANGE": {
            return { ...state,
                groupData: {
                    ...state.groupData,
                    bold: action.swtch
                }
            }
        }
        case "INPUT_CHNANGE_Change": {
            return { ...state,
                inputChange: action.bool
            }
        }
        case "CHANGE_LIMIT_PROF": {
            return { 
                ...state,
                report: {
                    ...state.report,
                    report_option: action.data
                }
            }
        }
        default:
            break
            
    }
    return state
}
