import { API_URL,xauth } from "../Constants"


export function changeStatus(data) {
    return {
        type: "STORE_STATUS_REFRESH", data
    }
}

export function refreshStatusCheck(){
    return function(dispatch){
        fetch(API_URL+'/manage/quicksync/status',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json())               
          .then((json) =>{
              dispatch(changeStatus(json))
          });
    }
}
export function refresh() {
    return function (dispatch) {
        fetch(API_URL + "/manage/quicksync/do", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(changeStatus(json))
            })
    }
}
export function startLoading() {
    return {
        type: "LOADING_START"
    }
}

export function stopLoading() {
    return {
        type: "LOADING_STOP"
    }
}

export function updateUserRole(json) {
    return {
        type: "UPDATE_USER_ROLE",
        role: json
    }
}

export function getUserRole(){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/auth/user/role',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(updateUserRole(json));
              //dispatch(stopLoading());
          });
    }
}

export function getFolders(){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/contact/folders',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(storeFolders(json));
              dispatch(stopLoading());
          });
    }
}

export function getUsers(){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/users/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(storeUsers(json));
              dispatch(stopLoading());
          });
    }
}


export function storeFolders(list){
    return {
        type: 'STORE_FOLDERS',
        folders:list
    }
}

export function storeUsers(list){
    return {
        type: 'STORE_USERS',
        users:list
    }
}


export function storeBooks(list) {
    return {
        type: "STORE_BOOKS",
        books: list
    }
}

export function getBooks() {
    
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/params/books", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                dispatch(storeBooks(json))
                dispatch(stopLoading())
            })
    }
}

export function SnackBar(show,message,varient){ 
    return {
                type:'SHOW_ALERT',
                show : show,
                message : message,
                varient:varient,                
           } ;

}

export function handleHTTPError(response){
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;    
}