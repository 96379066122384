import React, { Fragment } from "react"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import  makeStyles  from "@emotion/styled"
import MenuItem from "@mui/material/MenuItem"
import Select from 'react-select';
import DeleteIcon from "@mui/icons-material/Delete"
import SettingsIcon from "@mui/icons-material/Settings"
import DoneIcon from "@mui/icons-material/Done"
import Chip from "@mui/material/Chip"
import SelectType from "@mui/material/Select"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import "../styles.css"

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        display: "flex"
    },
    divIcon: {
        paddingRight: "15px"
    },
    divData: {
        paddingBottom: "50px",
        textAlign: "left"
    },
    formControl: {
        width: "220px"
    },
    formControlWinBook: {
        width: "450px"
    },
    type: {
        width: "140px"
    },
    sourcetop: {
        paddingBottom: "30px"
    }
})
const getDataType = (type) => {
    switch (type) {
        case "winbook":
            return "Winbook Data"
        case "formula":
            return "Formula"
        case "manual":
            return "Manual Figure"
        default:
            break;
    }
}

const getSource = (value, data) => {


    let source = ""

    // for (let i = 0; i < value.length; i++) {

    for (let x = 0; x < data.length; x++) {

        if (value === data[x].NUMBER) {

            source = data[x].NUMBER + " : " + data[x].NAME11
        }
    }
    // }
    return source
}

const getChipLabel = (option) => {
    if( option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else  {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}

const selectWinBook = (option, arrayList) => {
    for (let x = 0; x < arrayList.length; x++) {
        if(arrayList[x].NUMBER === option) {
            return arrayList[x]
        }
    }
}

const getExpenceArray = (expArray, dnaReport, selectedAccount) => {
    let expence = []
    if( dnaReport !== null) {
        expence = dnaReport
        if(expArray !== null && expArray.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < expArray.length; x++) {
                    if(expArray[x].winData === undefined) { continue }
                    if(expArray[x].winData.NUMBER === expence[y].NUMBER) {
                        expence= [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }
        
        if(selectedAccount!==undefined && selectedAccount.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < selectedAccount.length; x++) {
                    if(selectedAccount[x].account === expence[y].NUMBER) {
                        expence= [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }
        
    }
    return expence
}

export default function SecDataRow(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const arrayList = getExpenceArray(props.sectionAllData.expArray, props.dnaReport, props.sectionAllData.expenseSelectedGroupData.expenses)

    const handleTrue = () => {
        setOpen(!open)
    }


    const handleExpenseRowUpdate = (event, index) => {
        props.handleExpenseRowUpdate(event, index)
    }
    const handleDeleteExpenceRow = () => {
        props.handleDeleteExpenceRow(props.eIndex)
    }

    return (
        <Fragment className="tableRow">{
            open === true ?
                <tr>
                    <td>
                        <FormControl className={classes.typeSelect}>
                            <SelectType
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="trsType"
                                value={props.data.trsType}
                                onChange={(e)=>props.handleExpenseDesUpdate(e, props.eIndex)}
                            >
                                <MenuItem value={"winbook"} >Winbook Data</MenuItem>
                                <MenuItem value={"formula"} >Formula</MenuItem>
                                <MenuItem value={"manual"} >Manual Figure</MenuItem>
                            </SelectType>
                        </FormControl>
                    </td>
                    <td>
                        <TextField
                            id="standard-password-input"
                            name="description"
                            onChange={(e)=>props.handleExpenseDesUpdate(e, props.eIndex)}
                            value={props.data.description}
                        />
                    </td>
                    <td>
                        {props.data.trsType === "winbook" ? 
                            <Select
                                inputId="react-select-multiple"
                                TextFieldProps={{
                                    InputLabelProps: {
                                        htmlFor: 'react-select-multiple',
                                        shrink: true,
                                    },
                                    placeholder: 'Select locations',
                                }}
                                options={arrayList}
                                getOptionLabel={option => getChipLabel(option)}
                                getOptionValue={option => `${option.NUMBER}`}
                                onChange={(e) => handleExpenseRowUpdate(e, props.eIndex)}
                                value={selectWinBook(props.data.source, arrayList)}
                                name="winData"
                            /> : "" }
                            {props.data.trsType === "formula" ? 
                                <TextField
                                id="standard-password-input"
                                onChange={(e)=>props.handleExpenseDesUpdate(e, props.eIndex)}
                                className="textFieldSecEdit"
                                name="source"
                                value={props.data.source}
                            /> : "" }
                            {props.data.trsType === "manual" ? 
                                <TextField
                                id="standard-password-input"
                                onChange={(e)=>props.handleExpenseDesUpdate(e, props.eIndex)}
                                className="textFieldSecEdit"
                                name="value"
                                value={props.data.value}
                            /> : "" }
                    </td>
                                <td>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={props.data.bold === 1}
                                                onChange={()=>props.handleSwitchUpdate(!props.data.bold, props.eIndex)}
                                                name="bold"
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>

                    <td> <DoneIcon onClick={handleTrue} /> </td>
                </tr>

                :
                <tr>
                    <td>{getDataType(props.data.trsType)}</td>
                    <td>{props.data.description}</td>
                    <td>{props.data.trsType === "winbook" ? getSource(props.data.source, props.dnaReport) : ""}{props.data.trsType === "formula" ? props.data.source : ""}{props.data.trsType === "manual" ? props.data.value : ""}</td>
                    <td>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.data.bold === 1}
                                    name="bold"
                                    color="primary"
                                />
                            }
                        />
                    </td>
                    <td className={classes.icon}>
                        <div className={classes.divIcon}><SettingsIcon onClick={handleTrue} /></div>
                        <div><DeleteIcon onClick={handleDeleteExpenceRow} /></div>
                    </td>
                </tr>
        }
        </Fragment>
    )
}
