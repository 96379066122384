import React, { Fragment } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import FolderIcon from "@mui/icons-material/Folder"
import DeleteIcon from "@mui/icons-material/Delete"
import SettingsIcon from "@mui/icons-material/Settings"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import ViewReport from "@mui/icons-material/Visibility"
import MainAppBar from "../common/MainAppBar"
import Loader from "../../Layouts/Common/Loader"
import Report from "./dialog-new-report"
import ReportSet from "./dialog-rep-set"
import GradeIcon from "@mui/icons-material/Grade"


const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752
    },
    demo: {
        backgroundColor: theme.palette.background.paper
    },
    title: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`
    }
})


const getColur = (value) => {
    let name = "noColor"
    switch (value) {
        case 0: {
            name = "redStar"
            return name
        }
        case 1: {
            name = "yeollowStar"
            return name
        }
        case 2: {
            name = "greenStar"
            return name
        }
        case 3: {
            name = "grayStar"
            return name
        }
        default:
            break
    }
    return name
}


const View = function(props) {

    const { classes } = props


    return (
        <Fragment>
            <MainAppBar title="MVR" createReport={props.handleClickOpen}  />
            <Loader />

            <div style={{ marginTop: 60 }} />
            <div className={classes.root} style={{ marginTop: 25 }}>

                <Grid container spacing={10}>
                    <Grid item xs={12} md={8}>
                        <div className={classes.demo}>
                            <List >
                                {props.reports.length > 0 ? props.reports.map((report, i) => (
                                    <ListItem key={"l" + i.toString()}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={report.name}
                                            secondary={report.description}
                                        />
                                        <ListItemSecondaryAction>

                                            {props.userRole.reportModify ?
                                                <IconButton onClick={() => props.handleReportSettings(report.id)} aria-label="Settings">
                                                    <ViewReport />
                                                </IconButton> : null}

                                            <IconButton aria-label="Settings" onClick={() => props.handleRepoSelect(report)}>
                                                <SettingsIcon />
                                            </IconButton>

                                            {props.userRole.reportModify ?
                                                <IconButton onClick={() => props.deleteReport(report.id)} aria-label="Delete">
                                                    <DeleteIcon />
                                                </IconButton> : null}

                                            <IconButton aria-label="Status">
                                                <GradeIcon className={getColur(report.status)} onClick={()=>props.handleChangeStatusColor(report.id)} fontSize="small" />
                                            </IconButton>

                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )) : null}
                            </List>
                        </div>
                    </Grid>
                </Grid>

            </div>
            <Report handleChange={props.handleChange} createReports={props.createReports} handleClose={props.handleClose} data={props.mvrList} />
            <ReportSet mvrList={props.mvrList} handleRepo={props.handleRepo} handleRepoDataUpdate={props.handleRepoDataUpdate} handleClearRepData={props.handleClearRepData} />
        </Fragment>)
}

View.propTypes = {
    classes: PropTypes.object.isRequired
}

export default (View)
