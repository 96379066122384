export default function  reducer(state={    
    loading:false,
    folders:[],
    users:[],
    books:[],
    showAlert:false,
    alertMessage:null,
    alertVarient:'info',
    userRole:{
        reportAccess:false,
        reportModify:false,
        analysisAccess:false,
        analysisModify:false,
        dnaAccess:false,
        dnaModify:false
    }    ,
    refresh: false,
    pauseRefresh: false
},action){
    switch(action.type){
        case "LOADING_START":{
            return{...state,loading:true}
        }
        case "LOADING_STOP":{
            return{...state,loading:false}
        }
        case "STORE_FOLDERS":{
            return{...state,folders:action.folders}
        }       
        case "STORE_USERS":{
            return{...state,users:action.users}
        }
        case "STORE_BOOKS":{
            return{...state,books:action.books}
        }
        case "SHOW_ALERT":{
            return{...state,showAlert:action.show,alertMessage:action.message,alertVarient:action.varient}
        }
        case "UPDATE_USER_ROLE":{
            return{...state,userRole:action.role}
        }
        case "REFRESH_START":{
            return{...state , refresh: true }
        }
        case "REFRESH_STOP":{
            return{...state , refresh: false }
        }
        case "STORE_STATUS_REFRESH": {
            return{...state , refresh: action.data.status==1 ? true :false}
        }
        case "LOADING_START": {
            return{...state , pauseRefresh: true }
        }
        case "LOADING_STOP": {
            return{...state , pauseRefresh: false }
        }
        
        
    }
    return state;
}