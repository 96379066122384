import React, { PureComponent, Fragment } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

const newReportDialog = function(props) {

    return (
        <Dialog open={props.data.showCreate} onClose={props.handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Create new report</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    onChange={props.handleChange("newname")}
                    value={props.data.newname}
                    margin="dense"
                    name="newname"
                    id="reaport-name"
                    label="Report Name"
                    type="text"
                    fullWidth
                />

                <TextField
                    autoFocus
                    margin="dense"
                    value={props.data.newdesciption}
                    name="newname"
                    id="reaport-description"
                    label="Description"
                    type="text"
                    onChange={props.handleChange("newdesciption")}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                Cancel
                </Button>
                <Button onClick={props.createReports} color="primary">
                Create
                </Button>
            </DialogActions>
        </Dialog>)
}
export default (newReportDialog)
