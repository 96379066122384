
const getDate = () => {
    var date = new Date()
    var month = date.getMonth()+1
    var retDate = date.getFullYear() + "/" + month + "/" + date.getDate()  
    return  retDate
}
export default function reducer(
    state = {
        loyers: null,
        settingsData: { location: null, amount: null, beneficiaryInfo: "", fromPeriod: new Date(), location: "", toPeriod: "" },
        account: null,
        storeLoyers: null,
        updated: false

    }, action) {
    switch (action.type) {
        case "HANDLE_LOYERS_SETTINGS": {
            return {
                ...state,
                settingsData: {
                    ...state.settingsData,
                    [action.name]: action.value
                }
            }
        }
        case "CLEAR_LOYERS_SETTINGS": {
            return {
                ...state,
                settingsData: {
                    amount: "",
                    beneficiaryInfo: "",
                    fromPeriod: "",
                    location: "",
                    toPeriod: ""
                }
            }
        }

        case "HANDLE_SEND_DATA": {

            return {
                ...state,
                loyers: {
                    ...state.loyers,
                    transactions: [
                        ...state.loyers.transactions,
                        {
                            // periods: action.data.fromDate + " / " + action.data.toDate,
                            fromPeriodFormatted: action.data.fromPeriod.getFullYear !==undefined ? action.data.fromPeriod.getDate()+"/"+action.data.fromPeriod.getMonth()+"/"+action.data.fromPeriod.getFullYear():action.data.fromPeriodFormatted,
                            fromPeriod: action.data.fromPeriod.getFullYear !==undefined ? action.data.fromPeriod.getFullYear() + "-" + action.data.fromPeriod.getMonth() + "-" + action.data.fromPeriod.getDate():action.data.fromPeriod,
                            toPeriod: action.data.toPeriod.getFullYear !==undefined ? action.data.toPeriod.getFullYear() + "-" + action.data.toPeriod.getMonth() + "-" + action.data.toPeriod.getDate():action.data.toPeriod,
                            toPeriodFormatted: action.data.toPeriod.getFullYear !==undefined ? action.data.toPeriod.getDate()+"/"+action.data.toPeriod.getMonth()+"/"+action.data.toPeriod.getFullYear():action.data.toPeriodFormatted,
                            beneficiaryInfo: action.data.beneficiaryInfo,
                            location: action.data.location,
                            amount: action.data.amount,
                            amountFormatted: action.data.amount
                        }
                    ]
                }, updated: true
            }
        }

        case "HANDLE_EDIT_DATA_LOYERS": {
            return {
                ...state, loyers: {
                    ...state.loyers,
                    transactions: Object.values({
                        ...state.loyers.transactions,
                        [action.index]: {
                            ...state.loyers.transactions[action.index],
                            [action.target.name]: action.target.value
                        }
                    })
                }
            }
        }
        case "HANDLE_EDIT_AMOUNT": {
            return {
                ...state, loyers: {
                    ...state.loyers,
                    transactions: Object.values({
                        ...state.loyers.transactions,
                        [action.index]: {
                            ...state.loyers.transactions[action.index],
                            [action.target.name]: action.target.value,
                            "amountFormatted": action.target.value
                        }
                    })
                }
            }
        }
        case "HANDLE_EDIT_NAME_ADD": {
            return {
                ...state, loyers: {
                    ...state.loyers,
                    transactions: Object.values({
                        ...state.loyers.transactions,
                        [action.index]: {
                            ...state.loyers.transactions[action.index],
                            beneficiaires: {
                                ...state.loyers.transactions[action.index],
                                [action.target.name]: action.target.value
                            }
                        }
                    })
                }

            }
        }

        case "HANDLE_ACCOUNT_CHANGE": {
            return { ...state, 
                updated: true,
                loyers: {
                    ...state.loyers,
                    account:{
                        ...state.loyers.account,
                        accountNumber:action.acc.NUMBER
                    }
                }
            }
        }

        case "STORE_LOYERS": {
            return {
                ...state, loyers: action.data
            }
        }
        case "UPDATE_STOP": {
            return { ...state, updated: false }
        }
        case "UPDATE_START": {
            return { ...state, updated: true }
        }
        case "HANDLE_DELETE_LOYERS": {
            return {
                ...state,
                updated: true,
                loyers: {
                    ...state.loyers,
                    transactions: [
                        ...state.loyers.transactions.slice(0, action.index),
                        ...state.loyers.transactions.slice(action.index + 1)
                    ]
                }
            }
        }
        default:
            break

    }
    return state
}

