import { Avatar, Link, Paper } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { Fragment, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandLess } from "@mui/icons-material";
import StarIcon from '@mui/icons-material/Star';


function dot(row) {   
    return <div class={row.matched === 1 ? "green-dot" : "grey-dot"}></div>;
}

function AccountCheckDot() {   
    return <div class={"blue-dot"}></div>;
}


function Tree({ data = [], props, level }) {
    return (<Fragment>
        {data.map((item) => <TreeNode row={item} level={level} props={props}  /> )}
    </Fragment>);
}

function TreeNode({row, level, props}) {
    const [open, setOpen] = useState(true);
    const hasChildren = row.children ? true : false;
    const isFormula = row.rowType === "formula" ? true : false;

    if (row.rowType === "account") {

        var text = row.accountNumber+" "+row.description;
            text = text.toLowerCase();

        if( props.accountStatusFilter != null){
            if(row.matched != props.accountStatusFilter){
                return null;
            }    
        }
        
        if(props.accountCheckFilter == true){
            if(row.accountCheck == false){
                return null;
            }    
        }

        if(props.searchText != ""){
            if(text.indexOf((props.searchText).toLowerCase()) === -1){
                return null;
            }
        }
    }

    return (<Fragment>
        <TableRow className={`${hasChildren ? "group-" + level : "row-" + level}`} key={row.id}>
            <TableCell >  {hasChildren ? <IconButton size="small" onClick={() => setOpen(!open)}> {open ? <ExpandLess /> : <ExpandMoreIcon />} </IconButton> : <Fragment>{isFormula === false ? <IconButton onClick={() => props.onMarkClick(row)} size="small">{row.star === 1 ? <StarIcon style={{ color: 'gold' }} /> : <StarIcon />}</IconButton> :null}</Fragment>}   </TableCell >
            <TableCell className="row-description" >
                <p style={{ paddingLeft: (20 * level) + "px", display: "block", margin: "0" }} >                
                    {row.star === 1 ? <div className="click-row" onClick={() => props.onClick(row)} component="button" underline="false">{row.accountNumber}&nbsp;&nbsp;{row.description}</div> : row.rowType === "annex" ? <Fragment><div className="click-row" onClick={() => props.onClick(row)} component="button" underline="false">{row.accountNumber}&nbsp;&nbsp;{row.description}</div></Fragment> : <Fragment>{row.accountNumber}&nbsp;&nbsp;{row.description}</Fragment> }
                </p>
            </TableCell>
            <TableCell align="center">
                {row.star === 1 ? dot(row) : null}                
            </TableCell>
            <TableCell align="center">                
                {row.accountCheck === true ? AccountCheckDot(row) : null}
            </TableCell>
            <TableCell align="right">{row.star === 1 ? <Link onClick={() => props.onClick(row)} component="button" underline="false">{row.currentCol}</Link> : <Fragment>{row.currentCol}</Fragment>}</TableCell>
            <TableCell align="right">{row.star === 1 ? <Link onClick={() => props.onClick(row)} component="button" underline="false">{row.differenceCol}</Link> : <Fragment>{row.differenceCol}</Fragment>}</TableCell>
            <TableCell align="right">{row.star === 1 ? <Link onClick={() => props.onClick(row)} component="button" underline="false">{row.compareCol}</Link> : <Fragment>{row.compareCol}</Fragment>}</TableCell>
        </TableRow>
        {hasChildren && open && (<Tree data={row.children} props={props} level={level + 1} />)}
    </Fragment>)
}



function AuditTable(props) {

    return <Paper className='widget-paper'>
        <Table aria-label="collapsible table" className="audit-table" style={{ fontSize: "11px" }}>
            <TableHead>
                <TableRow>
                    <TableCell className="actions" />
                    <TableCell className="account-name"></TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                    <TableCell className="current-col" align="center">{props.currentDate}</TableCell>
                    <TableCell className="difference-col" align="center"></TableCell>
                    <TableCell className="compare-col" align="center">{props.previousDate}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Tree data={props.data.data} props={props} level={0} />
            </TableBody>
        </Table>
    </Paper>;
}

export default AuditTable;