import { combineReducers } from 'redux';
import Reports from './Reports';
import Contacts from './Contacts'
import Common from './Common';
import Dashboard from './Dashboard';
import DNAReport from './DNAReport';
import { reducerMvrList, reducerMvrSettings } from '../components/annexes/MixedValueReport'
// import { PathRouterReducer } from '../Components/Annax'
import { dnaReducer } from '../components/annexes/Dna'
// import { reducerCompte } from '../Components/Compte-courant'
// import { reducerConcordance } from '../Components/Concordance'
import { reducerLoyers } from '../components/annexes/Loyers'
import { reducerSalaires } from '../components/annexes/Salaires'
// import { AccountReducer as Account } from "../screens/index"
// import { CalcullReducer } from "../Components/CalculISOC"
// import { estimationReducer } from "../Components/Estimation"
// import { checklistReducer } from "../Components/Checklist"
// import { IsoReducer as liqudationReducer } from "../Components/ISOCBottomPart"


export default combineReducers({
    Common,
    Reports,
    Contacts,
    Dashboard,
    DNAReport,
    reducerMvrList,
    reducerMvrSettings,
    // PathRouterReducer,
    dnaReducer,
    // reducerCompte,
    // reducerConcordance,
    reducerLoyers,
    reducerSalaires,
    // Account,
    // CalcullReducer,
    // estimationReducer,
    // liqudationReducer,
    // checklistReducer
});
