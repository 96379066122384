import React, { Fragment } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import clsx from "clsx"
import Radio from "@mui/material/Radio"
import makeStyles  from "@emotion/styled"
import Select from "react-select"
import ExpenceRow from "./expenseRow"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import SelectType from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import "../styles.css"

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    divData: {
        padding: "10px",
        textAlign: "left"
    },
    formControl: {
        width: "200px"
    },
    formControlWinBook: {
        width: "450px"
    },
    popMargin: {
        margin: "20px 0 20px 0"
    },
    expencestable: {
        width: 500,
        height: 700
    },
    typeSelect: {
        width: "140px",
        marginTop: "19px"
    }
})

function StyledRadio(props) {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

const getExpenceArray = (expArray, dnaReport, selectedAccount, arrayValue) => {
    let expence = []
    if (dnaReport !== null) {
        expence = dnaReport
        if (arrayValue !== null && arrayValue.length > 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < arrayValue.length; x++) {
                    if (arrayValue[x].NUMBER == expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }

                }
            }
        }

        if (expArray !== null && expArray.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < expArray.length; x++) {
                    // if(expArray[x].winData === undefined) { continue }
                    if (expArray[x].source === expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }

        if (selectedAccount !== undefined && selectedAccount.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < selectedAccount.length; x++) {
                    if (selectedAccount[x].account === expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }

    }
    return expence
}


const getChipLabel = (option) => {
    if (option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}


export default function AlertDialog(props) {
    const classes = useStyles()
    const [arrayValue, setArrayValue] = React.useState([])
    // const arrayList = getExpenceArray(props.sectionAllData.expArray, props.dnaReport, props.sectionAllData.expenseSelectedGroupData.expenses,arrayValue)
    function handleExpenseUpdate(event) {
        props.viewProps.contProps.handleDataArraySalary({ target: { value: event, name: "winArray" } })
        setArrayValue(event)
    }
    function handleAddSalary() {
        props.viewProps.handleAddSalary()
        setArrayValue([])
    }


    return (
        <div>
            <Dialog
                aria-labelledby="form-dialog-title"
                open={props.viewProps.contProps.reducerSalaires.dataArray.show}
                onClose={() => props.viewProps.contProps.handleSetDataPopUp({ show: false, list: [], list_obj: { id: 0, trsType: "winbook", description: "", source: null, value: 0 } })}
            >
                <DialogTitle id="alert-dialog-title" className="reportPopHeading">Add Data</DialogTitle>
                <DialogContent style={{ height: 400, width: 975 }} >
                    <DialogContentText id="alert-dialog-description">
                        <table className="expences-table">
                            <tr>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Account / Source</th>
                                <th>Bold</th>
                                <th />
                            </tr>
                            {props.viewProps.contProps.reducerSalaires.dataArray.list !== 0 ? props.viewProps.contProps.reducerSalaires.dataArray.list.map((data, eIndex) => (
                                <ExpenceRow
                                    dataProps={props}
                                    data={data}
                                    index={eIndex}
                                />
                            )) : ""}
                            <tr>
                                <td>
                                    <FormControl className={classes.typeSelect}>
                                        <SelectType
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.trsType}
                                            onChange={props.viewProps.contProps.handleDataArraySalary}
                                            name="trsType"
                                        >
                                            <MenuItem value={"winbook"} >Winbook Data</MenuItem>
                                            <MenuItem value={"formula"} onClick={() => setArrayValue([])} >Formula</MenuItem>
                                            <MenuItem value={"manual"} onClick={() => setArrayValue([])} >Manual Figure</MenuItem>
                                        </SelectType>
                                    </FormControl>
                                </td>
                                <td>
                                    <TextField
                                        id="standard-password-input"
                                        className="textFieldSec"
                                        name="description"
                                        value={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.description}
                                        onChange={props.viewProps.contProps.handleDataArraySalary}
                                        disabled={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.trsType === "winbook"}
                                    />
                                </td>
                                <td>
                                    {props.viewProps.contProps.reducerSalaires.dataArray.list_obj.trsType === "winbook" ?
                                        <Select
                                            inputId="react-select-multiple"
                                            options={props.viewProps.contProps.dnaReport.accounts}
                                            getOptionLabel={option => getChipLabel(option)}
                                            getOptionValue={option => `${option.NUMBER}`}
                                            onChange={(e) => handleExpenseUpdate(e)}
                                            value={arrayValue}
                                            isMulti={true}
                                        /> : ""
                                    }
                                    {props.viewProps.contProps.reducerSalaires.dataArray.list_obj.trsType === "formula" ?
                                        <TextField
                                            id="standard-password-input"
                                            className="textFieldSec"
                                            name="source"
                                            value={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.source}
                                            onChange={props.viewProps.contProps.handleDataArraySalary}
                                        /> : ""}
                                    {props.viewProps.contProps.reducerSalaires.dataArray.list_obj.trsType === "manual" ?
                                        <TextField
                                            id="standard-password-input"
                                            className="textFieldSec"
                                            name="value"
                                            value={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.value}
                                            onChange={props.viewProps.contProps.handleDataArraySalary}
                                        /> : ""}
                                </td>
                                <td>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={props.viewProps.contProps.reducerSalaires.dataArray.list_obj.bold === 1}
                                                onChange={()=>props.viewProps.contProps.handleDataArraySalaryBold(props.viewProps.contProps.reducerSalaires.dataArray.list_obj.bold)}
                                                name="bold"
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td>
                                    <Button onClick={handleAddSalary} color="primary" > Add </Button>
                                </td>
                            </tr>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.viewProps.contProps.handleSetDataPopUp({ show: false, list: [], list_obj: { id: 0, trsType: "winbook", description: "", source: null, value: 0 } })} color="primary">
                        cancel
                    </Button>
                    <Button onClick={props.viewProps.addChildrenToGroup} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
