import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import clsx from "clsx"
import Radio from "@mui/material/Radio"
import makeStyles  from "@emotion/styled"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Switch from "@mui/material/Switch"
import Chip from "@mui/material/Chip"
import "../styles.css"

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    divData: {
        paddingBottom: "50px",
        textAlign: "left"
    },
    formControl: {
        width: "220px"
    },
    formControlWinBook: {
        width: "450px"
    }
})


const selectWinBook = (option, arrayList) => {
    for (let x = 0; x < arrayList.length; x++) {
        if(arrayList[x].NUMBER == option) {
            return arrayList[x]
        }
    }
}

function StyledRadio(props) {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

export default function AlertDialog(props) {
    const classes = useStyles()
    const handleChange = (event) => {
        props.handleSectionDataType(event)
    }
    return (
        <div>
            <Dialog
                aria-labelledby="form-dialog-title"
                onClose={props.accDataShowHide}
                open={props.sectionAllData.editAccDataShow}
            >
                <DialogTitle id="alert-dialog-title" className="reportPopHeading">Update Data</DialogTitle> 
                <DialogContent id="sectionDialog">
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.divData} >
                            <div className="accountPop">
                                <TextField
                                    id="standard-password-input"
                                    onChange={props.handleChangeData}
                                    name="description"
                                    value={props.sectionAllData.secData.description}
                                    label="Description"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={props.sectionAllData.secData.bold === 1}
                                            onChange={() => props.handleChangeDataSwitch(!props.sectionAllData.secData.bold)}
                                            name="bold"
                                            color="primary"
                                        />
                                    }
                                    label="bold"
                                />
                            </div>
                            <div className="accountPop">
                            {props.sectionAllData.secData.trsType === "winbook" ?
                                <FormControl className={classes.formControlWinBook}>
                                    <InputLabel id="demo-simple-select-label">WinBook Data</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={props.handleChangeData}
                                        name="source"
                                        value={props.sectionAllData.secData.source}
                                    >
                                        {props.dnaReport !== [] ? props.dnaReport.map((data) => (
                                            <MenuItem key={data.NUMBER} value={data.NUMBER}>{data.NUMBER} : {data.NAME11}
                                            {data.account_balance !== 0 ? <Chip
                                              label={data.accountBalanceFormatted}
                                            /> : ""}</MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl> 
                                : "" } 
                                {props.sectionAllData.secData.trsType === "formula" ?
                                    <TextField
                                    id="standard-password-input"
                                    name="source"
                                    onChange={props.handleChangeData}
                                    value={props.sectionAllData.secData.source}
                                    label="Formula"
                                />: "" } 
                                
                            </div>
                            <div className="accountPop">
                                {props.sectionAllData.secData.trsType === "manual" ?
                                    <TextField
                                        id="standard-password-input"
                                        name="value"
                                        onChange={props.handleChangeData}
                                        value={props.sectionAllData.secData.value}
                                        label="value"
                                    /> : "" }
                            </div>
                        </div>
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Data Type</FormLabel>
                                <RadioGroup value={props.sectionAllData.secData.trsType} aria-label="gender" name="customized-radios" onChange={handleChange} >
                                    <FormControlLabel value={"winbook"} control={<StyledRadio />} label="Winbook Data" />
                                    <FormControlLabel value={"formula"} control={<StyledRadio />} label="Formula Data" />
                                    <FormControlLabel value={"manual"} control={<StyledRadio />} label="Manual Data" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.accDataShowHide} color="primary">
                        cancel
                    </Button>
                    <Button onClick={props.handleUpdateRevenueData} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
