import React, { Fragment } from "react"
// import { withStyles } from "@mui/material/styles"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
// import ReportHeader from "../../../Common/ReportHeader"
import Loader from "../../Layouts/Common/Loader"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import WinbookAddPop from "./Dialogs/winbookAddPop"
import UpdateData from "./Dialogs/updateData"
import MainTitle from "./Dialogs/mainTitle"
import Arrange from "./arrangeAcc"
import DeleteConfirm from "../../Common/delete"
import "./styles.css"

const styles = theme => ({
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    head: {
        fontSize: "20px",
        padding: "40px 0 0 25px",
        float: "left"
    },
    root: {
        marginTop: "30px",
        marginRight: "30px",
        float: "right"
    }

})

const amountPrint = (formet, amount) => {
    if (amount === 0 || formet === "0,00") {
        return " "
    }
    else {
        return formet
    }
}

const getDataTrue = (data) => {
    
    for (let x = 0; x < data.length; x++) {
        if(data[x].accountAmountFormatted !== "0,00") {
            return true
        }
    }
    return false
}


const getTitle = (periods, selectedPeriod, books, selectedBook, list, selectedReportId) => {
    if (selectedPeriod === null || selectedBook === null || selectedReportId === null) {
        return false
    }
    else {
        return <Fragment> {list.find((data) => { return data.id === selectedReportId; }).name}&ensp; {books.find((data) => { return data.value === selectedBook; }).label + " / "  + periods.find((data) => { return data.value === selectedPeriod; }).label }</Fragment>
    }
}

const View = function (props) {
    const { classes } = props
    const [accArrange, setAccArrange] = React.useState(false)
    const [deletePop, setDeletePop] = React.useState(false)
    const [storeId, setStoreId] = React.useState(null)
    const [mode, setmode] = React.useState(false)

    function arrangeAcchandle() {
        if (props.contProps.dnaReducer.arrane.length !== 0) {
            props.contProps.saveArrangeAcc(props.contProps.dnaReducer.arrane,props.aid,props.template_id)
        }
        setAccArrange(!accArrange)
    }
    function handleAccountData(section) {
        //props.contProps.getColoumn(section.vehicle, props.contProps.route.selectedBook, props.contProps.route.selectedPeriod, 0)
        props.contProps.handleUpdateDna({ id: section.id, vehicle: section.vehicle, dna:section.taxFormatted, parentId: section.parentId, show: true, trsType: section.trsType, description: section.description, bold: section.bold, source: section.source, value: section.value })
        if (section.trsType === "winbook") {
            props.handleEditAraay(section.source)
        }
    }

    const deleteShowTrue = (data, title) => {
        setDeletePop(true)
        setStoreId(data)
    }

    const confirmDelete = (confirm) => {
        if (confirm === true) {
            props.handleDnaData(storeId)
        }
        setDeletePop(false)
        setStoreId(null)
    }

    return (
        <Fragment>
            {/* <ReportHeader 
                annexType="dna"
                backAction={props.handleGoBack}
                dropDown={true}
                title={getTitle(props.contProps.route.periods, props.contProps.route.selectedPeriod, props.contProps.route.books, props.contProps.route.selectedBook, props.contProps.route.list, props.contProps.reducerMvrList.selectedReport)}
                viewProps={props}
                creatAnnex={props.handleOpen}
                handleRepoSelect={props.handleRepoSelect}
                handleAccountIssue={props.handleAccountIssue}
                /> */}
            <Loader />

            <div className="container-dna" >
                <div id='dna_grp_div'>
                    <Typography component="h3" variant="subheading" gutterBottom>
                        <Button size="small" id="dna_grp" variant="text" color="primary" onClick={() => props.contProps.handleGroup({ trsType: "group", id: 0, bold: 0, description: "", show: true })} >
                            Ajouter Groupe
                    </Button>
                        <Button size="small" variant="text" color="primary" onClick={() => props.contProps.handleWinBookData({ id: 0, parentId: 0, show: true, trsType: "winbook", description: "", bold: 0, source: null, value: 0 })}>
                            Ajouter Comptes
                    </Button>
                        <Button style={{ float: "right", padding: "4px 10px 0 20px" }} size="small" variant="text" color="primary" onClick={() => setmode(!mode)} >
                            {mode === true ? "live" : "Template View"}
                        </Button>
                        <Button style={{ float: "right", paddingRight: 35 }} id="dna_mod" size="small" variant="text" color="primary" onClick={() => setAccArrange(!accArrange)} >
                            {accArrange === true ? "Cancel" : "Modifier présentation"}
                        </Button>
                        <Button style={{ float: "right" }} id="dna_sav" size="small" variant="text" color="primary" onClick={() => arrangeAcchandle()} >
                            {accArrange === true ? "Save" : ""}
                        </Button>
                    </Typography>
                </div>
                {accArrange === true ?
                    <Arrange
                        viewData={props}
                        handleSave={() => setAccArrange(!accArrange)}
                    /> :
                    <table size="small" cellspacing="10" aria-label="a dense table" className="table-dna-revenue" >
                        <thead className="table-dna-revenue-thead" >
                            <tr className="group-color">
                                <th className="compte-width">Compte</th>
                                <th >Libellé du compte</th>
                                <th >solde</th>
                                <th className="Percent-width">%DNA</th>
                                <th className="montant-dna-width">Montant DNA</th>
                            </tr>
                        </thead>
                        {props.contProps.dnaReducer.storeDna !== null ?
                            <tbody>
                                {props.contProps.dnaReducer.storeDna.revenue.length !== 0 ? props.contProps.dnaReducer.storeDna.revenue.map((data) => {
                                    return mode === false ? <Fragment key={data}>
                                        {data.trsType === "group" ?
                                            <Fragment>
                                            {data.children !== undefined && data.children.length !== 0 && getDataTrue(data.children) ?
                                                <tr className="group">
                                                    <td colSpan={2} className="revenue-section-head-description padding-top-bottom-10 data-border" align="left" style={{fontWeight:"bold", textAlign: "left" }}>
                                                        {data.description}
                                                    </td>
                                                    <td className="group-color" style={{ fontWeight:"bold", textAlign: "right" }}>{amountPrint(data.accountAmountFormatted)}</td>
                                                    <td className="group-color"></td>
                                                    <td className="group-color" style={{ fontWeight:"bold",textAlign: "right" }}>{amountPrint(data.accountBalanceTotalFormatted)}</td>
                                                </tr>:""}
                                                {data.children !== undefined && data.children.length !== 0 ? data.children.map((section) => (
                                                    section.accountAmountFormatted !== "0,00" ?
                                                        <Fragment key={section.id}>
                                                            <tr>
                                                                <td className="revenue-section-head-description revenue-sub-group" style={{ paddingLeft: 40, fontWeight: section.bold === 1 ? "bold" : "normal" }}>{section.trsType === "voiture" ? section.veh_name : section.source}</td>
                                                                <td className="revenue-section-head-description revenue-sub-group" style={{ textAlign: "left", fontWeight: section.bold === 1 ? "bold" : "normal" }}>
                                                                    {section.description} {section.licence_plate}
                                                                </td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }}>{amountPrint(section.accountAmountFormatted, section.accountAmount)}</td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }} >{amountPrint(section.taxFormatted)}</td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }} >{amountPrint(section.balanceFormatted, section.balance)}</td>
                                                            </tr>
                                                        </Fragment> : ""
                                                )) : ""}
                                            </Fragment> :
                                            <Fragment>
                                                {data.accountAmountFormatted !== "0,00" ?
                                                    <tr className="winbook">
                                                        <td className="revenue-section-head-description border-bottom" style={{ textAlign: "left", fontWeight: data.bold === 1 ? "bold" : "normal" }}>{data.trsType === "voiture" ? data.veh_name : data.source}</td>
                                                        <td className="revenue-section-head-description border-bottom" style={{ textAlign: "left", fontWeight: data.bold === 1 ? "bold" : "normal" }}>
                                                            {data.description} {data.licence_plate}
                                                        </td>
                                                        <td className=" border-bottom group-color" style={{ textAlign: "right", fontWeight: data.bold === 1 ? "bold" : "normal" }} >{amountPrint(data.accountAmountFormatted, data.accountAmount)}</td>
                                                        <td className="debit-column border-bottom group-color" style={{ fontWeight: data.bold === 1 ? "bold" : "normal" }} >{data.taxFormatted}</td>
                                                        <td className="debit-column border-bottom group-color" style={{ fontWeight: data.bold === 1 ? "bold" : "normal" }} >{amountPrint(data.balanceFormatted, data.balance)}</td>
                                                    </tr> : ""}
                                            </Fragment>}
                                    </Fragment> : <Fragment key={data}>
                                            {data.trsType === "group" ?
                                                <Fragment>
                                                    <tr className="group">
                                                        <td colSpan={2} className="revenue-section-head-description padding-top-bottom-10 data-border" align="left" style={{fontWeight:"bold", textAlign: "left" }}>
                                                            {data.description}
                                                            <div className="tr-actions">
                                                                <IconButton size="small" aria-label="Add" onClick={() => props.contProps.handleWinBookData({ id: 0, parentId: data.id, show: true, trsType: "winbook", description: "", bold: 0, source: null, value: 0 })} > <AddIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.handleGroup({ id: data.id, parentId: data.parentId, description: data.description, trsType: data.trsType, show: true })} > <EditIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data.id)} > <DeleteIcon fontSize="small" /> </IconButton>
                                                            </div>
                                                        </td>
                                                        {data.children !== undefined && data.children.length !== 0 && getDataTrue(data.children) ?
                                                            <Fragment>
                                                                <td className="group-color" style={{ fontWeight:"bold", textAlign: "right" }}>{amountPrint(data.accountAmountFormatted)}</td>
                                                                <td className="group-color"></td>
                                                                <td className="group-color" style={{ fontWeight:"bold",textAlign: "right" }}>{amountPrint(data.accountBalanceTotalFormatted)}</td>
                                                            </Fragment>
                                                        :""}
                                                    </tr>
                                                    {data.children !== undefined && data.children.length !== 0 ? data.children.map((section) => (
                                                        <Fragment key={section.id}>
                                                            <tr>
                                                                <td className="revenue-section-head-description revenue-sub-group" style={{ paddingLeft: 40, fontWeight: section.bold === 1 ? "bold" : "normal" }}>{section.trsType === "voiture" ? section.veh_name : section.source}</td>
                                                                <td className="revenue-section-head-description revenue-sub-group" style={{ textAlign: "left", fontWeight: section.bold === 1 ? "bold" : "normal" }}>
                                                                    {section.description} {section.licence_plate}
                                                                    <div className="tr-actions">
                                                                        <IconButton size="small" aria-label="Edit" onClick={() => handleAccountData({ id: section.id, vehicle: section.vehicle_id, taxFormatted:section.taxFormatted, parentId: data.id, show: true, trsType: section.trsType, description: section.description, bold: section.bold, source: section.source, value: section.value })} > <EditIcon fontSize="small" /> </IconButton>
                                                                        <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(section.id)} > <DeleteIcon fontSize="small" /> </IconButton>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }}>{section.accountAmountFormatted}</td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }} >{section.taxFormatted}</td>
                                                                <td style={{ textAlign: "right", fontWeight: section.bold === 1 ? "bold" : "normal" }} >{section.balanceFormatted}</td>
                                                            </tr>
                                                        </Fragment>
                                                    )) : ""}
                                                </Fragment> :
                                                <Fragment>
                                                    <tr className="winbook">
                                                        <td className="revenue-section-head-description border-bottom" style={{ textAlign: "left", fontWeight: data.bold === 1 ? "bold" : "normal" }}>{data.trsType === "voiture" ? data.veh_name : data.source}</td>
                                                        <td className="revenue-section-head-description border-bottom" style={{ textAlign: "left", fontWeight: data.bold === 1 ? "bold" : "normal" }}>
                                                            {data.description} {data.licence_plate}
                                                            <div className="tr-actions">
                                                                <IconButton size="small" aria-label="Edit" onClick={() => handleAccountData(data)} > <EditIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data.id)}  > <DeleteIcon fontSize="small" /> </IconButton>
                                                            </div>
                                                        </td>
                                                        <td className=" border-bottom group-color" style={{ textAlign: "right", fontWeight: data.bold === 1 ? "bold" : "normal" }} >{data.accountAmountFormatted}</td>
                                                        <td className="debit-column border-bottom group-color" style={{ fontWeight: data.bold === 1 ? "bold" : "normal" }} >{data.taxFormatted}</td>
                                                        <td className="debit-column border-bottom group-color" style={{ fontWeight: data.bold === 1 ? "bold" : "normal" }} >{data.balanceFormatted}</td>
                                                    </tr>
                                                </Fragment>}
                                        </Fragment>
                                }) : ""}
                                <tr> <td colSpan="5" style={{paddingTop:15}}> </td></tr>
                                {props.contProps.dnaReducer.storeDna.revenueTotalFormtted !== "0,00" ?
                                <tr className="dna-total">
                                    <th>Total</th>
                                    <th></th>
                                    <th className="debit-column padding-top-bottom-10 group-color" style={{ fontWeight: 700 }}>{amountPrint(props.contProps.dnaReducer.storeDna.revenueTotalFormtted, props.contProps.dnaReducer.storeDna.revenueTotal)}</th>
                                    <th className="group-color"></th>
                                    <th className="debit-column padding-top-bottom-10 group-color" style={{ fontWeight: 700 }}>{amountPrint(props.contProps.dnaReducer.storeDna.revenueBalanceTotalFormtted)}</th>
                                </tr> : ""}
                            </tbody> : ""}
                    </table>}

            </div>
            
            <WinbookAddPop
                viewProps={props}
                dnaReport={props.contProps.dnaReport}
                sectionAllData={props.sectionAllData}
            />

            <UpdateData
                viewProps={props}
                dnaReport={props.contProps.dnaReport}
                sectionAllData={props.sectionAllData}
                expenseDate={props.expenseDate}
            />

            <MainTitle
                viewProps={props}
            />

            <DeleteConfirm
                open={deletePop}
                title={"ligne"}
                confirmDelete={confirmDelete}
            />

        </Fragment>)
}

export default (View)
