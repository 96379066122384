import { functions } from "lodash"
import { API_URL, xauth } from "../../../Constants"

export function startLoading() {
    return { type: "LOADING_START" }
}

export function stopLoading() {
    return { type: "LOADING_STOP" }
}

export function storeAccounts(list) {
    return {
        type: "STORE_DNA_ACCOUNTS",
        accounts: list
    }
}

export function getAccounts(auditId) {
    return function (dispatch) {
        
        dispatch(startLoading())
        fetch(API_URL + "/dna/get/accounts?auditid=" + auditId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeAccounts(json))
                dispatch(stopLoading())
            })
    }
}

// export function getAccounts(sBkV, sPdv, repId, aId) {
//     return function (dispatch) {
//         if (aId === undefined) {
//             aId = 0
//         }
//         dispatch(startLoading())
//         fetch(API_URL + "/annex/current-account/accounts?b=" + sBkV + "&p=" + sPdv + "&repId=" + repId + "&aId=" + aId, {
//             method: "GET",
//             headers: {
//                 "Content-Type": "application/json;charset=utf-8",
//                 "X-Auth-Token": xauth
//             }
//         }).then(response => response.json())
//             .then((json) => {
//                 dispatch(storeAccounts(json))
//                 dispatch(stopLoading())
//             })
//     }
// }

export function handleloyersSettings(value, name) {
    return { type: "HANDLE_LOYERS_SETTINGS", value, name }
}

export function clearLoyersSettings() {
    return { type: "CLEAR_LOYERS_SETTINGS" }
}

export function storeLoyers(data) {
    return { type: "STORE_LOYERS", data }
}

export function getLoyers(templateId,auditId) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/annex/loyers/get?aid="+ auditId+"&tid="+templateId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeLoyers(json))
                dispatch(stopLoading())
                dispatch(clearLoyersSettings())
            })
    }
}

export function handleSendData(data) {
    return { type: "HANDLE_SEND_DATA", data }

}

export function handleEditData(event, index) {
    if (event.target.name === "name" || event.target.name === "address") {
        return { type: "HANDLE_EDIT_NAME_ADD", target: event.target, index }
    }
    else if(event.target.name === "amount") {
        return { type: "HANDLE_EDIT_AMOUNT", target: event.target, index }
    }
    else{
        return { type: "HANDLE_EDIT_DATA_LOYERS", target: event.target, index }
    }
}

export function handleAccountChange(acc) {
    return { type: "HANDLE_ACCOUNT_CHANGE", acc }
}

export function updateStop() {
    return { type: "UPDATE_STOP" }
}

export function updateLoyers(reportData, auditId,templateId) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(updateStop())
        fetch(API_URL + "/annex/loyers/update?aid="+ auditId+"&tid="+templateId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(reportData)
        }).then(response => response.json())
            .then(() => {
                dispatch(getLoyers(templateId,auditId))
                dispatch(stopLoading())
            })
    }
}

export function updateStart() {
    return { type: "UPDATE_START" }
}

export function handleDeleteLoyers(index) {
    return { type: "HANDLE_DELETE_LOYERS", index }
}
