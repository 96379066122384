// eslint-disable-next-line complexity
export default function reducer(
    state = {
        salryArray: null,
        salryArrayUpdate: false,
        group: { show: false, parentId: 0, trsType: null, id: 0, description: "" },
        salary: [],
        dataArray: { show: false, trsType: "", list: [], list_obj: { id: 0, parentId: 0, bold: 0, trsType: "winbook", winArray: [], description: "", source: null, value: 0 } },
        updateData: { show: false, index: null, trsType: "", mainIndex: null, list_obj: { id: 0, parentId: 0, bold: 0, trsType: "winbook", description: "", source: null, value: 0 } }
    }, action) {
    switch (action.type) {
        case "HANDLE_GROUP_NAME": {
            return { ...state, group: action.data }
        }
        case "STORE_SELARY_REPORT": {
            return { ...state, salryArray: action.array }
        }
        case "HANDLE_GROUP_HEADING": {
            return {
                ...state, group: {
                    ...state.group,
                    [action.target.name]: action.target.value
                }
            }
        }
        case "ADD_SALARY_ARRAY": {
            return {
                ...state,
                group: { show: false, id: 0, parentId: 0, trsType: null, description: "" }
            }
        }
        case "HANDLE_SET_DATA_POP_UP": {
            return {
                ...state,
                dataArray: action.array
            }
        }
        case "HANDLE_DATA_ARRAY_SALARY": {
            return {
                ...state, dataArray: {
                    ...state.dataArray,
                    list_obj: action.target.name === "trsType" ?
                        {
                            ...state.dataArray.list_obj,
                            id: 0,
                            bold: 0,
                            trsType: action.target.value,
                            winArray: [],
                            description: "",
                            source: null,
                            value: 0
                        } :
                        {
                            ...state.dataArray.list_obj,
                            [action.target.name]: action.target.value
                        }
                }
            }
        }
        case "HANDLE_DATA_ARRAY_SALARY_BOLD": {
            return {
                ...state, dataArray: {
                    ...state.dataArray,
                    list_obj: {
                        ...state.dataArray.list_obj,
                        bold: action.checked
                    }
                }
            }
        }
        case "HANDLE_ADD_SALARY": {
            return {
                ...state, dataArray: {
                    ...state.dataArray,
                    list: [...state.dataArray.list,
                    action.obj
                    ],
                    list_obj: {
                        ...state.dataArray.list_obj,
                        trsType: "winbook", description: "", source: null, value: 0, bold: 0
                    }
                }
            }
        }
        case "HANDLE_ROW_DATA": {
            return {
                ...state, dataArray: {
                    ...state.dataArray,
                    list: Object.values({
                        ...state.dataArray.list,
                        [action.index]: action.target.name === "trsType" ?
                            {
                                ...state.dataArray.list[action.index],
                                description: "",
                                trsType: action.target.value,
                                source: "",
                                value: 0
                            } :
                            {
                                ...state.dataArray.list[action.index],
                                [action.target.name]: action.target.value
                            }
                    })
                }
            }
        }
        case "DELETE_SALARY_ARRAY_ROW":
            return {
                ...state, dataArray: {
                    ...state.dataArray,
                    list: [
                        ...state.dataArray.list.slice(0, action.index),
                        ...state.dataArray.list.slice(action.index + 1)
                    ]
                }
            }
        case "ADD_CHILDREN_TO_GROUP":
            return {
                ...state, salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.index]: {
                            ...state.salryArray.data[action.index],
                            addGroup: {
                                ...state.salryArray.data[action.index].addGroup,
                                children: [
                                    ...state.salryArray.data[action.index].addGroup.children,
                                    action.array
                                ]
                            }
                        }
                    })
                },
                dataArray: { show: false, trsType: "", list: [], list_obj: { id: 0, parentId: 0, bold: 0, trsType: "winbook", winArray: [], description: "", source: null, value: 0 } },
                salryArrayUpdate: true
            }
        case "ADD_CHILDREN_TO_GROUP_DEDUCT":
            return {
                ...state, salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.index]: {
                            ...state.salryArray.data[action.index],
                            deductGroup: {
                                ...state.salryArray.data[action.index].deductGroup,
                                children: [
                                    ...state.salryArray.data[action.index].deductGroup.children,
                                    action.array
                                ]
                            }
                        }
                    })
                },
                dataArray: { show: false, trsType: "", list: [], list_obj: { id: 0, parentId: 0, bold: 0, trsType: "winbook", winArray: [], description: "", source: null, value: 0 } },
                salryArrayUpdate: true
            }
        case "STOP_SALARY_ARRAY_UPDATE":
            return {
                ...state,
                salryArrayUpdate: false,
            }
        case "SHOW_UPDATE_POP_UP":
            return {
                ...state,
                updateData: {
                    ...state.updateData,
                    show: true,
                    trsType: action.trsType,
                    mainIndex: action.mainIndex,
                    index: action.index,
                    list_obj: {
                        id: action.data.id,
                        parentId: action.data.parentId,
                        description: action.data.description,
                        bold: action.data.bold,
                        trsType: action.data.trsType,
                        source: action.data.source,
                        value: action.data.accountAmountFormatted
                    }
                }
            }
        case "Hide_UPDATE_POP_UP":
            return {
                ...state,
                updateData: {
                    ...state.updateData,
                    show: false,
                    index: null,
                    trsType: "",
                    mainIndex: null,
                    list_obj: { id: 0, parentId: 0, bold: 0, trsType: "winbook", description: "", source: null, value: 0 }
                }
            }
        case "CHANGE_UPDATE_DATA_ADD":
            return {
                ...state,
                updateData: {
                    ...state.updateData,
                    list_obj: action.name === "trsType" ? {
                        ...state.updateData.list_obj,
                        bold: 0,
                        trsType: action.value,
                        description: "",
                        source: null,
                        value: 0
                    } :
                        {
                            ...state.updateData.list_obj,
                            [action.name]: action.value
                        }
                }
            }
        case "SAVE_UPDATE_DATA_ADD":
            return {
                ...state,
                salryArrayUpdate: true,
                salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.value.mainIndex]: {
                            ...state.salryArray.data[action.value.mainIndex],
                            addGroup: {
                                ...state.salryArray.data[action.value.mainIndex].addGroup,
                                children: Object.values({
                                    ...state.salryArray.data[action.value.mainIndex].addGroup.children,
                                    [action.value.index]: action.value.list_obj
                                })
                            }
                        }
                    })
                }
            }
        case "SAVE_UPDATE_DATA_DETUCT":
            return {
                ...state,
                salryArrayUpdate: true,
                salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.value.mainIndex]: {
                            ...state.salryArray.data[action.value.mainIndex],
                            deductGroup: {
                                ...state.salryArray.data[action.value.mainIndex].deductGroup,
                                children: Object.values({
                                    ...state.salryArray.data[action.value.mainIndex].deductGroup.children,
                                    [action.value.index]: action.value.list_obj
                                })
                            }
                        }
                    })
                }
            }
        case "DELETE_DATA_LIST_ADD":
            return {
                ...state,
                salryArrayUpdate: true,
                salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.index]: {
                            ...state.salryArray.data[action.index],
                            addGroup: {
                                ...state.salryArray.data[action.index].addGroup,
                                children: action.array
                            }
                        }
                    })
                }
            }
        case "DELETE_DATA_LIST_DEDUCT":
            return {
                ...state,
                salryArrayUpdate: true,
                salryArray: {
                    ...state.salryArray,
                    data: Object.values({
                        ...state.salryArray.data,
                        [action.index]: {
                            ...state.salryArray.data[action.index],
                            deductGroup: {
                                ...state.salryArray.data[action.index].deductGroup,
                                children: action.array
                            }
                        }
                    })
                }
            }
        default:
            break

    }
    return state
}
