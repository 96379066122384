import { ArrowBack, ArrowForward, Star } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';

function AccountHeaderBar(props){
    
    return <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={2} alignItems="center">
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={8}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <IconButton>
              {/* Icon Component */}
              {/* Replace 'YourIcon' with your actual icon component */}
              {props.data.star === 1 ? <StarIcon style={{ color: 'gold' }}/> : <StarIcon/> }
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">{props.data.account_number}&nbsp;&nbsp;{props.data.account_name}</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={4}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <IconButton onClick={()=>props.onPrevAccount()} ><ArrowBack /></IconButton>
          </Grid>
          <Grid item>

            <IconButton onClick={()=>props.onNextAccount()} ><ArrowForward /></IconButton>

          </Grid>
        </Grid>
      </Grid>
    </Grid>

  </Paper>;
}

export default AccountHeaderBar