export default function  reducer(state={
    list: [],
    loading: false,
    createLoading: false,
    showReportSettings: null,
    reportSettings: null,
    reportData: null,
    selectedBook: null,
    selectedFromPeriod: null,
    selectedToPeriod: null,
    companies: []
},action){
    switch(action.type){
        case "LOADING_START":{
            return{...state,loading:true}
        }
        case "LOADING_STOP":{
            return{...state,loading:false}
        }
        case "STORE_REPORTS":{
            return{...state,list:action.reports}
        }
        case "STORE_REPORT_COMPANIES":{
            return{...state,companies:action.companies}
        }
        case "SHOW_SETTINGS":{
            return{...state,showReportSettings:action.report}
        }
        case "LOADED_SETTINGS":{
            return{...state,reportSettings:action.settings}
        }
        case "UPDATE_REPORT_BASIC":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            [action.field]:action.value                        
                        }
                            
                   }
        }
        case "ADD_COLUMN":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:[
                                ...state.reportSettings.columns,
                                action.column
                            ]                        
                        }
                            
                   }
        }
        case "UPDATE_COLUMN":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:Object.values({
                                ...state.reportSettings.columns,
                                [action.index]:{
                                    ...state.reportSettings.columns[action.index],
                                    [action.field]:action.value  
                                }
                            })                        
                        }
                            
                   }
        }
        
        case "MOVE_COLUMN":{

            const colList = state.reportSettings.columns;
            const [removed] = colList.splice(action.start, 1);
            colList.splice(action.end, 0, removed);

            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:colList                        
                        }
                            
                   }
        }
        case "UPDATE_DETAILS":{

            const colList = state.reportSettings.columns;
            const [removed] = colList.splice(action.start, 1);
            colList.splice(action.end, 0, removed);

            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            detail:action.details                        
                        }
                            
                   }
        }
        case "LOADED_DATA":{
            return{...state,reportData:action.data}
        }
        case "SET_BOOK":{
            return{...state,selectedBook:action.book,selectedFromPeriod:action.from,selectedToPeriod:action.to}
        }
        case "REMOVE_COLUMN":{

            return{...state,
                reportSettings:{
                    ...state.reportSettings,
                    columns:[
                        ...state.reportSettings.columns.slice(0,action.column),
                        ...state.reportSettings.columns.slice(action.column+1)
                        
                    ]                        
                }
                    
           }
            return{...state,selectedBook:action.book,selectedFromPeriod:action.from,selectedToPeriod:action.to}
        }

        
        

    }
    return state;
}