import React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
const getName = ( name ) => {
    switch (name) {
        case "group":
            return "Group"
        case "total":
            return "Total"
        case "expenses":
            return "Expenses"
        default:
            break;
    }
}
export default function GroupDialog(props) {
    return (
        //  
        <Dialog aria-labelledby="form-dialog-title" open={props.groupData.open}  onClose={() => props.handleGroup(false, {id: 0, name: "" })} >
            <DialogTitle id="form-dialog-title">{getName(props.groupData.trsType)} settings</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    onChange={(e) => props.handleText(e)}
                    value={props.groupData !== null ? props.groupData.description : ""}
                    margin="dense"
                    id="Group-name"
                    label={getName(props.groupData.trsType) + " Name"}
                    fullWidth
                />
                {props.groupData.trsType !== "group" ?
                <FormControlLabel
                    control={
                        <Switch
                            checked={props.groupData.bold === 1}
                            onClick={props.handleSwitchChange}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="bold"
                /> : " "}
            </DialogContent>
            <DialogActions>
                
                <Button  color="primary" onClick={() => props.handleGroup(false, {id: 0, name: "" })}>
                Cancel
                </Button>
                
                <Button color="primary" onClick={()=> props.handleGroupTextSave() } >
                Save
                </Button>
            </DialogActions>
        </Dialog>)
}
