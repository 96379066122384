import React, { Fragment } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import styled from '@emotion/styled'
import Select from "react-select"
import WinBookAddRow from "./winBookAddRow"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import SelectType from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import "../styles.css"

const useStyles = styled({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    divData: {
        padding: "10px",
        textAlign: "left"
    },
    formControl: {
        width: "200px"
    },
    formControlWinBook: {
        width: "450px"
    },
    popMargin: {
        margin: "20px 0 20px 0"
    },
    expencestable: {
        width: 500,
        height: 700
    },
    typeSelect: {
        width: "140px",
        marginTop: "19px"
    }
})
const getExpenceArray = (dnaReport, arrayValue) => {
    let expence = []
    if (dnaReport !== null) {
        expence = dnaReport
        if (arrayValue !== null && arrayValue.length > 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < arrayValue.length; x++) {
                    if (arrayValue[x].trsType === "winbook" && arrayValue[x].source == expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }

                }
            }
        }
    }
    return expence
}


const getChipLabel = (option) => {
    if (option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}


export default function AlertDialog(props) {
    const classes = useStyles()
    const arrayList = getExpenceArray(props.dnaReport, props.viewProps.contProps.dnaReducer.winbook_data_array)

    return (
        <div>
            <Dialog
                aria-labelledby="form-dialog-title"
                open={props.viewProps.contProps.dnaReducer.winbook_data.show}
                onClose={() => props.viewProps.contProps.handleWinBookData({ id: 0, show: false, trsType: "", description: "", bold: 1, source: null, vehicle: null, value: 0 })}
            >
                <DialogTitle id="alert-dialog-title" className="reportPopHeading">Add Data</DialogTitle>
                <DialogContent style={{ height: 400, width: 1100 }} >
                    <DialogContentText id="alert-dialog-description">
                        <table className="expences-table">
                            <tr>
                                <th>Type</th>
                                <th>{props.viewProps.contProps.dnaReducer.winbook_data.trsType === "voiture" ? "Voiture" : "Description"}</th>
                                <th>{props.viewProps.contProps.dnaReducer.winbook_data.trsType === "voiture" ? "Column" : "Account / Source"}</th>
                                <th style={{ minWidth: 90 }}>% DNA</th>
                                <th>Bold</th>
                                <th />
                            </tr>

                            {props.viewProps.contProps.dnaReducer.winbook_data_array.length !== 0 ? props.viewProps.contProps.dnaReducer.winbook_data_array.map((data, eIndex) => (
                                <WinBookAddRow
                                    popProps={props}
                                    reduc={props.viewProps.contProps.dnaReducer}
                                    key={data}
                                    data={data}
                                    eIndex={eIndex}
                                    sectionAllData={props.sectionAllData}
                                    dnaReport={props.dnaReport}
                                    vehicles={props.viewProps.contProps.dnaReducer.vehicles}
                                    vehicleChange={props.viewProps.popVehChange}
                                    columns={props.viewProps.contProps.dnaReducer.rowVehCol}
                                    cloumnChange={props.viewProps.handleRowColumn}
                                />
                            )) : ""}
                            <tr>
                                
                                <td>
                                    <FormControl className={classes.typeSelect}>
                                        <SelectType
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="trsType"
                                            className="type_select"
                                            value={props.viewProps.contProps.dnaReducer.winbook_data.trsType}
                                            onChange={(e) => props.viewProps.contProps.handleWinBookDataEvent("trsType", e.target.value)}
                                        >
                                            <MenuItem value={"winbook"} >Winbook Data</MenuItem>
                                            <MenuItem value={"formula"} >Formula</MenuItem>
                                            <MenuItem value={"manual"} >Manual Figure</MenuItem>
                                            <MenuItem value={"voiture"} >Voiture</MenuItem>
                                        </SelectType>
                                    </FormControl>
                                </td>
                                <td>
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "voiture" ?
                                        <FormControl className={classes.typeSelect}>
                                            <SelectType
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="number_plate"
                                                name="vehicle"
                                                value={props.viewProps.contProps.dnaReducer.winbook_data.vehicle}
                                                onChange={(e) => props.viewProps.vehicleChange(e.target.value)}
                                            >
                                                {props.viewProps.contProps.dnaReducer.vehicles.map((veh)=>(
                                                    <MenuItem value={veh.id} >{veh.licence_plate}</MenuItem>
                                                ))}
                                            </SelectType>
                                        </FormControl>:
                                        <TextField
                                            id="standard-password-input"
                                            onChange={(e) => props.viewProps.contProps.handleWinBookDataEvent("description", e.target.value)}
                                            className="textFieldSec"
                                            name="description"
                                            value={props.viewProps.contProps.dnaReducer.winbook_data.description}
                                            disabled={props.viewProps.contProps.dnaReducer.winbook_data.trsType === "winbook"}
                                        />}
                                </td>
                                <td>
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "winbook" ?
                                        <Select
                                            inputId="react-select-multiple"
                                            options={arrayList}
                                            getOptionLabel={option => getChipLabel(option)}
                                            getOptionValue={option => `${option.NUMBER}`}
                                            onChange={(e) => props.viewProps.contProps.addWinbookStore(e)}
                                            value={props.viewProps.contProps.dnaReducer.winbookStore}
                                            isMulti={true}
                                        /> : ""
                                    }
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "formula" ?
                                        <TextField
                                            id="standard-password-input"
                                            onChange={(e) => props.viewProps.contProps.handleWinBookDataEvent("source", e.target.value)}
                                            className="textFieldSec"
                                            name="source"
                                            value={props.viewProps.contProps.dnaReducer.winbook_data.source}
                                        /> : ""}
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "manual" ?
                                        <TextField
                                            id="standard-password-input"
                                            onChange={(e) => props.viewProps.contProps.handleWinBookDataEvent("value", e.target.value)}
                                            className="textFieldSec"
                                            name="value"
                                            value={props.viewProps.contProps.dnaReducer.winbook_data.value}
                                        /> : ""}
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "voiture" ?
                                        <FormControl className={classes.typeSelect}>
                                            <SelectType
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="source"
                                                className="column_select"
                                                value={props.viewProps.contProps.dnaReducer.winbook_data.source}
                                                onChange={(e) => props.viewProps.handleColumn(e.target.value)}
                                            >
                                                {props.viewProps.contProps.dnaReducer.vehCol.map((veh)=>(
                                                    <MenuItem value={veh.id} >{veh.label}</MenuItem>
                                                ))}
                                            </SelectType>
                                        </FormControl> : ""}
                                </td>

                                <td className={props.viewProps.contProps.dnaReducer.winbook_data.trsType === "voiture" ? "marge_top" : null}>
                                    <TextField
                                        id="standard-password-input"
                                        placeholder=" % DNA"
                                        onChange={(e) => props.viewProps.contProps.handleWinBookDataEvent("dna", e.target.value)}
                                        value={props.viewProps.contProps.dnaReducer.winbook_data.dna}
                                    />
                                </td>
                                <td>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={props.viewProps.contProps.dnaReducer.winbook_data.bold === 1}
                                                onChange={() => props.viewProps.contProps.handleWinBookDataEvent("bold", props.viewProps.contProps.dnaReducer.winbook_data.bold === 1 ? 0 : 1)}
                                                name="bold"
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td>
                                    {props.viewProps.contProps.dnaReducer.winbook_data.trsType === "winbook" ?
                                        <Button color="primary" onClick={props.viewProps.addwinBookArraySet} > Add </Button> :
                                        <Button color="primary" onClick={() => props.viewProps.contProps.AddWinbookDataArray(props.viewProps.contProps.dnaReducer.winbook_data)} > Add </Button>}
                                </td>
                            </tr>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.viewProps.contProps.clearDnaAccounts} color="primary">
                        cancel
                    </Button>
                    <Button onClick={props.viewProps.createDnaAcoounts} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
