import React, { Fragment } from "react"
import PropTypes from "prop-types"

import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Loader from "../../Layouts/Common/Loader"
import TextField from "@mui/material/TextField"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import DataArray from "./Dialogs/dataArray"
import GroupNmeDlg from "./Dialogs/groupNmeDlg"
import Arrange from "./arrangeAcc"

import DeleteConfirm from "../../Common/delete"
import "./styles.css"

import UpdateData from "./Dialogs/updateData"

// const styles = theme => ({
//     formControl: {
//         margin: theme.spacing(),
//         minWidth: 120
//     },
//     head: {
//         fontSize: "16px",
//         textAlign: "-webkit-center",
//         fontWeight: "700",

//     },
//     prgrp: {
//         width: "fit-content",
//         borderBottom: "1px solid"
//     },
//     root: {
//         marginTop: "30px",
//         marginRight: "30px",
//         float: "right"
//     }

// })

const getTitle = (periods, selectedPeriod, books, selectedBook, list, selectedReportId) => {
    if (selectedPeriod === null || selectedBook === null || selectedReportId === null) {
        return false
    }
    else {
        return list.find((data) => { return data.id === selectedReportId; }).name + "  " + books.find((data) => { return data.value === selectedBook; }).label + " / " + periods.find((data) => { return data.value === selectedPeriod; }).label
    }
}

const View = function (props) {
    const { classes } = props
    const [show, setShow] = React.useState(false)
    const [accArrange, setAccArrange] = React.useState(false)
    const [accExp, setExp] = React.useState(false)
    const [deletePop, setDeletePop] = React.useState(false)
    const [storeId, setStoreId] = React.useState(null)
    const [storeDelPlace, setStoreDelPlace] = React.useState("")

    function arrangeAcchandle() {
        if (props.contProps.section.accArrangeData.length !== 0) {
            props.contProps.saveArrangeAcc(props.contProps.section.accArrangeData, props.contProps.selectedReportId, props.contProps.selectedBook.value, props.contProps.selectedPeriod.value)
        }
        setAccArrange(!accArrange)
    }
    function arrangeExphandle() {
        if (props.contProps.section.expArrangeData.length !== 0) {
            props.contProps.saveArrangeExp(props.contProps.section.expArrangeData, props.contProps.selectedReportId, props.contProps.selectedBook.value, props.contProps.selectedPeriod.value)
        }
        setExp(!accExp)
    }

    function handleMainAccEdit(data) {
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
            props.handleTransPopUpdate(data)
        }
        else {
            props.handleTransPopUpdate(data)
        }
    }
    function handleSubAccEdit(data) {
        props.handleTransPopUpdate(data)
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
        }
    }
    function handleSecExpEdit(data) {
        props.handleExpenseEdit(true, data, { id: 0, description: "", account: [], private_percentage: "", tax_percentage: "" })
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
        }
    }
    function handleExpEdit(expense, data) {
        props.handleExpenseEdit(true, expense, data)
        if (expense.trsType === "winbook") {
            props.handleEditAraay(expense.source)
        }
    }

    const deleteShowTrue = (data, title) => {
        setDeletePop(true)
        setStoreDelPlace(title)
        setStoreId(data)
    }

    const confirmDelete = (confirm) => {
        if (confirm === true && storeDelPlace === "Section") {
            props.contProps.handleDeleteRepo(storeId.selectedReport, storeId.id)
        }
        
        if (confirm === true && storeDelPlace === "childData") {
            props.deleteData(storeId.index, storeId.addIndex, storeId.children, storeId.name)
        }
        setDeletePop(false)
        setStoreDelPlace("")
        setStoreId(null)
    }

    return (
        <Fragment>
            
            <Loader />

            <div className="compteTable">
               {props.contProps.reducerSalaires.salryArray !== null && props.contProps.reducerSalaires.salryArray.description !== undefined ? <table >
                    <tr>
                        <th>last update :</th>
                        <td>{props.contProps.reducerSalaires.salryArray.description}</td>
                    </tr>
                </table>: ""}
            </div>

            

            <div className="container" id="sal-iphone" >

                <div className="ajou_salary">
                    <Typography component="h3" variant="subheading" gutterBottom>
                        <Button size="small" id="sal-btn" variant="text" color="primary" onClick={() => props.contProps.createGroup(props.templateid,props.aid)} >
                            Ajouter Groupe
                            </Button>
                    </Typography>
                </div>
                <table size="small" aria-label="a dense table" className="table-salries" >
                    <tbody>
                        {props.contProps.reducerSalaires.salryArray !== null && props.contProps.reducerSalaires.salryArray.data.length !== 0 ? props.contProps.reducerSalaires.salryArray.data.map((data, index) => (
                            <Fragment>
                                <tr className="group background">
                                    <th className="revenue-section-head-description" align="left">
                                        {data.description}
                                        <div className="tr-actions">
                                            <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.handleGroup({ show: true, id: data.id, parentId: 0, trsType: data.trsType, description: data.description })} > <EditIcon fontSize="small" /> </IconButton>
                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue({selectedReport:props.contProps.reducerMvrList.selectedReport, id:data.id},"Section")} > <DeleteIcon fontSize="small" /> </IconButton>
                                        </div>
                                    </th>
                                    <th className="credit-column"></th>
                                    <th className="debit-column"></th>
                                </tr>


                                <tr className="group add_group">
                                    <th className="revenue-section-head-description border-top" align="left">
                                        {data.addGroup.description}
                                        <div className="tr-actions">
                                            <IconButton size="small" aria-label="Add" onClick={() => props.contProps.handleSetDataPopUp({ show: true, index, trsType: "add", list: [], list_obj: { id: 0, parentId: data.id, bold: 0, trsType: "winbook", description: "", source: null, value: 0 } })}  > <AddIcon fontSize="small" /> </IconButton>
                                            <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.handleGroup({ show: true, id: data.addGroup.id, parentId: data.id, trsType: data.addGroup.trsType, description: data.addGroup.description })} > <EditIcon fontSize="small" /> </IconButton>
                                        </div>
                                    </th>
                                    <th className="credit-column border-top"></th>
                                    <th className="debit-column border-top">{data.addGroup.totalFormated}</th>
                                </tr>
                                {data.addGroup.children.length !== 0 ? data.addGroup.children.map((add, addIndex) => (
                                    <tr className={"group add_group add-children " + (add.bold === 1 ? "font-weight" : "")}>
                                        <td className="revenue-section-head-description" align="left">
                                            {/* change */}
                                            {add.trsType==="winbook"? 
                                                                    <Fragment>
                                                                        <span className="sal-account-number">{add.source}</span>
                                                                        <span className="sal-account-description">{add.description}</span>
                                                                    </Fragment>
                                                                     :<span className="sal-account-description">{add.description}</span>}
                                            {/* {add.trsType === "winbook"? add.source + ": " + add.description :add.description} */}
                                            <div className="tr-actions">
                                                <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.showUpdatePopUp(add, addIndex, "add", index)} > <EditIcon fontSize="small" /> </IconButton>
                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue({ index, addIndex, children: data.addGroup.children, name: "add" },"childData")} > <DeleteIcon fontSize="small" /> </IconButton>
                                            </div>
                                        </td>
                                        <td className="credit-column">{add.accountAmountFormatted}</td>
                                        <td className="debit-column"></td>
                                    </tr>
                                )) : ""}

                                <tr className="group deduct_group">
                                    <th className="revenue-section-head-description border-top" align="left">
                                        {data.deductGroup.description}
                                        <div className="tr-actions">
                                            <IconButton size="small" aria-label="Add" onClick={() => props.contProps.handleSetDataPopUp({ show: true, index, trsType: "deduct", list: [], list_obj: { id: 0, parentId: data.id, bold: 0, trsType: "winbook", description: "", source: null, value: 0 } })}  > <AddIcon fontSize="small" /> </IconButton>
                                            <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.handleGroup({ show: true, id: data.deductGroup.id, parentId: data.id, trsType: data.deductGroup.trsType, description: data.deductGroup.description })} > <EditIcon fontSize="small" /> </IconButton>
                                        </div>
                                    </th>
                                    <th className="credit-column border-top"></th>
                                    <th className="debit-column border-top">{data.deductGroup.totalFormated}</th>
                                </tr>
                                {data.deductGroup.children.length !== 0 ? data.deductGroup.children.map((deduct, dedIndex) => (
                                    <tr className={"group add_group deduct-children " + (deduct.bold === 1 ? "font-weight" : "")}>
                                        <td className="revenue-section-head-description" align="left">
                                            {/* Change */}
                                            {deduct.trsType==="winbook"? 
                                                                    <Fragment>
                                                                        <span className="sal-account-number">{deduct.source}</span>
                                                                        <span className="sal-account-description">{deduct.description}</span>
                                                                    </Fragment>
                                                                     :<span className="sal-account-description">{deduct.description}</span>}
                                            {/* {deduct.trsType === "winbook"? deduct.source + ": " + deduct.description :deduct.description} */}
                                            <div className="tr-actions">
                                                <IconButton size="small" aria-label="Edit" onClick={() => props.contProps.showUpdatePopUp(deduct, dedIndex, "deduct", index)} > <EditIcon fontSize="small" /> </IconButton>
                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue({ index, addIndex:dedIndex, children: data.deductGroup.children, name: "deduct" },"childData")} > <DeleteIcon fontSize="small" /> </IconButton>
                                            </div>
                                        </td>
                                        <td className="credit-column">{deduct.accountAmountFormatted}</td>
                                        <td className="debit-column"></td>
                                    </tr>
                                )) : ""}

                                <tr className="group difference">
                                    <th className="revenue-section-head-description border-top" align="left">
                                        Difference
                                       </th>
                                    <th className="credit-column border-top"></th>
                                    <th className="debit-column border-top"> {data.differenceFormatted !== "0,00" ? data.differenceFormatted : ""} </th>
                                </tr>
                                <tr >
                                    <th className="padding-bottom"></th>
                                </tr>
                            </Fragment>
                        )) : ""}
                    </tbody>
                </table>
            </div>

            <GroupNmeDlg
                viewProps={props}
            />

            <DataArray
                viewProps={props}
            />

            <UpdateData
                viewProps={props}
            />
            <DeleteConfirm
                open={deletePop}
                title={"ligne"}
                confirmDelete={confirmDelete}
            />

        </Fragment>)
}

export default (View)
