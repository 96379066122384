export default function  reducer(state={
    list:[],
    categories:[],
    accounts:[],
    activeReport:{
                        id:0,
                        name:"",
                        categories:[
                            {
                            category:{
                                id:0,
                                name:"New Category",
                                code:"",
                            },                              
                            accounts:[]
                            },                            
                        ],
                },
    dataLoaded: false,
    activeReportWithData:{
        id:0,
        name:"",
        company:"",
        grand_total_formatted:'',
        taxed_grand_total_formatted:'',
        categories:[
            {
            category:{
                id:0,
                name:"New Category",
                code:"",
            },                              
            accounts:[]
            },                            
        ],
},
    dataLoadedView: false,           

},action){
    switch(action.type){
        case "LOADING_START":{
            return{...state,loading:true}
        }
        case "LOADING_STOP":{
            return{...state,loading:false}
        }
        case "STORE_DNA_CATEGORIES":{
            return{...state,categories:action.categories}
        }
        case "STORE_DNA_ACCOUNTS":{
            return{...state,accounts:action.accounts}
        }
        case "STORE_REPORTS":{
            return{...state,list:action.reports}
        }
        case "STORE_ACTIVE_REPORT":{
            return{...state,activeReport:action.report,dataLoaded:true}
        }
        case "LOCK_ACTIVE_REPORT":{
            return{...state,dataLoaded:false}
        }
        case "STORE_ACTIVE_REPORT_VIEW":{
            return{...state,activeReportWithData:action.report,dataLoadedView:true}
        }
        case "LOCK_ACTIVE_REPORT_VIEW":{
            return{...state,dataLoadedView:false}
        }
        case "SHOW_SETTINGS":{
            return{...state,showReportSettings:action.report}
        }
        case "LOADED_SETTINGS":{
            return{...state,reportSettings:action.settings}
        }
        case "UPDATE_REPORT_BASIC":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            [action.field]:action.value                        
                        }
                            
                   }
        }
        case "ADD_COLUMN":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:[
                                ...state.reportSettings.columns,
                                action.column
                            ]                        
                        }
                            
                   }
        }
        case "UPDATE_COLUMN":{
            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:Object.values({
                                ...state.reportSettings.columns,
                                [action.index]:{
                                    ...state.reportSettings.columns[action.index],
                                    [action.field]:action.value  
                                }
                            })                        
                        }
                            
                   }
        }
        
        case "MOVE_COLUMN":{

            const colList = state.reportSettings.columns;
            const [removed] = colList.splice(action.start, 1);
            colList.splice(action.end, 0, removed);

            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            columns:colList                        
                        }
                            
                   }
        }
        case "UPDATE_DETAILS":{

            const colList = state.reportSettings.columns;
            const [removed] = colList.splice(action.start, 1);
            colList.splice(action.end, 0, removed);

            return{...state,
                        reportSettings:{
                            ...state.reportSettings,
                            detail:action.details                        
                        }
                            
                   }
        }
        case "LOADED_DATA":{
            return{...state,reportData:action.data}
        }
        case "SET_BOOK":{
            return{...state,selectedBook:action.book,selectedFromPeriod:action.from,selectedToPeriod:action.to}
        }
        case "REMOVE_COLUMN":{

            return{...state,
                reportSettings:{
                    ...state.reportSettings,
                    columns:[
                        ...state.reportSettings.columns.slice(0,action.column),
                        ...state.reportSettings.columns.slice(action.column+1)
                        
                    ]                        
                }
                    
           }
            return{...state,selectedBook:action.book,selectedFromPeriod:action.from,selectedToPeriod:action.to}
        }

        
        

    }
    return state;
}