export default function  reducer(state={
    list:[],
    loading:false,
    createLoading:false,
    showReportSettings:null,
    reportSettings:null,
    reportData:null,
    selectedBook:null,
    selectedFromPeriod:null,
    selectedToPeriod:null,
    selectedBookCompare:null,
    selectedFromPeriodCompare:null,
    selectedToPeriodCompare:null,
    pages:[],
    mainGraphData:{
        datasets:[]
    },
    cfGraphData:{
        datasets:[]
    },
    paramChange:false,
    widgets:[],
    showWidgetSettings:false,
    companies: [],
    exportData:[]
},action){
    switch(action.type){
        case "LOADING_START":{
            return{...state,loading:true}
        }
        case "LOADING_STOP":{
            return{...state,loading:false}
        }
        case "SET_BOOK_DASHBOARD":{
            return{...state,selectedBook:action.book,selectedFromPeriod:action.from,selectedToPeriod:action.to,paramChange:true}
        }
        case "SET_COMPARE_BOOK_DASHBOARD":{
            return{...state,selectedBookCompare:action.book,selectedFromPeriodCompare:action.from,selectedToPeriodCompare:action.to,paramChange:true}
        }
        case "STORE_GRAPH_DATA":{
            return{...state,paramChange:false,mainGraphData:action.data}
        }
        case "LOCK_PARAM_CHANGE":{
            return{...state,paramChange:false}
        }
        case "STORE_WIDGETS":{
            return{...state,widgets:action.widgets}
        }
        case "STORE_CF_GRAPH_DATA":{
            return{...state,paramChange:false,cfGraphData:action.data}
        }
        case "STORE_PAGES":{
            return{...state,pages:action.pages}
        }
        case "STORE_DASHBOARD_COMPANIES":{
            return{...state,companies:action.companies}
        }
        case "STORE_EXPORT_DASH_DATA":{
            return{...state,exportData:action.data}
        }
        case "UPDATE_CHART_IMAGE":{
            return{...state,
                exportData:Object.values({
                    ...state.exportData,
                    [action.index]:{
                        ...state.exportData[action.index],
                        image:action.data
                    }
                })
            }
        }
        case "UPDATE_PIE_CHART_IMAGE":{
            return{...state,
                exportData:Object.values({
                    ...state.exportData,
                    [action.index]:{
                        ...state.exportData[action.index],
                        graph:{
                            ...state.exportData[action.index].graph,
                            [action.section]:{
                                ...state.exportData[action.index].graph[action.section],
                                image:action.data
                            }
                        }
                    }
                })
            }
        }





    }
    return state;
}
