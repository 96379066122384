import React from "react"
// import { withStyles } from "@mui/styles"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"


const useStyles = (theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    }
})

function DeleteConfirm(props) {
    const { classes } = props

    return (
        <Dialog onClose={()=>props.confirmDelete(false)} aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle id="simple-dialog-title" className="dialogTitle" >êtes vous certain de vouloir supprimer cette {props.title}?</DialogTitle>
            <div id="dialogDetails-text" className="dialogDetails-Button">
                <Button id="deleteButton" variant="outlined" color="primary" onClick={()=>props.confirmDelete(true)} >
                Effacer
                </Button>
                <Button variant="outlined" color="primary" onClick={()=>props.confirmDelete(false)}>
                Annuler
                </Button>
            </div>
        </Dialog>
    )
}
export default DeleteConfirm
