import { Alert, Dialog, DialogContent, Grid, Snackbar } from "@mui/material";

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import React, { useEffect, useState } from "react";
import Model1 from "./model-1";
import '../vf.css';

import SidePanel from "./side-panel";
import AccountHeaderBar from "./account-header-bar";
import AccountDetails from "./account-details";
import { API_URL } from "../config";
import AccountActions from "./account-actions";
import Model2 from "./model-2";
import Model3 from "./model-3";
import Model4 from "./model-4";
import Model5 from "./model-5";
import Model6 from "./model-6";
import Model7 from "./model-7";
import {SettingsView} from "./annexes/Dna"
import {LoyersView} from "./annexes/Loyers"
import { SalairesView } from "./annexes/Salaires";
import AnnexHeaderBar from "./annex-header-bar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalAnnex(props) {

  console.log(props);
  const [data, setMainData] = useState(null);
  const [modelData, setModelData] = useState([]);
  const [modelSubData, setModelSubData] = useState([]);
  const [totals, setTotals] = useState({
    model_1_total: 0
  });
  const [checklist, setChecklist] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null)

  function loadMainData(accountId) {
    setIsLoading(true)
    
    fetch(API_URL + "/audit/account?id=" + accountId+"&audit_id="+props.auditId)
      .then((response) => response.json())
      .then((response) => {
        setMainData(response);
        loadModelData(response)
        loadAccountChecklist(props.auditId, response.account_number)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }
  function loadAccountChecklist(auditId, account) {
    setIsLoading(true)

    fetch(API_URL + "/audit/checklist?audit_id=" + props.auditId + "&account=" + account)
      .then((response) => response.json())
      .then((response) => {
        setChecklist(response);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function toggleStatus(itemId) {

    setIsLoading(true)

    fetch(API_URL + "/audit/checklist-status?id=" + itemId+"&audit_id="+props.auditId)
      .then((response) => response.json())
      .then((response) => {
        loadAccountChecklist(props.auditId, data.account_number)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function loadModelData(account) {

    setIsLoading(true)

    fetch(API_URL + "/audit/model-data?audit_id=" + props.auditId + "&audit_row_id=" + account.id + "&account_number=" + account.account_number + "&model=" + account.template)
      .then((response) => response.json())
      .then((response) => {

        if (account.template === 1) {
          setModelData(response);
        }

        

        if (account.template === 2) {
          setModelData(response.main);
          setModelSubData(response.sub);
        }

        if (account.template === 3) {
         
          setModelData(response);
        }

        if (account.template === 4) {
          setModelData(response);
        }

        if (account.template === 5) {
          setModelData(response);
        }

        if (account.template === 6) {
          setModelData(response);
        }

        if (account.template === 7) {
          setModelData(response);
        }



        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function saveModelData() {
    var saveData = null;
    if (data.template === 1) {
      saveData = { rows: modelData }
    }

    if (data.template === 2) {
      saveData = { rows: modelData, subData: modelSubData }
    }

    if (data.template === 3) {
      saveData = modelData
    }

    if (data.template === 4) {
      saveData = { rows: modelData }
    }

    if (data.template === 5) {
      saveData = { rows: modelData }
    }

    if (data.template === 6) {
      saveData = { rows: modelData }
    }

    if (data.template === 7) {
      saveData = { rows: modelData }
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/model-data?audit_id=" + props.auditId + "&audit_row_id=" + data.id + "&account_number=" + data.account_number + "&model=" + data.template, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(saveData)
    })
      .then((response) => response.json())
      .then((response) => {
        loadModelData(data);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }



  function change_fields_model3(index, fieldName, fieldValue) {
    //const updateRows = [...modelData];

    var updateRows = {
      ...modelData,
      [fieldName]: fieldValue
    }

    setModelData(updateRows);


  }

  function change_fields(index, fieldName, fieldValue) {
    const updateRows = [...modelData];

    updateRows[index] = {
      ...updateRows[index],
      [fieldName]: fieldValue
    }

    setModelData(updateRows);


  }

  function change_sub_fields(index, fieldName, fieldValue) {
    const updateRows = [...modelSubData];

    updateRows[index] = {
      ...updateRows[index],
      [fieldName]: fieldValue
    }

    setModelSubData(updateRows);


  }

  function change_sub_fields(index, fieldName, fieldValue) {
    const updateRows = [...modelSubData];

    updateRows[index] = {
      ...updateRows[index],
      [fieldName]: fieldValue
    }

    setModelSubData(updateRows);


  }


  function add_main_row() {


    if (data.template === 1) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        number: "",
        tr_date: "",
        tr_partner: "",
        tr_ref: "",
        tr_account: "",
        tr_amount: "0,00"
      }]);
    }

    if (data.template === 2) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_date: "",
        tr_amount: "0,00",
        tr_number: modelData.length,
        tr_origin: 0,
        tr_num_actions: 0,
        tr_comments: "",
        tr_op_total: "0,00",
        tr_balance: "0,00"
      }]);
    }

    if (data.template === 4) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_number: "",
        tr_date: "",
        tr_partner: "",
        tr_entreprise: "",
        tr_comments: "",
        tr_shares: 0,
        tr_share_total: 0,
        tr_amount: "0,00",
      }]);
    }

    if (data.template === 5) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_number: "",
        tr_date: "",
        tr_partner: "",        
        tr_comments: "",        
        tr_amount: "0,00",
      }]);
    }

    if (data.template === 6) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_order_num: "",
        tr_type: 0,
        tr_debtor: "",        
        tr_motif: "",        
        tr_debts_init_total: "0,00",
        tr_modification: "0,00",
        tr_reduction_init_total: "0,00",
        tr_augmentation: "0,00",
      }]);
    }

    if (data.template === 7) {
      setModelData([...modelData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_order_num: "",
        tr_type: 0,
        tr_nature: 0,                        
        tr_end_cost: "0,00",
        tr_provision: "0,00",
        tr_justification: ""        
      }]);
    }



  }

  function add_sub_row() {

    if (data.template === 2) {
      setModelSubData([...modelSubData, {
        audit_id: "",
        audit_row_id: "",
        account: "",
        tr_date: "",
        tr_amount: "0,00",
        tr_number: 0,
        tr_origin: 0,
        tr_num_actions: 0,
        tr_comments: "",
        tr_wh_tax: "0,00",
        tr_wh_not_tax: "0,00",
        tx_fix_assets: "0,00",
        tx_wh_tax_total: "0,00"
      }]);
    }



  }

  function remove_main_row(index) {
    const newItems = [...modelData];
    newItems.splice(index, 1);
    setModelData(newItems);

  }

  function remove_sub_row(index) {
    const newItems = [...modelSubData];
    newItems.splice(index, 1);
    setModelSubData(newItems);

  }

  if (props.open === true && data === null) {
    console.log(props.model);
    if (isLoading === false) {
      //loadMainData(props.model.accountNumber)
    }
    //loadMainData(props.model.id);
  }

  function closeView() {
    setMainData(null)
    props.handleClose()
  }

  function nextAccount() {
    if (data.next_account !== false) {
      loadMainData(data.next_account)
    }
  }

  function prevAccount() {
    if (data.previous_account !== false) {
      loadMainData(data.previous_account)
    }
  }

  return <Dialog
    fullScreen
    open={props.open}
    onClose={closeView}
    PaperProps={{
      sx: {
        backgroundColor: 'rgb(252 252 252)', // Set dialog paper color here
      },
    }}



  >
    <AppBar className="template-app-bar" sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>





      </Toolbar>
    </AppBar>

    <Typography id="modal-modal-title" variant="h6" component="h2">

    </Typography>
    <DialogContent>

      <Grid container spacing={3} >

        <Grid item xs={12} md={9}>
          <AnnexHeaderBar model={props.model} /> 
           {props.model.annex_type === "annex-dna" ? <SettingsView aid={props.auditId} model={props.model} /> : null}
           {props.model.annex_type === "annex-loyers" ? <LoyersView aid={props.auditId} model={props.model} /> : null}
           {props.model.annex_type === "annex-salaries" ? <SalairesView aid={props.auditId} model={props.model} /> : null}
            
        </Grid>
        <Grid item xs={12} md={3}>
          <SidePanel data={checklist} toggleStatus={toggleStatus} />
        </Grid>
      </Grid>





      <Snackbar open={isLoading} autoHideDuration={6000} >
        <Alert

          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Loading...
        </Alert>
      </Snackbar>
    </DialogContent>
  </Dialog>;
}

export default ModalAnnex