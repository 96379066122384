
import { FileUpload } from "@mui/icons-material";
import { Alert, Button, Grid, Paper, Snackbar, Menu,MenuItem,Divider, ListItemText, ListItemIcon } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../config";


function dot(row){
  return <div class={row === 1 ? "green-dot": "grey-dot"}></div>;
}


function AccountActions(props) {

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleFileChange = (event) => {
    
    const selectedFile = event.target.files[0];
    
    
    handleSubmit(selectedFile)
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const importAccounts = async ()=>{
    fetch(API_URL+"/audit/import?audit_id="+props.data.audit_id+"&audit_row_id="+props.data.id+"&account_number="+props.data.account_number+"&template="+props.data.template)
    .then((response) => response.json())
    .then((response) => {   
      props.loadMainData(props.data.account_number)   
      setIsLoading(false)
    })
    .catch((error) => {
      setIsLoading(false)
      console.error('Error fetching data:', error);
      // Display a user-friendly error message
      setError(error)
    });
  }

  const handleSubmit = async (file) => {
    console.log(props.data)
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        // Send the file to the server using Axios
        const response = await axios.post(API_URL+"/audit/import?audit_id="+props.data.audit_id+"&audit_row_id="+props.data.id+"&account_number="+props.data.account_number+"&template="+props.data.template, formData, {
          headers: {                       
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log('File uploaded:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      alert("Please select a file.");
    }
  };

  const handleOpenFileInput = () => {
    document.getElementById('fileInput').click();
  };

  function Dot(props){
    return <div class={props.status ? "green-dot": "grey-dot"}></div>;
 }
  
  return <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={2} alignItems="center">
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
          <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
        />              
              <Button autoFocus color="inherit" onClick={handleClick} endIcon={<FileUpload/>} >
              Réconciliation
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
              <MenuItem onClick={() => importAccounts()} >
                <ListItemIcon>
                  <Dot status={ props.data ? props.data.imports.account_history : false} />
                </ListItemIcon>
                <ListItemText>Recopier les données de la comptabilité</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => importAccounts()} >
                <ListItemIcon>
                  <Dot status={props.data ? props.data.imports.prv_year : false} />
                </ListItemIcon>
                <ListItemText>Reprendre la réconciliation de la clôture précédente</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => importAccounts()} >
                <ListItemIcon>
                  <Dot status={props.data ? props.data.imports.prv_period : false} />
                </ListItemIcon>
                <ListItemText>Reprendre la réconciliation de la dernière période justifiée</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => {}} >
                <ListItemIcon>
                  
                </ListItemIcon>
                <ListItemText>Importer la réconciliation</ListItemText>
              </MenuItem>                             
                <Divider />
                <MenuItem onClick={handleClose} >Cancel</MenuItem>
              </Menu>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          { props.data ? <Button autoFocus color="inherit" endIcon={dot(props.data.matched)} >
                expliqué
          </Button>:null }
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>


    <Snackbar open={isLoading} autoHideDuration={6000} >
        <Alert

          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Loading...
        </Alert>
      </Snackbar>

  </Paper>;
}

export default AccountActions