import React, { Fragment } from "react"
import Switch from '@mui/material/Switch'
// import { withStyles } from "@mui/material/styles"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import SubAppBar from "../common/SubAppBar"
import ReportHeader from "../../../Common/ReportHeader"
import Loader from "../../Layouts/Common/Loader"
import TextField from "./TextField"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import AcoountSectionPopup from "./Dialogs/acoountSectionPopup"
import NewSectionDialog from "./Dialogs/dialog-new-section"
import ExpenseData from "./Dialogs/dialog-expense-data"
import ExpenseEditData from "./Dialogs/editExpense"
import EditAcoountDataRow from "./Dialogs/editRevDataRow"
import DialogGroupData from "./Dialogs/dialog-group-data"
import DialogExpenseNew from "./Dialogs/dialog-expense-new"
import AccountSubHead from "./Dialogs/revenSubGrp"
import GrpDropDown from "./Dialogs/grpDropDown"
import Arrange from "./arrangeAcc"
import ExpArrange from "./arrangeExp"
import ImportReports from "./Dialogs/import-report"
import Alert from "@mui/material/Alert"
import DeleteConfirm from "../../Common/delete"
import "./styles.css"

const styles = theme => ({
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    head: {
        fontSize: "20px",
        padding: "40px 0 0 25px",
        float: "left"
    },
    root: {
        marginTop: "30px",
        marginRight: "30px",
        float: "right"
    }
})

const zeroCheck = (valueSet) => {
    if (valueSet.accountAmount === 0 && valueSet.value === 0) {
        return ""
    }
    else {
        return valueSet.accountAmountFormatted
    }
}
const amountPrint = (formet, amount) => {
    if (amount === 0) {
        return " "
    }
    else {
        return formet
    }
}

const getTitle = (periods, selectedPeriod, books, selectedBook, list, selectedReportId) => {
    if (selectedPeriod === null || selectedBook === null || selectedReportId === null) {
        return false
    }
    else {
        return list.find((data) => { return data.id === selectedReportId; }).name + "  " + books.find((data) => { return data.value === selectedBook; }).label + " / " + periods.find((data) => { return data.value === selectedPeriod; }).label
    }
}

const View = function (props) {
    const { classes } = props
    const [show, setShow] = React.useState(false)
    const [accArrange, setAccArrange] = React.useState(false)
    const [accExp, setExp] = React.useState(false)
    const [deletePop, setDeletePop] = React.useState(false)
    const [storeId, setStoreId] = React.useState(null)
    const [storeDelPlace, setStoreDelPlace] = React.useState("")

    function arrangeAcchandle() {
        if (props.contProps.section.accArrangeData.length !== 0) {
            props.contProps.saveArrangeAcc(props.contProps.section.accArrangeData, props.contProps.selectedReportId, props.contProps.selectedBook.value, props.contProps.selectedPeriod.value)
        }
        setAccArrange(!accArrange)
    }
    function arrangeExphandle() {
        if (props.contProps.section.expArrangeData.length !== 0) {
            props.contProps.saveArrangeExp(props.contProps.section.expArrangeData, props.contProps.selectedReportId, props.contProps.selectedBook.value, props.contProps.selectedPeriod.value)
        }
        setExp(!accExp)
    }

    function handleMainAccEdit(data) {
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
            props.handleTransPopUpdate(data)
        }
        else {
            props.handleTransPopUpdate(data)
        }
    }
    function handleSubAccEdit(data) {
        props.handleTransPopUpdate(data)
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
        }
    }
    function handleSecExpEdit(data) {
        props.handleExpenseEdit(true, data, { id: 0, description: "", account: [], private_percentage: "", tax_percentage: "" })
        if (data.trsType === "winbook") {
            props.handleEditAraay(data.source)
        }
    }
    function handleExpEdit(expense, data) {
        props.handleExpenseEdit(true, expense, data)
        if (expense.trsType === "winbook") {
            props.handleEditAraay(expense.source)
        }
    }

    const deleteShowTrue = (data, title) => {
        setDeletePop(true)
        setStoreDelPlace(title)
        setStoreId(data)
    }

    const confirmDelete = (confirm) => {
        if (confirm === true && storeDelPlace === "main") {
            props.handleDeleteRevenGroup(storeId)
        }
        if (confirm === true && storeDelPlace === "expence") {
            props.deleteExpenseGroup(storeId.mvReportId, storeId.id)
        }
        setDeletePop(false)
        setStoreDelPlace("")
        setStoreId(null)
    }
    return (
        <Fragment>
            <ReportHeader 
                annexType="mvr"
                backAction={props.handleGoBack}
                dropDown={true}
                title={getTitle(props.contProps.route.periods, props.contProps.route.selectedPeriod, props.contProps.route.books, props.contProps.route.selectedBook, props.contProps.route.list, props.contProps.reducerMvrList.selectedReport)}
                viewProps={props}
                creatAnnex={props.handleOpen}
                handleRepoSelect={props.handleRepoSelect}
                handleAccountIssue={props.handleAccountIssue}
                />
            <Loader />
            {props.contProps.dnaReport.length !== 0 ? <Alert severity="error">Tous les comptes ne sont pas intégrés dans le rapport</Alert> : ""}

            <h1 className={classes.head}>{props.data !== null ? props.data.name : ""}</h1>
            <div id="hide_div">
                {props.data ?
                    <div className="container" >

                        <div className="accTitle">
                            <Typography component="h3" variant="subheading" gutterBottom>
                                <Button size="small" id="mvr_btn_grp" variant="text" color="primary" onClick={() => props.handleGroup(true, { trsType: "group", id: 0, bold: 1, description: "" })} >
                                    Ajouter Groupe
                                </Button>
                                <Button size="small" variant="text" color="primary" onClick={props.handleAccountsSet}>
                                    Ajouter Comptes
                                </Button>
                                <Button size="small" variant="text" color="primary" onClick={() => props.handleGroup(true, { trsType: "total", id: 0, bold: 0, description: "Résultats" })} >
                                    Ajouter Résultats
                                </Button>
                                <Button size="small" variant="text" color="primary" onClick={() => props.handleGroup(true, { trsType: "expenses", id: 0, bold: 0, description: "Total des dépenses" })} >
                                    Ajouter Total Dépenses
                                </Button>
                                <Button id="mvr_btn_Mod" size="small" variant="text" color="primary" onClick={() => setAccArrange(!accArrange)} >
                                    {accArrange === true ? "Cancel" : "Modifier présentation"}
                                </Button>
                                <Button id="mvr_btn_Sav" size="small" variant="text" color="primary" onClick={() => arrangeAcchandle()} >
                                    {accArrange === true ? "Save" : ""}
                                </Button>
                            </Typography>
                        </div>
                        {accArrange === true ?
                            <Arrange
                                viewData={props}
                                handleSave={() => setAccArrange(!accArrange)}
                            /> :

                            <table size="small" aria-label="a dense table" className="table-revenue" >
                                <tbody>
                                    {props.data !== null ? props.data.revenue.map((data) => {
                                        return <Fragment key={data.id}>
                                            {data.trsType === "group" ?
                                                <Fragment>
                                                    <tr className="group">
                                                        <th className="revenue-section-head-description" align="left">
                                                            {data.description}
                                                            <div className="tr-actions">
                                                                <IconButton size="small" aria-label="Add" onClick={() => setShow(true)} > <AddIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Edit" onClick={() => props.handleGroup(true, { id: data.id, description: data.description, trsType: data.trsType })} > <EditIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Delete" > <DeleteIcon fontSize="small" onClick={() => deleteShowTrue(data.id, "main")} /* onClick={()=>props.handleDeleteRevenGroup(data.id)}*/ /> </IconButton>
                                                            </div>
                                                            <GrpDropDown
                                                                show={show}
                                                                handleClose={() => setShow(false)}
                                                                subAccount={() => props.handleSectionPopShow(data)}
                                                                subPop={() => props.handleShowNewSectionDialog(true, { parentId: data.id, id: 0, description: "", display_order: 0, trsType: data.trsType })}
                                                            />
                                                        </th>
                                                        <th className="amount-column"></th>
                                                        <th className="credit-column">{data.parentId === 0 ? zeroCheck(data) : ""}</th>
                                                        <th className="debit-column"></th>
                                                    </tr>
                                                    {data.children !== undefined && data.children.length !== 0 ? data.children.map((section, rIndex) => (
                                                        section.trsType === "group" ?
                                                            <Fragment key={section.id}>
                                                                <tr className="group">

                                                                    <th className="revenue-section-head-description revenue-sub-group" align="left">
                                                                        {section.description}
                                                                        <div className="tr-actions">
                                                                            <IconButton size="small" aria-label="Add" onClick={() => props.handleSectionPopShow(section, data)} > <AddIcon fontSize="small" /> </IconButton>
                                                                            <IconButton size="small" aria-label="Edit" onClick={() => props.handleShowNewSectionDialog(true, { parentId: data.id, id: section.id, description: section.description, display_order: 0, trsType: data.trsType })} > <EditIcon fontSize="small" /> </IconButton>
                                                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(section.id, "main")} /*onClick={()=>props.handleDeleteRevenGroup(section.id)}*/> <DeleteIcon fontSize="small" /> </IconButton>
                                                                        </div>
                                                                    </th>
                                                                    <th className="amount-column">{section.parentId !== 0 ? zeroCheck(section) : ""} </th>
                                                                    <th className="credit-column">{section.parentId === 0 ? zeroCheck(section) : ""}</th>
                                                                    <th className="debit-column"></th>
                                                                </tr>

                                                                {section.children.length !== 0 ? section.children.map((trans) => (
                                                                    <tr key={trans.id} className="transaction-row">
                                                                        <td className={"transaction-description " + (trans.bold === 1 ? "bold" : "")}>
                                                                            {trans.description}
                                                                            <div className="tr-actions">
                                                                                <IconButton size="small" className="act-button" aria-label="Edit" onClick={() => handleSubAccEdit(trans)} > <EditIcon fontSize="small" /> </IconButton>
                                                                                <IconButton size="small" className="act-button" aria-label="Delete" onClick={() => deleteShowTrue(trans.id, "main")} /*onClick={()=>props.handleDeleteRevenGroup(trans.id)} */ > <DeleteIcon fontSize="small" /> </IconButton>
                                                                            </div>
                                                                        </td>
                                                                        <td className={"amount-column " + (trans.bold === 1 ? "bold" : "")}>{trans.parentId !== 0 ? zeroCheck(trans) : ""}</td>
                                                                        <td className={"amount-column " + (trans.bold === 1 ? "bold" : "")}>{trans.parentId === 0 ? zeroCheck(trans) : ""}</td>
                                                                        <td className={"amount-column " + (trans.bold === 1 ? "bold" : "")} />
                                                                    </tr>

                                                                )) : ""}
                                                            </Fragment> :
                                                            <Fragment key={section.id}>
                                                                <tr>

                                                                    <td className="revenue-section-head-description revenue-sub-group" align="left" id={section.bold === 1 ? "descriptionSub" : ""}>
                                                                        {section.description}
                                                                        <div className="tr-actions">
                                                                            <IconButton size="small" aria-label="Edit" onClick={() => handleSubAccEdit(section)} > <EditIcon fontSize="small" /> </IconButton>
                                                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(section.id, "main")} > <DeleteIcon fontSize="small" /> </IconButton>
                                                                        </div>
                                                                    </td>
                                                                    <td className="amount-column " id={section.bold === 1 ? "descriptionSub" : ""} >{section.parentId !== 0 ? zeroCheck(section) : ""} </td>
                                                                    <td className="credit-column " id={section.bold === 1 ? "descriptionSub" : ""} >{section.parentId === 0 ? zeroCheck(section) : ""}</td>
                                                                    <td className="debit-column"></td>
                                                                </tr>
                                                            </Fragment>
                                                    )) : ""}
                                                </Fragment> : ""}

                                            {data.trsType === "total" ?
                                                <tr>
                                                    <td className={"revenue-section-head-description " + (data.bold === 1 ? "bold" : "")} align="left">
                                                        {data.description}
                                                        <div className="tr-actions">

                                                            <IconButton size="small" aria-label="Edit" onClick={() => props.handleGroup(true, { id: data.id, description: data.description, trsType: data.trsType, bold: data.bold })} > <EditIcon fontSize="small" /> </IconButton>
                                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data.id, "main")} /* onClick={()=>props.handleDeleteRevenGroup(data.id)}*/ > <DeleteIcon fontSize="small" /> </IconButton>
                                                        </div>
                                                    </td>
                                                    <td className={"amount-column " + (data.bold === 1 ? "bold" : "")} >{data.parentId !== 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"credit-column " + (data.bold === 1 ? "bold" : "")} >{data.parentId === 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"debit-column" + (data.bold === 1 ? "bold" : "")}></td>
                                                </tr>
                                                : ""}
                                            {data.trsType === "winbook" || data.trsType === "formula" || data.trsType === "manual" ?
                                                <tr>
                                                    <td className={"revenue-section-head-description " + (data.bold === 1 ? "bold" : "")} id="description" align="left">
                                                        {data.description !== null ? data.description : "Null Description"}
                                                        <div className="tr-actions">
                                                            {/* <IconButton size="small" aria-label="Edit" onClick={() => props.handleTransPopUpdate(data)}  > <EditIcon fontSize="small" /> </IconButton> */}
                                                            <IconButton size="small" aria-label="Edit" onClick={() => handleMainAccEdit(data)}  > <EditIcon fontSize="small" /> </IconButton>
                                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data.id, "main")} /* onClick={()=>props.handleDeleteRevenGroup(data.id)}*/ > <DeleteIcon fontSize="small" /> </IconButton>
                                                        </div>
                                                    </td>
                                                    <td className={"amount-column " + (data.bold === 1 ? "bold" : "")} id="description">{data.parentId !== 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"credit-column " + (data.bold === 1 ? "bold" : "")} id="description">{data.parentId === 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"debit-column " + (data.bold === 1 ? "bold" : "")}></td>
                                                </tr>
                                                : ""}
                                            {data.trsType === "expenses" ?
                                                <tr>
                                                    <td className={"revenue-section-head-description " + (data.bold === 1 ? "bold" : "")} align="left">
                                                        {data.description !== null ? data.description : "Null Description"}
                                                        <div className="tr-actions">
                                                            <IconButton size="small" aria-label="Edit" onClick={() => props.handleGroup(true, { id: data.id, description: data.description, trsType: data.trsType, bold: data.bold  })}> <EditIcon fontSize="small" /> </IconButton>
                                                            <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data.id, "main")} /* onClick={()=>props.handleDeleteRevenGroup(data.id)}*/ > <DeleteIcon fontSize="small" /> </IconButton>
                                                        </div>
                                                    </td>
                                                    <td className={"amount-column " + (data.bold === 1 ? "bold" : "")} >{data.parentId !== 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"credit-column " + (data.bold === 1 ? "bold" : "")} >{data.parentId === 0 ? zeroCheck(data) : ""}</td>
                                                    <td className={"debit-column " + (data.bold === 1 ? "bold" : "")}></td>
                                                </tr>
                                                : ""}

                                        </Fragment>
                                    }) : ""}

                                </tbody>
                            </table>}



                        <div className="repHead">
                            <Typography component="h3" id="mvr_btn_grp" variant="subheading" gutterBottom>
                                Détail des frais
                                <Button size="small" variant="text" color="primary" onClick={() => props.handleExpenseNew(true, { id: 0, description: "", trsType: "group", parentId: 0, dna: 0, personal: 0, value: 0, bold: 1, })} >
                                    Ajouter Groupe
                                </Button>
                                <Button size="small" variant="text" color="primary" onClick={() => props.contProps.accountSecPopShow(0)} >
                                    Ajouter Comptes
                                </Button>
                                <Button id="mvr_btn_Mod" size="small" variant="text" color="primary" onClick={() => setExp(!accExp)} >
                                    {accExp === true ? "cancel" : "Modifier présentation"}
                                </Button>
                                <Button id="mvr_btn_Sav" size="small" variant="text" color="primary" onClick={() => arrangeExphandle()} >
                                    {accExp === true ? "Save" : ""}
                                </Button>
                            </Typography>
                            <div className="lim-button" onClick={props.handleLimProf}>
                                Limit Prof 
                                    <Switch 
                                        checked={props.data.report_option.hide==1} 
                                        color="primary"
                                    />
                            </div>
                        </div>
                        {accExp === true ?
                            <ExpArrange
                                viewData={props}
                                handleSave={() => setExp(!accExp)}
                            /> :
                            <table size="small" aria-label="a dense table" className="table-expences" >
                                <thead>
                                    <th className="discription">Description</th>
                                    <th className="balance">{props.contProps.route.periods.find((data) => { return data.value === props.contProps.route.selectedPeriod; }).label}/{props.contProps.route.books.find((data) => { return data.value === props.contProps.route.selectedBook; }).label}</th>
                                    {props.data.report_option.hide !==0 ? <th className="professional">Limit Prof<br />(non déd)</th>:null}
                                    <th className="dna">DNA</th>
                                    <th className="total">Limite Totale</th>
                                    <th className="difference">Déductible</th>
                                </thead>
                                <div id="padding" />
                                {props.data !== null ? props.data.expense.map((data, groupIndex) => {

                                    return <Fragment key={data.id}>
                                        {data.trsType === "group" ? <Fragment >
                                            <tr>
                                                <th className="expenseTitle">
                                                    <div className="expenseGroupTitle"> {data.description}  </div>
                                                    <div className="tr-actions">
                                                        <IconButton size="small" aria-label="Add" onClick={() => props.contProps.accountSecPopShow(data.id, data.parentId)} > <AddIcon fontSize="small" /> </IconButton>
                                                        <IconButton size="small" aria-label="Edit" onClick={() => props.handleExpenseNew(true, { id: data.id, description: data.description, trsType: "group", parentId: data.parentId, dna: data.dna, personal: data.personal, value: data.value })}  > <EditIcon fontSize="small" /> </IconButton>
                                                        <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data, "expence")} /*onClick={() => props.deleteExpenseGroup(data.mvReportId, data.id)}  */ > <DeleteIcon fontSize="small" /> </IconButton>
                                                    </div>
                                                </th>
                                                <th style={{ textAlign: 'right' }}>{amountPrint(data.accountAmountFormatted, data.accountAmount)}</th>
                                                {props.data.report_option.hide !==0 ?<th style={{ textAlign: 'center' }}>{amountPrint(data.accountPersonalTotalFormatted, data.accountPersonalTotal)}</th>:null}
                                                <th style={{ textAlign: 'center' }} >
                                                    {/* {amountPrint(data.accountDNATotalFormatted, data.accountDNATotal)} */}
                                                    </th>
                                                <th style={{ textAlign: 'right' }}>{amountPrint(data.accountBalanceAmountFormatted, data.accountBalanceAmount)}</th>
                                                <th style={{ textAlign: 'right' }}>{amountPrint(data.accountDiffAmountFormatted, data.accountDiffAmount)}</th>
                                            </tr>
                                            <tbody >
                                                {data.children !== undefined && data.children.length !== 0 ? data.children.map((expense, eIndex) => (
                                                    <tr key={expense.id} className="transactions">
                                                        <td className={"discription " + (expense.bold === 1 ? "bold" : "")} style={{ paddingLeft: 15 }}>
                                                            {expense.description}
                                                            <div className="tr-actions">
                                                                {/* <IconButton size="small" aria-label="Edit" onClick={() => props.handleExpenseEdit(true, expense, data)} > <EditIcon fontSize="small" /> </IconButton> */}
                                                                <IconButton size="small" aria-label="Edit" onClick={() => handleExpEdit(expense, data)} > <EditIcon fontSize="small" /> </IconButton>
                                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(expense, "expence")} /*onClick={() => props.deleteExpenseGroup(expense.mvReportId, expense.id)} */   > <DeleteIcon fontSize="small" /> </IconButton>
                                                            </div>
                                                        </td>
                                                        <td className={"amount " + (expense.bold === 1 ? "bold" : "")}> {amountPrint(expense.accountAmountFormatted, expense.accountAmount)}</td>
                                                        {props.data.report_option.hide !==0 ? <td className={"professional" + (expense.bold === 1 ? " input-bold" : "")} >
                                                            <TextField
                                                                id="standard-password-input"
                                                                className="textValue"
                                                                name="personal"
                                                                onChange={(e) => props.handleSingleChange(e, expense)}
                                                                value={expense.personal}
                                                                ViewProps={props}
                                                            />
                                                        </td>:null}
                                                        <td className={"dna" + (expense.bold === 1 ? " input-bold" : "")}>
                                                            <TextField
                                                                id="standard-password-input"
                                                                name="dna"
                                                                onChange={(e) => props.handleSingleChange(e, expense)}
                                                                value={expense.dna}
                                                                className="textValue"
                                                                ViewProps={props}
                                                            />
                                                        </td>
                                                        <td className={"total " + (expense.bold === 1 ? "bold" : "")}> {amountPrint(expense.accountBalanceAmountFormatted, expense.accountBalanceAmount)}</td>
                                                        <td className={"difference " + (expense.bold === 1 ? "bold" : "")}>{amountPrint(expense.accountDiffAmountFormatted, expense.accountDiffAmount)}</td>
                                                    </tr>
                                                )) : ""}
                                            </tbody>

                                        </Fragment> : <Fragment >
                                                <tbody >
                                                    <tr className="transactions">
                                                        <td className={"discription " + (data.bold === 1 ? "bold" : "")} >
                                                            {data.description}
                                                            <div className="tr-actions">
                                                                <IconButton size="small" aria-label="Edit"  > <EditIcon fontSize="small" onClick={() => handleSecExpEdit(data)} /> </IconButton>
                                                                <IconButton size="small" aria-label="Delete" onClick={() => deleteShowTrue(data, "expence")} /* onClick={() => props.deleteExpenseGroup(data.mvReportId, data.id)}*/   > <DeleteIcon fontSize="small" /> </IconButton>
                                                            </div>
                                                        </td>
                                                        <td className={"amount " + (data.bold === 1 ? "bold" : "")}> {amountPrint(data.accountAmountFormatted, data.accountAmount)}</td>
                                                        {props.data.report_option.hide !==0 ?  <td className={"professional" + (data.bold === 1 ? " input-bold" : "")}>
                                                            <TextField
                                                                id="standard-password-input"
                                                                className="textValue"
                                                                name="personal"
                                                                onChange={(e) => props.handleSingleChange(e, data)}
                                                                value={data.personal}
                                                                ViewProps={props}
                                                            />
                                                        </td>:null}
                                                        <td className={"dna" + (data.bold === 1 ? " input-bold" : "")}>
                                                            <TextField
                                                                id="standard-password-input"
                                                                name="dna"
                                                                onChange={(e) => props.handleSingleChange(e, data)}
                                                                value={data.dna}
                                                                className="textValue"
                                                                ViewProps={props}
                                                            />
                                                        </td>
                                                        <td className={"total " + (data.bold === 1 ? "bold" : "")}>{amountPrint(data.accountBalanceAmountFormatted, data.accountBalanceAmount)}</td>
                                                        <td className={"difference " + (data.bold === 1 ? "bold" : "")}>{amountPrint(data.accountDiffAmountFormatted, data.accountDiffAmount)}</td>
                                                    </tr>
                                                </tbody>

                                            </Fragment>}

                                    </Fragment>


                                }) : ""}

                                <tfoot>
                                    <tr />
                                    <tr className="transactions" style={{ textAlign: 'right', height: 50 }}>
                                        <th ></th>
                                        <th > {amountPrint(props.data.expenseTotalFormatted, props.data.expenseTotal)}</th>
                                        {props.data.report_option.hide !==0 ? <th style={{ textAlign: 'center' }} >
                                            {amountPrint(props.data.accountPersonalTotalFormatted, props.data.accountPersonalTotal)}
                                        </th>:null}
                                        <th style={{ textAlign: 'center' }} >
                                            {/* {amountPrint(props.data.accountDNATotalFormatted, props.data.accountDNATotal)} */}
                                        </th>
                                        <th >{amountPrint(props.data.accountBalanceTotalFormatted, props.data.accountBalanceTotal)}</th>
                                        <th >{amountPrint(props.data.accountDiffTotalFormatted, props.data.accountDiffTotal)}</th>
                                    </tr>
                                </tfoot>

                            </table>}
                    </div> : null}
            </div>


            <NewSectionDialog
                sectionProps={props}
                handleSectionChanges={props.handleSectionChanges}
                handleSaveSectionHead={props.handleSaveSectionHead}
                sectionText={props.sectionData}
            />
            <AccountSubHead
                sectionProps={props}
            />
            <AcoountSectionPopup
                sectionProps={props}
                handleSectionPopHide={props.handleSectionPopHide}
                sectionAllData={props.sectionAllData}
                handleSectionDataType={props.handleSectionDataType}
                handleChangeData={props.handleChangeData}
                dnaReport={props.dnaReport}
                handleSaveSection={props.handleSaveSection}
                addArrayRow={props.addArrayRow}
                handleChangeRowData={props.handleChangeRowData}
                handleDeleteDataRow={props.handleDeleteDataRow}
                closeNewSectionDialog={props.closeNewSectionDialog}
                handleSwitchUpdate={props.handleSwitchUpdate}
            />
            <ExpenseData
                handleExpenseHide={props.handleExpenseHide}
                sectionAllData={props.sectionAllData}
                handleExpenseUpdate={props.handleExpenseUpdate}
                handleSaveExpense={props.handleSaveExpense}
                expenseDate={props.expenseDate}
                dnaReport={props.dnaReport}
                handleAddExpense={props.handleAddExpense}
                handleAddExpenseTwo={props.handleAddExpenseTwo}
                handleExpenseRowUpdate={props.handleExpenseRowUpdate}
                handleDeleteExpenceRow={props.handleDeleteExpenceRow}
                handleExpenseUpdateArray={props.handleExpenseUpdateArray}
                handleExpenseDesUpdate={props.handleExpenseDesUpdate}
                handleSwitchUpdate={props.contProps.handleSwitchUpdate}
                SelectedExpense={props.data}
                handleChangeExpence={props.handleChangeExpence}
            />
            <ExpenseEditData
                handleExpenseHide={props.handleExpenseHide}
                sectionAllData={props.sectionAllData}
                handleExpenseUpdate={props.handleExpenseUpdate}
                handleSaveExpense={props.handleSaveExpense}
                expenseDate={props.expenseDate}
                dnaReport={props.dnaReport}
                handleAddExpense={props.handleAddExpense}
                handleExpenseRowUpdate={props.handleExpenseRowUpdate}
                handleDeleteExpenceRow={props.handleDeleteExpenceRow}
                handleUpdateExpense={props.handleUpdateExpense}
                clearEcpenceFill={props.clearEcpenceFill}
            />

            <EditAcoountDataRow
                handleSectionPopHide={props.handleSectionPopHide}
                handleChangeRowData={props.handleChangeRowData}
                handleChangeData={props.handleChangeData}
                handleChangeDataSwitch={props.contProps.handleChangeDataSwitch}
                handleSaveSection={props.handleSaveSection}

                sectionAllData={props.sectionAllData}
                handleSectionDataType={props.handleSectionDataType}
                dnaReport={props.dnaReport}
                addArrayRow={props.addArrayRow}
                handleDeleteDataRow={props.handleDeleteDataRow}
                accDataShowHide={props.accDataShowHide}
                handleUpdateRevenueData={props.handleUpdateRevenueData}
            />

            <DialogGroupData
                groupData={props.sectionAllData.groupData}
                handleGroup={props.handleGroup}
                handleText={props.handleText}
                handleGroupTextSave={props.handleGroupTextSave}
                handleSwitchChange={props.handleSwitchChange}
            />
            <DialogExpenseNew
                handleExpenseNew={props.handleExpenseNew}
                expenseData={props.sectionAllData.expenseData}
                handleExpenseText={props.handleExpenseText}
                handleExpenseTextSave={props.handleExpenseTextSave}
            />

            <ImportReports
                open={props.contProps.section.company}
                handleChange={props.handleCompanyChange}
                companies={props.contProps.dashReducer.companies}
                handleClose={props.handleRepoSelect}
                handleImport={props.handleImport}
                selected={props.contProps.section.importCompany}
            />
            <DeleteConfirm
                open={deletePop}
                title={"ligne"}
                confirmDelete={confirmDelete}
            />

        </Fragment>)
}

export default (View)
