import React, { Component } from "react"
import SortableTree from "@nosferatu500/react-sortable-tree"
import BMSTheme from "react-sortable-tree-theme-bms"

import "./styles.css"

const heigth = (treeData) => {
    let heigth = 0
    if (treeData === null) { return 100 }
    else {
        for (let q = 0; q < treeData.revenue.length; q++) {
            heigth = heigth + 1
            if (treeData.revenue[q].children !== undefined) {
                for (let w = 0; w < treeData.revenue[q].children.length; w++) {
                    heigth = heigth + 1
                    if (treeData.revenue[q].children[w].children !== undefined) {
                        for (let e = 0; e < treeData.revenue[q].children[w].children; e++) {
                            heigth = heigth * 15
                        }

                    }

                }
            }
        }
        return heigth * 30
    }
}

const handleChangeTree = (treeData) => {
    let change = true
    for (let x = 0; x < treeData.length; x++) {
        if (treeData[x].children !== undefined) {
            if (treeData[x].trsType !== "group" && treeData[x].children.length > 0) {
                change = false
            }
            for (let y = 0; y < treeData[x].children.length; y++) {
                if (treeData[x].children[y].children !== undefined) {
                    if (treeData[x].children[y].trsType !== "group" && treeData[x].children[y].children.length > 0) {
                        change = false
                    }
                    for (let z = 0; z < treeData[x].children[y].children.length; z++) {
                        if (treeData[x].children[y].children[z].children !== undefined && treeData[x].children[y].children[z].children.length > 0) {
                            change = false
                        }
                    }
                }
            }
        }
    }
    return change
}

export default class Tree extends Component {
    constructor(props) {
        super(props)

        this.state = {
            treeData: [],
            heigth: heigth(this.props.viewData.data)
        }
    }

    childUpdate = () => {
        for (let x = 0; x < this.props.viewData.contProps.dnaReducer.storeDna.revenue.length; x++) {
            let child = this.props.viewData.contProps.dnaReducer.storeDna.revenue[x]
            if (child.children !== undefined) {
                for (let m = 0; m < child.children.length; m++) {
                    let secoundChild = child.children[m]
                    this.state.treeData[x].children.push({ id: secoundChild.id, trsType: secoundChild.trsType, title: secoundChild.trsType === "winbook" ? secoundChild.source + " " + secoundChild.description : secoundChild.description, [child.children !== undefined && child.children.length !== 0 ? "expanded" : ""]: true, [child.trsType === "group" ? "children" : ""]: [] })
                    if (secoundChild.children !== undefined) {
                        for (let r = 0; r < secoundChild.children.length; r++) {
                            this.state.treeData[x].children[m].children.push({ id: secoundChild.children[r].id, trsType: secoundChild.children[r].trsType, title:secoundChild.trsType === "winbook" ? secoundChild.children[r].source + " " + secoundChild.children[r].description : secoundChild.children[r].description, expanded: true })
                        }
                    }
                }
            }
        }
    }


    updateArray() {
        for (let x = 0; x < this.props.viewData.contProps.dnaReducer.storeDna.revenue.length; x++) {
            let child = this.props.viewData.contProps.dnaReducer.storeDna.revenue[x]
            this.state.treeData.push({
                id: child.id,
                trsType: child.trsType,
                title: child.trsType === "winbook" ? child.source + " " + child.description : child.description,
                [child.children !== undefined && child.children.length !== 0 ? "expanded" : ""]: true,
                [child.trsType === "group" ? "children" : ""]: []
            })
        }
        this.childUpdate()
    }
    handleChange = (treeData) => {
        if (handleChangeTree(treeData)) { this.setState({ treeData }) }
        this.props.viewData.contProps.storeArrangeAcc(treeData)
    }


    render() {
        if (this.state.treeData.length === 0 && this.props.viewData.contProps.dnaReducer.storeDna.revenue !== null) {
            this.updateArray()
        }
        return (this.state.treeData.length !== 0 ?
            <div className="dna-tree">
                <SortableTree
                    className={"rstcustom__rowTitle"}
                    rowHeight={20}
                    treeData={this.state.treeData}
                    onChange={treeData => this.handleChange(treeData)}
                    theme={BMSTheme}
                    scaffoldBlockPxWidth={16}
                />
            </div> : ""
        )
    }
}