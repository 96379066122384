import React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export default function GroupDialog(props) {
    return (
        //   
        <Dialog aria-labelledby="form-dialog-title" open={props.expenseData.open}  onClose={() => props.handleExpenseNew(true, { id: 0, name: "" })}>
            <DialogTitle id="form-dialog-title">Expense settings</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    onChange={(e)=>props.handleExpenseText(e)}
                    value={props.expenseData !== null ? props.expenseData.name : ""}
                    margin="dense"
                    id="Group-name"
                    label="Expense Name"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button  color="primary"  onClick={() => props.handleExpenseNew(false, { id: 0, name: "" })} >
                    Cancel
                </Button>
                <Button color="primary"  onClick={()=> props.handleExpenseTextSave() }>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
}
