import { functions } from "lodash"
import { API_URL, xauth } from "../../../Constants"

export function startLoading() {
    return { type: "LOADING_START" }
}

export function stopLoading() {
    return { type: "LOADING_STOP" }
}

export function handleGroup(data) {
    return { type: "HANDLE_GROUP_NAME", data }
}

export function handleGroupHeading(event) {
    return { type: "HANDLE_GROUP_HEADING", target: event.target }
}

export function clearGroupNameData() {
    return { type: "ADD_SALARY_ARRAY" }
}


export function updateGroupName(repId, data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(clearGroupNameData())
        fetch(API_URL + "/salaires/" + repId + "/group/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getSalRepo(repId))
                dispatch(stopLoading())
            })
    }
}

export function handleSetDataPopUp(array) {
    return { type: "HANDLE_SET_DATA_POP_UP", array }
}

export function storeAccounts(list) {
    return {
        type: "STORE_DNA_ACCOUNTS",
        accounts: list
    }
}

export function getAccounts(auditId) {
    return function (dispatch) {
        
        dispatch(startLoading())
        fetch(API_URL + "/dna/get/accounts?auditid=" + auditId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeAccounts(json))
                dispatch(stopLoading())
            })
    }
}

export function handleDataArraySalary(event) {
    return { type: "HANDLE_DATA_ARRAY_SALARY", target: event.target }
}

export function handleDataArraySalaryBold(value) {
    return { type: "HANDLE_DATA_ARRAY_SALARY_BOLD", checked:value !== 1 ? 1 : 0 }
}

export function handleAddSalary(obj) {
    return { type: "HANDLE_ADD_SALARY", obj }
}

export function handleRowData(event, index) {
    return { type: "HANDLE_ROW_DATA", target: event.target, index }
}

export function deleteRowData(index) {
    return { type: "DELETE_SALARY_ARRAY_ROW", index }
}

export function addChildrenToGroup(array, index) {
    return { type: "ADD_CHILDREN_TO_GROUP", array ,index }
}

export function addChildrenToGroupDeduct(array, index) {
    return { type: "ADD_CHILDREN_TO_GROUP_DEDUCT", array ,index }
}

export function storeSalaryReport(array) {
    return { type: "STORE_SELARY_REPORT", array }
}

export function getSalRepo(templateId,auditId) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/salaires/get?aid=" + auditId+"&tid="+templateId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeSalaryReport(json))
                dispatch(stopLoading())
            })
    }
}

export function createGroup(templateId,auditId) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/salaires/" + templateId + "/group/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getSalRepo(templateId,auditId))
                dispatch(stopLoading())
            })
    }
}

export function handleDeleteRepo(repId, dataId) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(clearGroupNameData())
        fetch(API_URL + "/salaires/" + repId + "/group/delete/" + dataId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getSalRepo(repId))
                dispatch(stopLoading())
            })
    }
}

export function addDataArray(templateId,auditId, data) {
    
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(hideUpdatePopUp())
        fetch(API_URL + "/salaires/" + templateId + "/group/create/subgroup" , {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getSalRepo(templateId,auditId))
                dispatch(stopLoading())
            })
    }
}

export function stopSalryArrayUpdate() {
    return { type: "STOP_SALARY_ARRAY_UPDATE" }
}

export function showUpdatePopUp(data, index, trsType, mainIndex) {
    return { type: "SHOW_UPDATE_POP_UP", data, index, trsType, mainIndex }
}

export function hideUpdatePopUp() {
    return { type: "Hide_UPDATE_POP_UP" }
}
export function changeUpdateDataAdd(value, name) {
    return { type: "CHANGE_UPDATE_DATA_ADD", value, name }
}
export function saveUpdatedDateAdd(value, name) {
    return { type: "SAVE_UPDATE_DATA_ADD", value, name }
}
export function saveUpdatedDateDetuct(value, name) {
    return { type: "SAVE_UPDATE_DATA_DETUCT", value, name }
}

export function deleteData(index, sIndex, name, array) {
    if (name==="add") {
        return { type: "DELETE_DATA_LIST_ADD", index, sIndex , array}
        
    }
    else {
        return { type: "DELETE_DATA_LIST_DEDUCT", index, sIndex, array }
    }
}