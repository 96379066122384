import {API_URL} from '../Constants';
import {startLoading, stopLoading, SnackBar, handleHTTPError} from './Common'

const xauth = window.xauth ? window.xauth : "MjA6OQ=="

export function getReports(){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(storeReports(json));
              dispatch(stopLoading());
          });
    }
}

export function createReport(reportName,reportDescription){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/create',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify({
                name:reportName,
                description:reportDescription
            }),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());
          });
    }
}



export function updateReport(reportData){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify(reportData),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());
          });
    }
}

export function deleteReport(reportID){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/delete/'+reportID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());
          });
    }
}




export function storeReports(list){
    return {
        type: 'STORE_REPORTS',
        reports:list
    }
}

export function selectReport(reportID){
    return{
        type:'SHOW_SETTINGS',
        report: reportID
    }
}

export function getReportSettings(reportID){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/get?rid='+reportID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(storeLoadedSettings(json));
              dispatch(stopLoading());
          });
    }
}

export function getReportData(reportID,book,fromP,toP){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/data?rid='+reportID+'&b='+book+'&f='+fromP+'&t='+toP,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(storeLoadedData(json));
              dispatch(stopLoading());
          });
    }
}

export function storeLoadedSettings(data){
    return{
        type:'LOADED_SETTINGS',
        settings: data
    }
}

export function storeLoadedData(data){
    return{
        type:'LOADED_DATA',
        data: data
    }
}

export function resetSettings(){
    return function(dispatch){
        dispatch(selectReport(null));
        dispatch(storeLoadedSettings(null));
    }
}

export function updareReportBasicInfo(fieldName,value){
    return{
        type:'UPDATE_REPORT_BASIC',
        field: fieldName,
        value:value
    }
}

export function addColumn(cType,cOrder,rerpotID){
    return{
        type:'ADD_COLUMN',
        column:{
                    col_type: cType,
                    column_order: cOrder,
                    first_period_id: null,
                    breakdown:0,
                    id: 0,
                    period: 0,
                    report_id: rerpotID,
                    sec_period_id: null,
                    difference_in:null,
                    book:null,
                    difference:0
                }
    }
}

export function removeColumn(colIndex){
    return{
        type:'REMOVE_COLUMN',
        column:colIndex
    }
}

export function updateColumn(index,field,value){
    return{
        type:'UPDATE_COLUMN',
        field:field,
        index:index,
        value:value
    }
}
export function moveColumn(startIndex, endIndex){
    return{
        type:'MOVE_COLUMN',
        start:startIndex,
        end:endIndex,
    }
}

export function updateDetails(details){
    return{
        type:'UPDATE_DETAILS',
        details:details
    }
}


export function setBook(book,from,to){
    return{
        type:'SET_BOOK_DASHBOARD',
        book:book,
        from:from,
        to,to,
    }
}

export function setCompareBook(book,from,to){
    return{
        type:'SET_COMPARE_BOOK_DASHBOARD',
        book:book,
        from:from,
        to,to,
    }
}


export function GetMainGraphData(b,f,t,cb,cf,ct,p,view){
    return function(dispatch){
        dispatch(startLoading());
        dispatch(SnackBar(true,'Loading data','info'));
        fetch(API_URL+'/web-dash/mainGraph?b='+b+'&f='+f+'&t='+t+'&cb='+cb+'&cf='+cf+'&ct='+ct+'&p='+p+'&v='+view,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(handleHTTPError)
        .then((response)=>{

                response.json().then((json)=>{
                    dispatch(StoreGraphData(json));
                    dispatch(stopLoading());
                });
        }).catch((error)=>{
            dispatch(stopLoading());
            dispatch(SnackBar(true,'Could not load data','error'));
          });
    }
}


export function StoreGraphData(data){
    return{
        type:'STORE_GRAPH_DATA',
        data:data
    }
}

export function UpdateWidget(data){
    return{
        type:'UPDATE_WIDGET',
        data:data
    }
}

export function UpdateWidgetData(page,widgetData,b,f,t,cb,cf,ct){
    return function(dispatch){
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating...','info'));
        fetch(API_URL+'/web-dash/widget/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify(widgetData),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetWidget(page,b,f,t,cb,cf,ct));
          });
    }
}


export function DeleteWidget(page,widgetData,b,f,t,cb,cf,ct){
    return function(dispatch){
        dispatch(startLoading());
        dispatch(SnackBar(true,'Removing widget','info'));
        fetch(API_URL+'/web-dash/widget/delete',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify(widgetData),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetWidget(page,b,f,t,cb,cf,ct));
          });
    }
}


export function CreateWidget(page,b,f,t,cb,cf,ct) {
    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Creating widget','info'));
        fetch(API_URL+'/web-dash/widget/create?pid='+page,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetWidget(page,b,f,t,cb,cf,ct));
          });
    }
}

export function GetWidget(page,b,f,t,cb,cf,ct){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/widget/get?b='+b+'&f='+f+'&t='+t+'&cb='+cb+'&cf='+cf+'&ct='+ct+'&pid='+page,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(StoreWidgets(json));
          });
    }
}


export function StoreWidgets(data){
    return{
        type:'STORE_WIDGETS',
        widgets:data
    }
}

export function GetCfGraphData(b,f,t,cb,cf,ct){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/cfGraph?b='+b+'&f='+f+'&t='+t+'&cb='+cb+'&cf='+cf+'&ct='+ct,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{

              dispatch(StoreCfGraphData(json));
              dispatch(stopLoading());
          });
    }
}


export function StoreCfGraphData(data){
    return{
        type:'STORE_CF_GRAPH_DATA',
        data:data
    }
}

export function AddPage(parent){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/add-page',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify({
                                    id:0,
                                    parent:parent,
                                    name:"New Page",
                                    data:{
                                            name:"",
                                            type:"",
                                            data:[]
                                          }
                                }),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetPages());
          });
    }
}

export function CopyPage(pageId){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/page-duplicate?pid='+pageId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            }            
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetPages());
          });
    }
}

export function GetPages(){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/get-pages',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(StorePages(json));
          });
    }
}

export function StorePages(data){
    return{
        type:'STORE_PAGES',
        pages:data
    }
}


export function UpdatePageName(id,text){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/update-page',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify({
                                    id:id,
                                    name:text}),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetPages());
          });
    }
}

export function UpdatePageData(id,data){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/update-page-data',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify({
                                    id:id,
                                    data:data}),
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetPages());
          });
    }
}

export function DeletePage(id){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/web-dash/delete-page?id='+id,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(GetPages());
          });
    }
}


export function storeCompanies(list) {
    return {
        type: "STORE_DASHBOARD_COMPANIES",
        companies: list
    }
}
export function storeSelectedCompany(selected) {
    return {
        type: "STORE_SELECTED_COMPANY",
        selected
    }
}

export function getCompanies() {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/web-dash/companies", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                dispatch(storeCompanies(json))
                dispatch(storeSelectedCompany(json[0].id))
                dispatch(stopLoading())
            })
    }
}


export function importReports(companyId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/web-dash/import?cid=" + companyId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                window.location.reload();
                dispatch(stopLoading())
            })
    }
}


export function exportReports(data) {
    
    return function(dispatch) {
        // dispatch(startLoading())
        // fetch(API_URL + "/web-dash/import?cid=" + companyId, {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json;charset=utf-8",
        //         "X-Auth-Token": xauth
        //     }
        // }).then(response => response.json(), error => console.log('An error occurred.', error))               
        //     .then((json) => {
        //         window.location.reload();
        //         dispatch(stopLoading())
        //     })
    }
}


export function GetPrintData(b,f,t,cb,cf,ct,p,view){
    return function(dispatch){
        
        dispatch(startLoading());
        dispatch(SnackBar(true,'Loading print data','info'));
        fetch(API_URL+'/web-dash/print?b='+b+'&f='+f+'&t='+t+'&cb='+cb+'&cf='+cf+'&ct='+ct+'&p='+p+'&v='+view,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(handleHTTPError)
        .then((response)=>{

                response.json().then((json)=>{
                    dispatch(StoreExportData(json));
                    dispatch(stopLoading());
                });
        }).catch((error)=>{
            dispatch(stopLoading());
            dispatch(SnackBar(true,'Could not load data','error'));
          });
    }
}

export function StoreExportData(data){
    return{
        type:'STORE_EXPORT_DASH_DATA',
        data:data
    }
}


export function UpdateChartImage(index,imageData){
    return{
        type:'UPDATE_CHART_IMAGE',
        index,
        data:imageData
    }
}

export function UpdatePieChartImage(index,imageData,section){
    return{
        type:'UPDATE_PIE_CHART_IMAGE',
        index,
        data:imageData,
        section
    }
}