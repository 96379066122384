import { Alert, Container, Dialog, DialogContent, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField } from "@mui/material";

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import React, { useEffect, useState } from "react";
import Model1 from "./model-1";
import '../vf.css';

import SidePanel from "./side-panel";
import AccountHeaderBar from "./account-header-bar";
import AccountDetails from "./account-details";
import { API_URL } from "../config";
import AccountActions from "./account-actions";
import Model2 from "./model-2";
import Model3 from "./model-3";
import Model4 from "./model-4";
import Model5 from "./model-5";
import Model6 from "./model-6";
import Model7 from "./model-7";
import { Add, AddOutlined, CheckCircle, CheckCircleOutline, Delete, DeleteOutline } from "@mui/icons-material";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalChecklist(props) {

  const [data, setMainData] = useState(null);
  const [modelData, setModelData] = useState([]);
  const [modelSubData, setModelSubData] = useState([]);
  const [totals, setTotals] = useState({
    model_1_total: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null)
  const [description,setDescription] = useState("");
  const [account,setAccount]=useState("");

  function loadMainData(auditId) {
    setIsLoading(true)

    fetch(API_URL + "/audit/checklist?audit_id=" + auditId)
      .then((response) => response.json())
      .then((response) => {
        setMainData(response);        
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function removeItem(itemId) {
    setIsLoading(true)

    fetch(API_URL + "/audit/checklist-delete?id=" + itemId)
      .then((response) => response.json())
      .then((response) => {
        loadMainData(props.auditId)        
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function toggleStatus(itemId) {
    setIsLoading(true)

    fetch(API_URL + "/audit/checklist-status?id=" + itemId+"&audit_id="+props.auditId)
      .then((response) => response.json())
      .then((response) => {
        loadMainData(props.auditId)        
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  

  function addChecklistItem() {

    if(description === null){
        return false;
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/checklist-item?audit_id=" + props.auditId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ description: description,account:account })
    })
      .then((response) => response.json())
      .then((response) => {
        //oadModelData(data);s
        setAccount("");
        setDescription("");
        loadMainData(props.auditId)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }


  if (props.open === true && data === null) {

    if (isLoading === false) {
      loadMainData(props.auditId)
    }
    
  }

  function closeView() {
    setMainData(null)
    props.handleClose()
  }


  console.log(props)


  return <Dialog
    fullScreen
    open={props.open}
    onClose={closeView}
    PaperProps={{
      sx: {
        backgroundColor: 'rgb(252 252 252)', // Set dialog paper color here
      },
    }}



  >
    <AppBar className="template-app-bar" sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

      </Toolbar>
    </AppBar>

    <Typography id="modal-modal-title" variant="h6" component="h2">

    </Typography>
    <DialogContent>
      <Container maxWidth="lg">
      <h3 className="widget-main-number">{data !== null ? data.percentage : 0}%</h3>
        <Paper className='widget-paper'>
          
          <Table aria-label="collapsible table" className="audit-table" style={{ fontSize: "11px" }}>            
            <TableBody>
              {data !== null ? data.items.map((item,index)=><TableRow key={"cl-"+index}>
                <TableCell className="actions" >
                    <IconButton onClick={()=>toggleStatus(item.id)}>
                        { item.status == 0 ? <CheckCircleOutline/> : <CheckCircle color="success" /> }
                        
                    </IconButton>
                </TableCell>
                <TableCell className="account-name">{item.description}</TableCell>                
                <TableCell className="current-col" align="center">{item.account}</TableCell>
                <TableCell className="difference-col" align="center">
                    <IconButton onClick={()=>removeItem(item.id)}>
                        <DeleteOutline/>
                    </IconButton>
                </TableCell>
                <TableCell className="compare-col" align="center">&nbsp;</TableCell>
              </TableRow>) : null }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="actions" ></TableCell>
                <TableCell className="account-name"><TextField id="outlined-basic" value={description} onChange={(event)=>setDescription(event.target.value)}  fullWidth label="Description" variant="outlined" /></TableCell>               
                <TableCell className="current-col" align="center"><TextField id="outlined-basic" value={account} onChange={(event)=>setAccount(event.target.value)} label="Account" fullWidth variant="outlined" /></TableCell>
                <TableCell className="difference-col" align="center">
                    <IconButton onClick={()=>addChecklistItem()}>
                        <AddOutlined/>
                    </IconButton>
                </TableCell>
                <TableCell className="compare-col" align="center">&nbsp;</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Container>



      <Snackbar open={isLoading} autoHideDuration={6000} >
        <Alert

          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Loading...
        </Alert>
      </Snackbar>
    </DialogContent>
  </Dialog>;
}

export default ModalChecklist