import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import clsx from "clsx"
import Radio from "@mui/material/Radio"
import  makeStyles  from "@emotion/styled"
import "./styles.css"

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    divData: {
        padding: "10px",
        textAlign: "left"
    },
    formControl: {
        width: "200px"
    },
    formControlWinBook: {
        width: "450px"
    },
    popMargin: {
        margin: "20px 0 20px 0"
    }
})


function StyledRadio(props) {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

export default function AlertDialog(props) {
    const classes = useStyles()

    return (
        <div>
            <Dialog
                open={props.mvrList.repoShow}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="reportPopHeading">Report Title</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            <div className={classes.popMargin}>
                                <TextField
                                    id="standard-password-input"
                                    type="Text"
                                    placeholder="name"
                                    name="name"
                                    onChange={props.handleRepoDataUpdate}
                                    value={props.mvrList.repo.name}
                                    label="Report Title"
                                />
                            </div>

                            <div className={classes.popMargin}>
                                <TextField
                                    id="standard-password-input"
                                    type="Text"
                                    placeholder="Report Description"
                                    name="description"
                                    onChange={props.handleRepoDataUpdate}
                                    value={props.mvrList.repo.description}
                                    label="Report Description"
                                />
                            </div>
                        </div>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClearRepData} color="primary">
                        cancel
                    </Button>
                    <Button onClick={props.handleRepo} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
