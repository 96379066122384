import React, { PureComponent } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@mui/material/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "./view"
import { getReportData, setBook } from "../../../Actions/Reporting"
import {getCompanies} from "../../../Actions/Dashboard"
import {
    handleGroup, handleAccountsSet, hideRevenueSubHead, handleAccSectionChange, storeArrangeAcc, saveArrangeAcc, handleRepoSelect, handleCompanyChange, importReports,
    handleExpenseNew, closeExpenseNewPop, accountSecPopShow, accSectionPopHide, createExpenseAccount, storeArrangeExp, saveArrangeExp, handleSwitchChange,
    getAccounts, getReport, updateReportField, resetSelectedReport, updateReport, showNewSectionDialogAction, handleSingleChange, handleSwitchUpdate, handleChangeDataSwitch,
    updateSectionField, saveSection, deleteSection, secttionPopUp, handleSectionDataType, handleChangeData, expensePopUp, updateExpenseField, createExpense, selectDefaultBook, getBooks,
    createSubSection, sectionId, showExpenseUpdateDialog, deleteExpense, handleClearSectionData, clearEcpenceFill, accDataShowHide, closeNewSectionDialog,
    handleTransPopUpdate, handleDeleteSection, handleDeleteTransactions, addArrayRow, handleChangeRowData, updateRevenue, singleChangeUpdate, handleExpenseDesUpdate,
    handleDeleteDataRow, handleAddExpense, handleExpenseRowUpdate, handleDeleteExpenceRow, handleExpenseEdit, handleUpdateExpense, handleGroupTitle, handleExpenseTextSave,
    addRevenueg, addExpense, ChangeGroupText, expanceChangeText, deleteExpenseGroup, handleDeleteRevenGroup, handleChangeExpence, inputChange, handleLimProf, changeLimProf
} from "./actions"
import { refresh } from "../../../Actions/Common"

const mapStateToProps = (state) => ({
    reducerMvrList: state.reducerMvrList,
    selectedReportId: state.reducerMvrList.selectedReport,
    selectedRepordData: state.reducerMvrSettings.report,
    showNewSectionDialog: state.reducerMvrSettings.showNewSectionDialog,
    sectionData: state.reducerMvrSettings.sectionData,
    selectedBook: state.reducerMvrSettings.selectedBook,
    fromPeriod: state.Reports.selectedFromPeriod,
    reportID: state.Reports.showReportSettings,
    reportData: state.Reports.reportData,
    dnaReport: state.DNAReport.accounts,
    expenseDate: state.reducerMvrSettings.expense,
    books: state.reducerMvrSettings.books,
    selectedBookMvr: state.reducerMvrSettings.selectedBook,
    selectedPeriod: state.reducerMvrSettings.selectedPeriod,
    section: state.reducerMvrSettings,
    expenseSelectedGroup: state.reducerMvrSettings.expenseSelectedGroup,
    expensesGroupUpdated: state.reducerMvrSettings.expensesGroupUpdated,
    expensesUpdatedGroupIndex: state.reducerMvrSettings.expensesUpdatedGroupIndex,
    expensesUpdatedRowIndex: state.reducerMvrSettings.expensesUpdatedRowIndex,
    expenseAddParentId: state.reducerMvrSettings.expenseAddParentId,
    dashReducer: state.Dashboard,
    route: state.PathRouterReducer

})

const mapDispatchToProps = dispatch => ({
    refresh: () => dispatch(refresh()),
    getCompanies: () => dispatch(getCompanies()),
    importReports: (cid) => dispatch(importReports(cid)),
    // Revenue saveArrangeAcc
    handleGroup: (con, data) => dispatch(handleGroup(con, data)),
    handleAccountsSet: () => dispatch(handleAccountsSet()),
    hideRevenueSubHead: () => dispatch(hideRevenueSubHead()),
    handleAccSectionChange: (e) => dispatch(handleAccSectionChange(e)),
    storeArrangeAcc: (data) => dispatch(storeArrangeAcc(data)),
    saveArrangeAcc: (data, id, book, period) => dispatch(saveArrangeAcc(data, id, book, period)),

    // expense 
    handleExpenseNew: (con, name) => dispatch(handleExpenseNew(con, name)),
    closeExpenseNewPop: () => dispatch(closeExpenseNewPop()),
    accountSecPopShow: (parentId) => dispatch(accountSecPopShow(parentId)),
    accSectionPopHide: () => dispatch(accSectionPopHide()),
    createExpenseAccount: (data, id, groupId, book, period) => dispatch(createExpenseAccount(data, id, groupId, book, period)),
    storeArrangeExp: (data) => dispatch(storeArrangeExp(data)),
    saveArrangeExp: (data, id, book, period) => dispatch(saveArrangeExp(data, id, book, period)),

    getAccounts: (sBkV, sPdv, repId, aId) => dispatch(getAccounts(sBkV, sPdv, repId, aId)),
    handleClearSectionData: () => dispatch(handleClearSectionData()),
    handleTransPopUpdate: (data) => dispatch(handleTransPopUpdate(data)),
    createSubSection: (data, reportId, book, period) => dispatch(createSubSection(data, reportId, book, period)),
    handleChangeData: (name, data) => dispatch(handleChangeData(name, data)),
    handleChangeDataSwitch: (value) => dispatch(handleChangeDataSwitch(value)),
    handleSectionDataType: (typeId) => dispatch(handleSectionDataType(typeId)),
    getReport: (reportId, bookId, periodId) => dispatch(getReport(reportId, bookId, periodId)),
    secttionPopUp: (popBln) => dispatch(secttionPopUp(popBln)),
    updateReportField: (key, value) => dispatch(updateReportField(key, value)),
    resetSelectedReport: () => dispatch(resetSelectedReport()),
    updateReport: (data) => dispatch(updateReport(data)),
    showNewSectionDialogAction: (show, data) => dispatch(showNewSectionDialogAction(show, data)),
    updateSectionField: (key, value) => dispatch(updateSectionField(key, value)),
    saveSection: (id, data, book, report) => dispatch(saveSection(id, data, book, report)),
    deleteSection: (reportId, id, bId, rId) => dispatch(deleteSection(reportId, id, bId, rId)),
    sectionId: (id) => dispatch(sectionId(id)),
    setBook: (book, from, to) => { dispatch(setBook(book, from, to)) },
    getReportData: (reportID, book, from, to) => dispatch(getReportData(reportID, book, from, to)),
    expensePopUp: (popBln, groupId, gropData) => dispatch(expensePopUp(popBln, groupId, gropData)),
    updateExpenseField: (key, value) => dispatch(updateExpenseField(key, value)),
    createExpense: (data, id, groupId, book, period) => dispatch(createExpense(data, id, groupId, book, period)),
    getBooks: () => dispatch(getBooks()),
    selectDefaultBook: (book, period) => dispatch(selectDefaultBook(book, period)),
    showExpenseUpdateDialog: (show, data) => dispatch(showExpenseUpdateDialog(show, data)),
    deleteExpense: (reportId, id, bId, rId) => dispatch(deleteExpense(reportId, id, bId, rId)),
    handleDeleteSection: (repoId, id, book, report) => dispatch(handleDeleteSection(repoId, id, book, report)),
    handleDeleteTransactions: (repoId, id, book, report) => dispatch(handleDeleteTransactions(repoId, id, book, report)),
    addArrayRow: (data) => dispatch(addArrayRow(data)),
    handleChangeRowData: (target, index) => dispatch(handleChangeRowData(target, index)),
    handleDeleteDataRow: (index) => dispatch(handleDeleteDataRow(index)),
    handleAddExpense: (data) => dispatch(handleAddExpense(data)),
    handleExpenseRowUpdate: (data, index) => dispatch(handleExpenseRowUpdate(data, index)),
    handleDeleteExpenceRow: (index) => dispatch(handleDeleteExpenceRow(index)),
    handleExpenseEdit: (show, data, allData) => dispatch(handleExpenseEdit(show, data, allData)),
    handleUpdateExpense: (data, id, book, period) => dispatch(handleUpdateExpense(data, id, book, period)),
    clearEcpenceFill: () => dispatch(clearEcpenceFill()),
    accDataShowHide: () => dispatch(accDataShowHide()),
    updateRevenue: (secData, id, slcBk, sPrd) => dispatch(updateRevenue(secData, id, slcBk, sPrd)),
    handleSingleChange: (target, index, groupIndex) => dispatch(handleSingleChange(target, index, groupIndex)),
    singleChangeUpdate: (data, id, book, period) => dispatch(singleChangeUpdate(data, id, book, period)),
    closeNewSectionDialog: () => dispatch(closeNewSectionDialog()),
    handleGroupTitle: (con, title) => dispatch(handleGroupTitle(con, title)),
    handleExpenseTextSave: (con, title) => dispatch(handleExpenseTextSave(con, title)),
    handleExpenseDesUpdate: (event, index) => dispatch(handleExpenseDesUpdate(event, index)),
    handleSwitchUpdate: (value, index) => dispatch(handleSwitchUpdate(value, index)),
    addRevenueg: (data, reportId, bookId, periodId) => dispatch(addRevenueg(data, reportId, bookId, periodId)),
    addExpense: (data, reportId, bookId, periodId) => dispatch(addExpense(data, reportId, bookId, periodId)),
    ChangeGroupText: (data) => dispatch(ChangeGroupText(data)),
    expanceChangeText: (data) => dispatch(expanceChangeText(data)),
    deleteExpenseGroup: (reportId, id, bId, rId) => dispatch(deleteExpenseGroup(reportId, id, bId, rId)),
    handleDeleteRevenGroup: (repoId, revGrpId, bookId, periodId) => dispatch(handleDeleteRevenGroup(repoId, revGrpId, bookId, periodId)),
    handleChangeExpence: (name, value) => dispatch(handleChangeExpence(name, value)),
    handleRepoSelect: (bool) => dispatch(handleRepoSelect(bool)),
    handleCompanyChange: (bool) => dispatch(handleCompanyChange(bool)),
    handleSwitchChange: (swtc) => dispatch(handleSwitchChange(swtc)),
    inputChange: (bool) => dispatch(inputChange(bool)),
    handleLimProf: (reportdID, bookId, periodId, data) => dispatch(handleLimProf(reportdID, bookId, periodId, data)),
    changeLimProf: (data) => dispatch(changeLimProf(data))
    
})

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
})

class SettingsView extends PureComponent {

    constructor(props) {
        super(props)


        this.state = {
            reportName: "",
            reportDescription: ""
        }
        // this.props.getBooks()
        this.updateTimer = null;
    }

    // Add Revenue
    handleGroup = (con, data,) => {
        this.props.handleGroup(con, data)
    }

    // Sub Group Adding
    handleShowNewSectionDialog = (show, data) => {
        this.props.showNewSectionDialogAction(show, data)
    }

    // Sub Group handle Change 
    saveExpenseNewPop = () => {
        this.props.addRevenueg(this.props.section.sectionData, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
        this.props.hideRevenueSubHead()
    }

    // Delete Revenue
    handleDeleteRevenGroup = (revGrpId) => {
        this.props.handleDeleteRevenGroup(this.props.selectedReportId, revGrpId, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }
    // Revenue Account create
    handleSaveExpense = () => {
        this.props.createExpense(this.props.section.expArray, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
        this.handleExpenseHide()
    }
    // Expence Account create
    saveExpenseAccounts = () => {
        this.props.createExpenseAccount(this.props.section.expArray, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    // Expense Data Add
    handleExpenseNew = (con, data,) => {
        this.props.handleExpenseNew(con, data)
    }

    handleSectionPopShow = (section) => {
        this.props.sectionId(section)
    }

    handleSectionPopHide = () => {
        this.props.secttionPopUp(false)
        this.handleClearSectionData()
    }

    handleExpenseShow = (show, groupId, gropData) => {
        this.props.expensePopUp(show, groupId, gropData)
    }

    handleExpenseHide = () => {

        this.props.showExpenseUpdateDialog(false, { id: 0, description: "", account: "", private_percentage: "", tax_percentage: "" })
    }

    handleExpenseEdit = (show, data, allData) => {
        this.props.handleExpenseEdit(show, data, allData)
    }

    componentDidMount = () => {
        this.props.getCompanies()
        if (this.props.selectedReportId === null) {
            this.handleGoBack()
        }
        else if (this.props.route.selectedBook !== null && this.props.route.selectedPeriod !== null) {
            this.props.getReport(this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
            this.props.getAccounts(this.props.route.selectedBook, this.props.route.selectedPeriod, this.props.selectedReportId)
        }
    }

    componentDidUpdate = () => {
        // if (this.props.route.selectedBook !== null && this.props.route.selectedPeriod !== null && this.props.selectedRepordData === null&&this.props.section.apiDelay === false) {
        //     this.props.getReport(this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
        //     this.props.getAccounts(this.props.route.selectedBook, this.props.route.selectedPeriod, this.props.selectedReportId)
        // }

        if (this.props.expensesGroupUpdated) {

            let data = this.props.selectedRepordData.expenses_groups[this.props.expensesUpdatedGroupIndex].expenses[this.props.expensesUpdatedRowIndex]

            this.props.singleChangeUpdate(data, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod);

        }
    }

    handleGoBack = () => {
        this.props.resetSelectedReport()
        this.props.history.goBack()
    }


    handleReportUpdate = name => event => {
        this.props.updateReportField(name, event.target.value)
    }

    handleExpenseUpdate = name => event => {
        if (name === "account") {
            this.props.updateExpenseField('source', event.NUMBER)
        }
        if (name === "bold") {
            this.props.updateExpenseField(name, event.target.checked === true ? 1:0)
        }
        else {
            this.props.updateExpenseField(name, event.target.value)
        }
    }


    handleUpdateReport = () => {
        this.props.updateReport(this.props.selectedRepordData)
    }



    handleSectionChanges = name => event => {
        this.props.updateSectionField(name, event.target.value)
    }

    handleSaveSectionHead = () => {
        this.props.saveSection(this.props.section.selectedReport, this.props.section.expenseData, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    handleDelete = (reportId, id) => {
        this.props.deleteSection(reportId, id, this.props.sectionData, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }
    handleDeleteExpense = (reportId, id) => {
        this.props.deleteExpense(reportId, id, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    handleSectionDataType = (event) => {
        this.props.handleSectionDataType(event.target.value)
    }

    handleChangeData = (event) => {
        this.props.handleChangeData(event.target.name, event.target.value)
    }
    handleSaveSection = () => {
        this.props.createSubSection(this.props.section.secArray, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
        this.closeNewSectionDialog()
        this.props.secttionPopUp(false)
    }
    changeFromPeriod = event => {
        this.props.setBook(this.props.route.selectedBook, event.target.value, event.target.value)
    }


    // handleChangeBook = event => {
    //     this.props.selectDefaultBook(event.target.value, event.target.value.periods[0])
    //     this.props.getReport(this.props.selectedReportId, event.target.value.value, this.props.route.selectedPeriod)
    //     this.props.getAccounts(event.target.value.value, this.props.route.selectedPeriod, this.props.selectedReportId)
    // }

    // handleChangePeriod = event => {
    //     this.props.selectDefaultBook(this.props.route.selectedBook, event.target.value)
    //     this.props.getReport(this.props.selectedReportId, this.props.route.selectedBook, event.target.value.value)
    //     this.props.getAccounts(this.props.route.selectedBook, event.target.value.value, this.props.selectedReportId)
    // }

    handleTransPopUpdate = (data) => {
        this.props.handleTransPopUpdate(data)
    }
    handleClearSectionData = () => {
        this.props.handleClearSectionData()
    }

    handleDeleteSection = (id) => {
        this.props.handleDeleteSection(this.props.section.report.id, id, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    handleDeleteTransactions = (id) => {
        this.props.handleDeleteTransactions(this.props.section.report.id, id, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    // SecData Data Row
    addArrayRow = () => {
        this.props.addArrayRow({
            ...this.props.section.secData,
            book: this.props.route.selectedBook,
            period: this.props.route.selectedPeriod
        })
    }

    // expence Data Add
    handleAddExpense = (trsType) => {
        for (let x = 0; x < this.props.section.expense.account.length; x++) {
            this.props.handleAddExpense({
                description: "",
                id: 0,
                open: false,
                bold: this.props.section.expenceData.bold,
                parentId: this.props.section.expenceData.parentId !== 0 ? this.props.section.expenceData.parentId : 0,
                trsType: trsType,
                value: 0,
                source: this.props.section.expense.account[x].NUMBER,
                book: this.props.route.selectedBook,
                period: this.props.route.selectedPeriod
            })
        }
    }
    handleAddExpenseTwo = (trsType) => {
        this.props.handleAddExpense({
            source: this.props.section.expenceData.source,
            description: this.props.section.expenceData.description,
            id: 0,
            open: false,
            bold: this.props.section.expenceData.bold,
            parentId: this.props.section.expenceData.parentId !== 0 ? this.props.section.expenceData.parentId : 0,
            trsType: trsType,
            value: this.props.section.expenceData.value,

            book: this.props.route.selectedBook,
            period: this.props.route.selectedPeriod
        })
    }
    // expence Section Account Add
    AddAccountExpenceWin = (trsType) => {
        for (let x = 0; x < this.props.section.expense.account.length; x++) {
            this.props.handleAddExpense({
                description: "",
                id: 0,
                parentId: this.props.section.expenceData.parentId !== 0 ? this.props.section.expenceData.parentId : 0,
                trsType: trsType,
                value: 0,
                source: this.props.section.expense.account[x].NUMBER,
                value: 0,
                personal: 0,
                dna: 0,
                book: this.props.route.selectedBook,
                period: this.props.route.selectedPeriod,
                bold: this.props.section.expenceData.bold
            })
        }
    }
    AddAccountExpenceOther = (trsType) => {
        this.props.handleAddExpense({
            source: this.props.section.expenceData.source,
            description: this.props.section.expenceData.description,
            id: 0,
            parentId: this.props.section.expenceData.parentId !== 0 ? this.props.section.expenceData.parentId : 0,
            trsType: trsType,
            value: this.props.section.expenceData.value,
            personal: 0,
            dna: 0,
            book: this.props.route.selectedBook,
            bold: this.props.section.expenceData.bold,
            period: this.props.route.selectedPeriod
        })
    }

    // expanse Data event change
    handleExpenseRowUpdate = (target, index) => {
        this.props.handleExpenseRowUpdate(target, index)
    }


    // update pop up expence
    handleUpdateExpense = () => {
        this.props.handleUpdateExpense(this.props.section.expense, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)
        this.props.clearEcpenceFill()
    }

    // data pop up Revenue data
    accDataShowHide = () => {
        this.props.accDataShowHide()
        this.props.getAccounts(this.props.route.selectedBook, this.props.route.selectedPeriod, this.props.selectedReportId,0)
    }

    // Revenue Data update
    handleUpdateRevenueData = () => {
        this.props.accDataShowHide()
        this.props.updateRevenue(this.props.section.secData, this.props.section.selectedReport, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    // timer 2000
    handleSingleChange = (e, data) => {

        let sendData = {
            ...data,
            [e.target.name]: e.target.value
        }

        if (this.updateTimer !== null) {
            clearTimeout(this.updateTimer)
        }

        this.updateTimer = setTimeout(() => {
            this.props.saveSection(this.props.section.selectedReport, sendData, this.props.route.selectedBook, this.props.route.selectedPeriod)
        }, 1000);
      
    }


    // clear and close in acoount data create
    closeNewSectionDialog = () => {
        this.props.closeNewSectionDialog()
    }
    // array Update in expance
    handleExpenseUpdateArray = (name, event) => {
        this.props.updateExpenseField(name, event)
    }


    handleText = (e) => {

        var data = {
            trsType: this.props.section.groupData.trsType,
            id: this.props.section.groupData.id,
            description: e.target.value,
            con: true
        }

        this.props.ChangeGroupText(data)
    }

    handleGroupTextSave = () => {
        if(this.props.section.groupData.description === "")
        {
            this.props.handleGroupTitle(false, this.props.section.groupData.name)
        }
        else {
            this.props.handleGroupTitle(false, this.props.section.groupData.name)
            this.props.addRevenueg(this.props.section.groupData, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)    
        }
        }


    handleExpenseText = (e) => {
        var data = {
            id: this.props.section.expenseData.id,
            name: e.target.value,
            con: true
        }

        this.props.expanceChangeText(data)
    }

    handleExpenseTextSave = () => {
        this.props.handleExpenseTextSave(false, this.props.section.expenseData.name)
        this.props.addExpense(this.props.section.expenseData, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod)

    }

    //delete expense group
    deleteExpenseGroup = (reportId, id) => {
        this.props.deleteExpenseGroup(reportId, id, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    handleEditAraay = (aId) => {
        this.props.getAccounts(this.props.route.selectedBook, this.props.route.selectedPeriod, this.props.selectedReportId,aId)
    }

    // clear expense
    clearEcpenceFill = () =>{
        this.props.clearEcpenceFill()
        this.props.getAccounts(this.props.route.selectedBook, this.props.route.selectedPeriod, this.props.selectedReportId,0)
    }

    // repo compeny pop up
    handleRepoSelect= () =>{
        this.props.handleRepoSelect(!this.props.section.company)
    }
    // handleCompanyChange
    handleCompanyChange= (event) =>{
        this.props.handleCompanyChange(event.target)
    }
    // handleImport
     
    handleImport= (event) =>{
        if (this.props.section.importCompany !== null) {
          this.props.importReports(this.props.section.importCompany)
          this.handleRepoSelect()
        }
    }
    // handleSwitchChange
    handleSwitchChange= () =>{
        this.props.handleSwitchChange(!this.props.section.groupData.bold)
    }
    handleLimProf = (event) =>{
        var report_option = {hide:event.target.checked?1:0}
        this.props.handleLimProf(this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod,report_option)
        this.props.changeLimProf(report_option)
    }
    render() {
        return (
            <View
                contProps={this.props}
                handleLimProf={this.handleLimProf}
                handleCompanyChange={this.handleCompanyChange}
                handleImport={this.handleImport}
                handleRepoSelect={this.handleRepoSelect}
                handleGroup={this.handleGroup}
                handleAccountsSet={this.props.handleAccountsSet}
                handleShowNewSectionDialog={this.handleShowNewSectionDialog}
                handleDeleteRevenGroup={this.handleDeleteRevenGroup}
                saveExpenseNewPop={this.saveExpenseNewPop}
                handleSwitchChange={this.handleSwitchChange}

                // expense Set
                handleExpenseNew={this.handleExpenseNew}
                saveExpenseAccounts={this.saveExpenseAccounts}
                handleSectionChanges={this.handleSectionChanges}
                closeExpenseNewPop={this.props.closeExpenseNewPop}
                handleSaveSectionHead={this.handleSaveSectionHead}
                handleSaveExpense={this.handleSaveExpense}
                AddAccountExpenceWin={this.AddAccountExpenceWin}
                AddAccountExpenceOther={this.AddAccountExpenceOther}

                handleEditAraay={this.handleEditAraay}

                handleGoBack={this.handleGoBack}
                handleReportUpdate={this.handleReportUpdate}
                data={this.props.selectedRepordData}
                handleUpdateReport={this.handleUpdateReport}
                showNewSectionDialog={this.props.showNewSectionDialog}
                sectionData={this.props.sectionData}
                handleSaveSection={this.handleSaveSection}
                handleDelete={this.handleDelete}
                selectedBook={this.props.route.selectedBook}
                fromPeriod={this.props.fromPeriod}
                changeFromPeriod={this.changeFromPeriod}
                sectionAllData={this.props.section}
                handleSectionPopShow={this.handleSectionPopShow}
                handleSectionPopHide={this.handleSectionPopHide}
                handleSectionDataType={this.handleSectionDataType}
                handleChangeData={this.handleChangeData}
                dnaReport={this.props.dnaReport}
                handleExpenseShow={this.handleExpenseShow}
                handleExpenseHide={this.handleExpenseHide}
                handleExpenseUpdate={this.handleExpenseUpdate}
                books={this.props.books}
                selectedPeriod={this.props.route.selectedPeriod}
                handleChangeBook={this.handleChangeBook}
                handleChangePeriod={this.handleChangePeriod}
                handleExpenseEdit={this.handleExpenseEdit}
                expenseDate={this.props.expenseDate}
                handleDeleteExpense={this.handleDeleteExpense}
                handleTransPopUpdate={this.handleTransPopUpdate}
                handleDeleteSection={this.handleDeleteSection}
                handleDeleteTransactions={this.handleDeleteTransactions}
                addArrayRow={this.addArrayRow}
                handleChangeRowData={this.props.handleChangeRowData}
                handleDeleteDataRow={this.props.handleDeleteDataRow}
                handleAddExpense={this.handleAddExpense}
                handleAddExpenseTwo={this.handleAddExpenseTwo}
                handleExpenseRowUpdate={this.handleExpenseRowUpdate}
                handleDeleteExpenceRow={this.props.handleDeleteExpenceRow}
                handleUpdateExpense={this.handleUpdateExpense}
                clearEcpenceFill={this.clearEcpenceFill}
                accDataShowHide={this.accDataShowHide}
                handleUpdateRevenueData={this.handleUpdateRevenueData}
                handleSingleChange={this.handleSingleChange}
                closeNewSectionDialog={this.closeNewSectionDialog}
                handleExpenseUpdateArray={this.handleExpenseUpdateArray}
                handleText={this.handleText}
                handleGroupTextSave={this.handleGroupTextSave}
                handleExpenseText={this.handleExpenseText}
                handleExpenseTextSave={this.handleExpenseTextSave}
                handleExpenseDesUpdate={this.props.handleExpenseDesUpdate}
                deleteExpenseGroup={this.deleteExpenseGroup}
                handleChangeExpence={this.props.handleChangeExpence}
                handleSwitchUpdate={this.props.handleSwitchUpdate}
            />
        )
    }
}

SettingsView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps), )(SettingsView)
