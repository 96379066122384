import React, { Fragment } from "react"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import styled from '@emotion/styled'
import MenuItem from "@mui/material/MenuItem"
import Select from 'react-select';
import DeleteIcon from "@mui/icons-material/Delete"
import SettingsIcon from "@mui/icons-material/Settings"
import DoneIcon from "@mui/icons-material/Done"
import Chip from "@mui/material/Chip"
import SelectType from "@mui/material/Select"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import DeleteConfirm from "../../../Common/delete"
import "../styles.css"

const useStyles = styled({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        display: "flex"
    },
    divIcon: {
        paddingRight: "15px"
    },
    divData: {
        paddingBottom: "50px",
        textAlign: "left"
    },
    formControl: {
        width: "220px"
    },
    formControlWinBook: {
        width: "450px"
    },
    type: {
        width: "140px"
    },
    sourcetop: {
        paddingBottom: "30px"
    }
})
const getDataType = (type) => {
    switch (type) {
        case "winbook":
            return "Winbook Data"
        case "formula":
            return "Formula"
        case "manual":
            return "Manual Figure"
        case "voiture":
            return "Voiture"
        default:
            break;
    }
}

const getSource = (value, data) => {
    let source = ""
    for (let x = 0; x < data.length; x++) {

        if (value === data[x].NUMBER) {

            source = data[x].NUMBER + " : " + data[x].NAME11
        }
    }
    return source
}

const getChipLabel = (option) => {
    if (option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}

const selectWinBook = (option, arrayList) => {
    for (let x = 0; x < arrayList.length; x++) {
        if (arrayList[x].NUMBER === option) {
            return arrayList[x]
        }
    }
}

const getExpenceArray = (expArray, dnaReport, selectedAccount) => {
    let expence = []
    if (dnaReport !== null) {
        expence = dnaReport
        if (expArray !== null && expArray.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < expArray.length; x++) {
                    if (expArray[x].winData === undefined) { continue }
                    if (expArray[x].winData.NUMBER === expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }

        if (selectedAccount !== undefined && selectedAccount.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < selectedAccount.length; x++) {
                    if (selectedAccount[x].account === expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }

    }
    return expence
}

export default function SecDataRow(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [deletePop, setDeletePop] = React.useState(false)
    const arrayList = getExpenceArray(props.sectionAllData.expArray, props.dnaReport, props.sectionAllData.expenseSelectedGroupData.expenses)

    const handleTrue = () => {
        setOpen(!open)
        if(props.data.trsType==="voiture"&&!open) {
            props.popProps.viewProps.contProps.getColoumn(
                props.data.vehicle,
                props.popProps.viewProps.contProps.route.selectedBook,
                props.popProps.viewProps.contProps.route.selectedPeriod, 
                1)
        }
    }

    const deleteShowTrue = () => {
        setDeletePop(true)
    }

    const confirmDelete = (confirm) => {
        if (confirm === true) {
            props.popProps.viewProps.contProps.popAccDelete(props.eIndex)
        }
        setDeletePop(false)
    }

    return (
        <Fragment className="tableRow" key={props.key}>{
            open ?
                <tr>
                    <td>
                        <FormControl className={classes.typeSelect}>
                            <SelectType
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="trsType"
                                className="type_select"
                                value={props.data.trsType}
                                onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "trsType", e.target.value)}
                            >
                                <MenuItem value={"winbook"} >Winbook Data</MenuItem>
                                <MenuItem value={"formula"} >Formula</MenuItem>
                                <MenuItem value={"manual"} >Manual Figure</MenuItem>
                                <MenuItem value={"voiture"} >Voiture</MenuItem>
                            </SelectType>
                        </FormControl>
                    </td>
                    <td>
                        {props.data.trsType === "voiture" ?
                            <FormControl className={classes.typeSelect}>
                                <SelectType
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="vehicle"
                                    className="number_plate"
                                    value={props.data.vehicle}
                                    onChange={(e) => props.vehicleChange(e.target.value, props.eIndex)}
                                >
                                    {props.vehicles.map((veh) => (
                                        <MenuItem value={veh.id} >{veh.licence_plate}</MenuItem>
                                    ))}
                                </SelectType>
                            </FormControl> :
                            <TextField
                                id="standard-password-input"
                                name="description"
                                onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "description", e.target.value)}
                                value={props.data.description}
                            />}
                    </td>
                    <td>
                        {props.data.trsType === "winbook" ?
                            <Select
                                inputId="react-select-multiple"
                                TextFieldProps={{
                                    InputLabelProps: {
                                        htmlFor: 'react-select-multiple',
                                        shrink: true,
                                    },
                                    placeholder: 'Select locations',
                                }}
                                options={arrayList}
                                getOptionLabel={option => getChipLabel(option)}
                                getOptionValue={option => `${option.NUMBER}`}
                                onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "source", e.NUMBER)}
                                value={selectWinBook(props.data.source, arrayList)}
                                name="winData"
                            /> : ""}
                        {props.data.trsType === "formula" ?
                            <TextField
                                id="standard-password-input"
                                onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "source", e.target.value)}
                                className="textFieldSecEdit"
                                name="source"
                                value={props.data.source}
                            /> : ""}
                        {props.data.trsType === "manual" ?
                            <TextField
                                id="standard-password-input"
                                onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "value", e.target.value)}
                                className="textFieldSecEdit"
                                name="value"
                                value={props.data.value}
                            /> : ""}
                        {props.data.trsType === "voiture" ?
                            <FormControl className={classes.typeSelect}>
                                <SelectType
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="column_select"
                                    name="source"
                                    value={props.data.source}
                                    onChange={(e) => props.cloumnChange(props.eIndex, e.target.value)}
                                >
                                    {props.columns.map((veh)=>(
                                        <MenuItem value={veh.id} >{veh.label}</MenuItem>
                                    ))}
                                </SelectType>
                            </FormControl> : ""}
                    </td>

                    <td className={props.data.trsType === "voiture" ? "marge_top" : null}>
                        <TextField
                            id="standard-password-input"
                            placeholder=" % DNA"
                            onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "dna", e.target.value)}
                            value={props.data.dna}
                        />
                    </td>
                    <td>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.data.bold === 1}
                                    onChange={(e) => props.popProps.viewProps.contProps.handleWinbookArrayEvent(props.eIndex, "bold", props.data.bold === 1 ? 0 : 1)}
                                    name="bold"
                                    color="primary"
                                />
                            }
                        />
                    </td>

                    <td> <DoneIcon onClick={handleTrue} /> </td>
                </tr>

                :
                <tr>
                    <td>{getDataType(props.data.trsType)}</td>
                    <td>{props.data.trsType !== "voiture" ? props.data.description : props.reduc.vehicles.find((veh) => { return veh.id === props.data.vehicle }).licence_plate}</td>
                    <td>
                        {props.data.trsType === "winbook" ? getSource(props.data.source, props.dnaReport) : null}
                        {props.data.trsType === "formula" ? props.data.source : ""}{props.data.trsType === "manual" ? props.data.value : ""}
                        {props.data.trsType === "voiture" ? props.data.description : null}
                    </td>
                    <td>{props.data.dna}</td>
                    <td>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.data.bold === 1}
                                    name="bold"
                                    color="primary"
                                />
                            }
                        />
                    </td>
                    <td className={classes.icon}>
                        <div className={classes.divIcon}><SettingsIcon onClick={handleTrue} /></div>
                        <div><DeleteIcon onClick={deleteShowTrue} /></div>
                    </td>
                </tr>
        }
        
        <DeleteConfirm
                open={deletePop}
                title={"ligne"}
                confirmDelete={confirmDelete}
            />
        </Fragment>
    )
}
