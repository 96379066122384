import { Avatar, Button, Divider, Grid, Link, Paper } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { Fragment, useState, useRef } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Cancel, Check, Delete, Edit, ExpandLess, OpenWith } from "@mui/icons-material";


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import  SortableTreeWithoutDndContext from '@nosferatu500/react-sortable-tree';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";



function EditFormula({ data, onSave, onCancel,onFieldChange }) {
  return <Paper className='widget-paper'>
    <table width={"100%"}>
      <tr>
        <td>
          Title
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} onChange={(event)=>onFieldChange('description',event.target.value)} value={data.description} />
        </td>
      </tr>      
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          Formula
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} onChange={(event)=>onFieldChange('formula',event.target.value)} value={data.formula} />
        </td>
      </tr>      
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <Button size="small" variant="outlined" color="primary" onClick={()=>onSave()}>
            Save
          </Button>&nbsp;
          <Button size="small" variant="outlined" color="primary" onClick={()=>onCancel()} >
            Cancel
          </Button>
        </td>
      </tr>
    </table>
  </Paper>;
}

function EditAnnex({ data, onSave, onCancel,onFieldChange,list }) {
  
  return <Paper className='widget-paper'>
    <table width={"100%"}>
      <tr>
        <td>
          Title
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} onChange={(event)=>onFieldChange('description',event.target.value)} value={data.description} />
        </td>
      </tr>      
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          Select annex
        </td>
      </tr>
      <tr>
        <td>
          <select onChange={(event)=>onFieldChange('annex_type',event.target.value)} value={data.annex_type}>
              <option value="annex-dna">Annexe DNA</option>
              <option value="annex-isoc">Annexe calcul isoc</option>
              <option value="annex-salaries">Annexe salaires employés</option>
              <option value="annex-concordance">Annexe concordance CA/tva</option>
              <option value="annex-loyers">Annexe loyers</option>
              <option value="annex-courant">Annexe compte courant</option>
              <option value="annex-liquidation">Annexe réserve liquidation</option>
              <option value="annex-ipp">Annexe ipp et inasti</option>
              <option value="annex-tableau">Annexe tableau d'exploitation</option>              
          </select>
        </td>
      </tr>      
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <Button size="small" variant="outlined" color="primary" onClick={()=>onSave()}>
            Save
          </Button>&nbsp;
          <Button size="small" variant="outlined" color="primary" onClick={()=>onCancel()} >
            Cancel
          </Button>
        </td>
      </tr>
    </table>
  </Paper>;
}

function EditGroup({ data, onSave, onCancel,onFieldChange }) {
  return <Paper className='widget-paper'>
    <table width={"100%"}>
      <tr>
        <td>
          Group Title
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} onChange={(event)=>onFieldChange('description',event.target.value)} value={data.description} />
        </td>
      </tr>
      <tr>
        <td>
          Included accounts
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} onChange={(event)=>onFieldChange('include_accounts',event.target.value)}  value={data.include_accounts} />
        </td>
      </tr>
      <tr>
        <td>
          Variable name
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" style={{ width: "100%" }} value={data.var_name} onChange={(event)=>onFieldChange('var_name',event.target.value)} />
        </td>
      </tr>
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <Button size="small" variant="outlined" color="primary" onClick={()=>onSave()}>
            Save
          </Button>&nbsp;
          <Button size="small" variant="outlined" color="primary" onClick={()=>onCancel()} >
            Cancel
          </Button>
        </td>
      </tr>
    </table>
  </Paper>;
}

function AuditTemplate(props) {

  const [addType, setAddType] = useState(null);
  const [addNewItem, setAddNewItem] = useState(null)
  const [newItemDescription, setNewItemDescription] = useState("")
  const [editData, setEditData] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  function addNewItemFunc() {
    props.addItem({ type: addNewItem, parent: 0, value: newItemDescription, audit_id: props.auditId });
    setAddNewItem(null)
    setNewItemDescription("")
  }

  function onSave(){
    
    props.addItem(editData);
    setEditData(null)
  }

  function editItem(data){    
    console.log(editData)
    setEditData(data)
  }

  function cancelEdit(){
    setEditData(null)
  }

  function changeField(field,value){
    setEditData({
      ...editData,
      [field]:value
    })
    
  }

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleCreateGroup = (parent) => {
    props.addItem({parent_id:parent.id,template_id:props.auditId,rowType:"group",include_accounts:null,exclude_accounts:null,description:"New group",formula:"",var_name:null,annex_id:null,annex_type:null})
    //setAddNewItem("group")
    setAnchorEl(null);
  }

  const handleCreateFormula = (parent) => {
    props.addItem({parent_id:parent.id,template_id:props.auditId,rowType:"formula",include_accounts:null,exclude_accounts:null,description:null,formula:"",var_name:null,annex_id:null,annex_type:null})
    setAnchorEl(null);
  }

  const handleCreateAnnex = (parent) => {
    props.addItem({parent_id:parent.id,template_id:props.auditId,rowType:"annex",include_accounts:null,exclude_accounts:null,description:null,formula:"",var_name:null,annex_id:null,annex_type:null})
    setAnchorEl(null);
  }

  return <Grid container spacing={3} >
    <Grid item md={8} >
      <br/>
      <Button variant="outlined" size="small" onClick={()=>props.back()}>Back</Button>
      <br/><br/>
      <Paper className='widget-paper'>
        <Grid container spacing={3} >
          <Grid item md={12} >
            <Button onClick={() => setEditData({parent_id:0,template_id:props.auditId,rowType:"group",include_accounts:null,exclude_accounts:null,description:"New group",formula:"",var_name:null,annex_id:null,annex_type:null})} size="small" variant="outlined" color="primary" endIcon={<Add />}  >
              Add group
            </Button>&nbsp;
            <Button size="small" onClick={() => setEditData({parent_id:0,template_id:props.auditId,rowType:"formula",include_accounts:null,exclude_accounts:null,description:null,formula:"",var_name:null,annex_id:null,annex_type:null})} variant="outlined" color="primary" endIcon={<Add />}  >
              Add formula
            </Button>&nbsp;
            <Button size="small" onClick={() => setEditData({parent_id:0,template_id:props.auditId,rowType:"annex",include_accounts:null,exclude_accounts:null,description:null,formula:"",var_name:null,annex_id:null,annex_type:null})} variant="outlined" color="primary" endIcon={<Add />}  >
              Add annex
            </Button>
          </Grid>
          <Grid item md={12} >
          
          <div style={{height:800}}>
          
           
          <SortableTreeWithoutDndContext
            
            onChange={(treeData)=>props.setMainData(treeData)}
            treeData={props.data}
            generateNodeProps={rowInfo => ({
              className: rowInfo.node.rowType === "formula" ? "formula-node":null,
              buttons: [
                <IconButton size="small" onClick={() => editItem(rowInfo.node)} ><Edit fontSize={'10px'} /></IconButton>,
                <IconButton size="small" onClick={() => props.removeSubItem(rowInfo.node)} ><Delete fontSize={'10px'} /></IconButton>,
                rowInfo.node.rowType === "group" ?
              <Fragment>
              <IconButton size="small" onClick={handleClick} ><Add fontSize={'10px'} /></IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={()=>handleCreateGroup(rowInfo.node)} >Add group</MenuItem>
                <MenuItem onClick={()=>handleCreateFormula(rowInfo.node)} >Add formula</MenuItem>
                <MenuItem onClick={()=>handleCreateAnnex(rowInfo.node)} >Add annex</MenuItem>            
                <Divider />
                <MenuItem onClick={handleClose} >Cancel</MenuItem>
              </Menu>
            </Fragment>:null
              
              ]
            })
            }
            // onChange={treeData => this.setState({ treeData })}
          />
          
          
          </div>
          
          

        
           
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    <Grid item md={4} >
      { editData != null && editData.rowType === "group"  ? <EditGroup data={editData} onSave={onSave} onFieldChange={changeField} onCancel={cancelEdit} />:null}
      { editData != null && editData.rowType === "formula"  ? <EditFormula data={editData} onSave={onSave} onFieldChange={changeField} onCancel={cancelEdit} />:null}
      { editData != null && editData.rowType === "annex"  ? <EditAnnex list={props.annexes} data={editData} onSave={onSave} onFieldChange={changeField} onCancel={cancelEdit} />:null}
    </Grid>
  </Grid>



    ;
}



export default AuditTemplate;