import React, { Fragment } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import SelectType from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import makeStyles  from "@emotion/styled"
import InputLabel from "@mui/material/InputLabel"
import Select from "react-select"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Chip from "@mui/material/Chip"
import "../styles.css"

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    divData: {
        padding: "10px",
        textAlign: "left"
    },
    formControl: {
        width: "200px"
    },
    formControlWinBook: {
        width: "450px"
    },
    popMargin: {
        margin: "20px 0 20px 0"
    },
    typeSelect: {
        width: "140px"
    }
})

const getChipLabel = (option) => {
    if (option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}
const getSelectValue = (value, array) => {
    for (let x = 0; x < array.length; x++) {
        if (value === array[x].NUMBER) {
            return array[x]
        }
    }
}

const getExpenceArray = (dnaReport, selectedAccount, editingData) => {

    let expence = []
    if (dnaReport !== null) {
        expence = dnaReport
        if (selectedAccount !== undefined && selectedAccount.length !== 0) {
            for (let y = 0; y < expence.length; y++) {
                for (let x = 0; x < selectedAccount.length; x++) {
                    if (selectedAccount[x].account === expence[y].NUMBER && editingData !== expence[y].NUMBER) {
                        expence = [
                            ...expence.slice(0, y),
                            ...expence.slice(y + 1)
                        ]
                    }
                }
            }
        }

    }
    return expence
}

export default function AlertDialog(props) {
    const classes = useStyles()
    // const arrayList = getExpenceArray(props.dnaReport, props.sectionAllData.expenseSelectedGroupData.expenses, props.expenseDate.account)


    return (
        <div>
            <Dialog
                aria-labelledby="form-dialog-title"
                open={props.viewProps.contProps.reducerSalaires.updateData.show}
                onClose={props.viewProps.contProps.hideUpdatePopUp}
                className="editExpense"
            >
                <DialogTitle id="alert-dialog-title" className="reportPopHeading">Update Data</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.divData}>
                            <div className={classes.popMargin}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={props.viewProps.contProps.reducerSalaires.updateData.list_obj.bold === 1}
                                                onChange={(e) => props.viewProps.contProps.changeUpdateDataAdd(e.target.checked === true ? 1 : 0, "bold")}
                                                name="bold"
                                                color="primary"
                                            />
                                        }
                                        label="bold"
                                    />
                                </div>
                                <FormControl className={classes.typeSelect}>
                                    <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                                    <SelectType
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="type"
                                        label="Type"
                                        value={props.viewProps.contProps.reducerSalaires.updateData.list_obj.trsType}
                                        onChange={(e) => props.viewProps.contProps.changeUpdateDataAdd(e.target.value, "trsType")}
                                    >
                                        <MenuItem value={"winbook"} >Winbook Data</MenuItem>
                                        <MenuItem value={"formula"} >Formula</MenuItem>
                                        <MenuItem value={"manual"}  >Manual Figure</MenuItem>
                                    </SelectType>
                                </FormControl>
                            </div>

                            <div className={classes.popMargin} id="inputExp">
                                <TextField
                                    id="standard-password-input"
                                    placeholder="Description"
                                    onChange={(e) => props.viewProps.contProps.changeUpdateDataAdd(e.target.value, "description")}
                                    value={props.viewProps.contProps.reducerSalaires.updateData.list_obj.description}
                                    label="Description"
                                />
                            </div>

                            <div className={classes.popMargin} id="inputExp">
                                {props.viewProps.contProps.reducerSalaires.updateData.list_obj.trsType === "winbook" ?
                                    <div >
                                        <Select
                                            inputId="react-select-multiple"
                                            menuIsOpen={true}
                                            options={props.viewProps.contProps.dnaReport.accounts}
                                            placeholder="Source"
                                            label="Source"
                                            getOptionLabel={option => getChipLabel(option)}
                                            getOptionValue={option => `${option.NUMBER}`}
                                            onChange={(acc) => props.viewProps.contProps.changeUpdateDataAdd(acc.NUMBER, "source")}
                                            value={getSelectValue(props.viewProps.contProps.reducerSalaires.updateData.list_obj.source,props.viewProps.contProps.dnaReport.accounts)}
                                            isMulti={false}
                                        />
                                    </div> :
                                    null
                                }
                                {props.viewProps.contProps.reducerSalaires.updateData.list_obj.trsType === "formula" ?
                                    <TextField
                                        id="standard-password-input"
                                        className="textFieldSec"
                                        name="source"
                                        label="Source"
                                        placeholder="Source"
                                        onChange={(e) => props.viewProps.contProps.changeUpdateDataAdd(e.target.value, "source")}
                                        value={props.viewProps.contProps.reducerSalaires.updateData.list_obj.source}
                                    /> : null

                                }
                                {props.viewProps.contProps.reducerSalaires.updateData.list_obj.trsType === "manual" ?
                                    <TextField
                                        id="standard-password-input"
                                        className="textFieldSec"
                                        name="source"
                                        placeholder="Value"
                                        label="Value"
                                        onChange={(e) => props.viewProps.contProps.changeUpdateDataAdd(e.target.value, "value")}
                                        value={props.viewProps.contProps.reducerSalaires.updateData.list_obj.value}
                                    /> : null

                                }
                            </div>
                        </div>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.viewProps.contProps.hideUpdatePopUp} color="primary">
                        cancel
                    </Button>
                    <Button onClick={props.viewProps.saveUpdateData} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
