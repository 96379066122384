import React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
// import { fade } from "@mui/material/styles/colorManipulator"
// import { withStyles } from "@mui/material/styles"
// import { withRouter} from "react-router-dom"
import BackIcon from "@mui/icons-material/ArrowBack"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import GetAppIcon from "@mui/icons-material/GetApp"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import { compose } from "redux"
import { connect } from "react-redux"
import "./styles.css"
import {API_URL, xauth, company} from '../../../Constants';

// const styles = theme => ({
//   root: {
//     width: "100%",
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   dropFont: {
//     color: "white!important",
//     fontSize: "16px",
//     fontWeight: "bold"
//   },
//   dropFontSelect: {
//     color: "white",
//     fontSize: "16px",
//     fontWeight: "400"
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
//   title: {
//     [theme.breakpoints.up("sm")]: {
//       display: "block",
//     },
//   },
//   search: {
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: fade(theme.palette.common.white, 0.15),
//     "&:hover": {
//       backgroundColor: fade(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing.unit * 2,
//     marginLeft: 0,
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       marginLeft: theme.spacing.unit * 3,
//       width: "auto",
//     },
//   },
//   searchIcon: {
//     width: theme.spacing.unit * 9,
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   inputRoot: {
//     color: "inherit",
//     width: "100%",
//   },
//   inputInput: {
//     paddingTop: theme.spacing.unit,
//     paddingRight: theme.spacing.unit,
//     paddingBottom: theme.spacing.unit,
//     paddingLeft: theme.spacing.unit * 10,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: 200,
//     },
//   },
//   sectionDesktop: {
//     [theme.breakpoints.up("md")]: {
//       display: "flex",
//     },
//   },
//   sectionMobile: {
//     display: "flex"
//   },
// })

const mapStateToProps = (state) => {
  return {
    
  }
}

const mapDispatchToProps = dispatch => ({
    
})

class SubAppBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    anchorElSec:null,
    open:false
  }

  handleClick = (event) => {
    this.setState({
      anchorElSec:event.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorElSec:null
    })
  }


  goTo = (URL) =>{
      window.location.target="_blank"
      window.location.href=URL

      this.setState({
        anchorElSec:null
      })
  }
  

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state
    const { classes } = this.props
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    return (
      <div className={classes.root}>
        <AppBar position="static" id="mvr_header">
          <Toolbar>
            <IconButton onClick={this.props.backAction} className={classes.menuButton} color="inherit" aria-label="Open drawer">
              <BackIcon />
            </IconButton>
            <Typography className={classes.title} id="mvr_head_title" variant="h6" color="inherit" noWrap>
            {company} : {this.props.title}
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              
              {this.props.rightAction != null ? (<Button onClick={this.props.rightAction} color="inherit">               
                  
                  {this.props.rightActionTitle}              
              </Button>):null}

              {this.props.exportHandle != null ? (<Button onClick={this.props.exportHandle}  color="inherit">                
                  <GetAppIcon /> 
                  Export              
              </Button>):null}
              {this.props.dropDown === true ?
                <div>
                  {/* <Button className="exportClass" aria-controls="fade-menu" aria-haspopup="true" onClick={this.props.handleRepoSelect} >       
                    <AddIcon /> 
                    Import
                  </Button> */}
                  <Button className="exportClass" aria-controls="export-menu" id="mvr_export" aria-haspopup="true" onClick={this.handleClick}>
                    Export
                  </Button>
                    <Menu
                      id="export-menu"
                      anchorEl={this.state.anchorElSec}
                      keepMounted
                      open={this.state.anchorElSec !== null}
                      onClose={this.handleClose}
                    >
                      <MenuItem onClick={()=>this.goTo(API_URL+"/mvr/get?dl="+xauth+"&rid=" + this.props.repId + "&b=" + this.props.bk + "&p=" + this.props.per + "&export=pdf")} >PDF</MenuItem>
                      <MenuItem onClick={()=>this.goTo(API_URL+"/mvr/get?dl="+xauth+"&rid=" + this.props.repId + "&b=" + this.props.bk + "&p=" + this.props.per + "&export=excel")} >EXCEL</MenuItem>
                    </Menu> 
                  </div>
              : null}
              
            </div>
          </Toolbar>
        </AppBar>
        
      </div>
    )
  }
}

SubAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(connect(mapStateToProps, mapDispatchToProps),)((SubAppBar))
