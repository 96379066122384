import React, { Fragment } from "react"
// import { withStyles } from "@mui/material/styles"
import Button from "@mui/material/Button"

// import ReportHeader from "../../../Common/ReportHeader"
import Loader from "../../Layouts/Common/Loader"
import LoyerRow from "./loyerArrayRow"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import ReactSelect from "react-select"
import Chip from "@mui/material/Chip"
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Grid from "@mui/material/Grid"
// import DateFnsUtils from "@date-io/date-fns"
import "./styles.css"
// import "react-datepicker/dist/react-datepicker.css"

const styles = theme => ({
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    head: {
        fontSize: "20px",
        padding: "40px 0 0 25px",
        float: "left"
    },
    root: {
        marginTop: "30px",
        marginRight: "30px",
        float: "right"
    },
    lastChild: {
        position: "relative",
        paddingBottom: "33px"
    },
    table: {
        minWidth: 650,
    },
    tableAlign: {
        textAlign: "right"
    },
    totalCell: {
        fontWeight: "bold",
        textAlign: "right"
    }
})

const getTitle = (periods, selectedPeriod, books, selectedBook, list, selectedReportId) => {
    if (selectedPeriod === null || selectedBook === null || selectedReportId === null) {
        return false
    }
    else {
        return list.find((data) => { return data.id === selectedReportId }).name + "  " + books.find((data) => { return data.value === selectedBook }).label + " / " + periods.find((data) => { return data.value === selectedPeriod }).label
    }
}

const getChipLabel = (option) => {
    if (option.account_balance !== 0) {
        return <Fragment>{option.NUMBER} : {option.NAME11} <Chip label={option.accountBalanceFormatted} /></Fragment>
    }
    else {
        return <Fragment>{option.NUMBER} : {option.NAME11}</Fragment>
    }
}


const amountPrint = (formet, amount) => {
    if (amount === 0) {
        return "0,00"
    }
    else {
        return formet
    }
}

const getDate = () => {
    var date = new Date()
    var retDate = date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()
    return retDate
}

const View = function (props) {
    const { classes } = props

    return (
        <Fragment>
            {/* <ReportHeader 
                annexType="annex/loyers"
                backAction={props.handleGoBack}
                dropDown={true}
                title={getTitle(props.contProps.route.periods, props.contProps.route.selectedPeriod, props.contProps.route.books, props.contProps.route.selectedBook, props.contProps.route.list, props.contProps.reducerMvrList.selectedReport)}
                viewProps={props}
                creatAnnex={props.handleOpen}
                handleRepoSelect={props.handleRepoSelect}
                handleAccountIssue={props.handleAccountIssue}
                /> */}
            <Loader />
            <br/>
            <div className="select_data">
                
                <div className="loyers-drop-head" >Annexe des loyers :</div>
                <div id="annex_Des-table" style={{width:"400px"}}>
                    <ReactSelect
                        id="react_select"
                        inputId="react-select-multiple"
                        options={props.contProps.dnaReport}
                        getOptionLabel={option => getChipLabel(option)}
                        getOptionValue={option => `${option.NUMBER}`}
                        value={props.contProps.reducerLoyers.loyers !== null ? props.contProps.dnaReport.find((data) => { return data.NUMBER === props.contProps.reducerLoyers.loyers.account.accountNumber }) : "0"}
                        onChange={props.contProps.handleAccountChange}
                        isMulti={false}
                    />
                </div>
                <div className="loyers_balance">{props.contProps.reducerLoyers.loyers !== null && props.contProps.reducerLoyers.loyers.account.amount !== 0 ? props.contProps.reducerLoyers.loyers.account.amountFormatted + " euros" : ""}</div>
            </div>
            <br/><br/>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" id="table-pad">
                    <TableHead>
                        <TableRow>
                            <TableCell >Périodes</TableCell>
                            <TableCell align="right">Bénéficiaires</TableCell>
                            <TableCell align="right">Localisation du bien</TableCell>
                            <TableCell align="right">Montants</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.contProps.reducerLoyers.loyers != null && props.contProps.reducerLoyers.loyers.transactions.length !== 0 ? props.contProps.reducerLoyers.loyers.transactions.map((transactions, index) => (
                            <LoyerRow
                                key={transactions.id}
                                data={transactions}
                                index={index}
                                viewProps={props}
                            />
                        )) : null}
                        <TableRow >
                            <TableCell component="th" scope="row" id="cal-align">
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                                    <Grid container justify="space-around" className="dCreate-loyer">
                                        <input onChange={(e) => props.handleFromDateChange(e.target.value)} value={props.contProps.reducerLoyers.settingsData.fromPeriod} />
                                        
                                        <input  className="dateC-to" onChange={(e) => props.handleToDateChange(e.target.value)} value={props.contProps.reducerLoyers.settingsData.toPeriod} />
                                        {/* <KeyboardDatePicker
                                            value={props.contProps.reducerLoyers.settingsData.fromPeriod != null ? props.contProps.reducerLoyers.settingsData.fromPeriod : null}
                                            format="dd/MM/yyyy"
                                            onBlur={props.handleFromDateChange}
                                            className="datePicker"
                                            disableToolbar
                                            variant="inline"
                                            margin="normal"
                                            id="date-picker-inline"
                                            error={false}
                                        />
                                        <KeyboardDatePicker
                                            value={props.contProps.reducerLoyers.settingsData.toPeriod !== null ? props.contProps.reducerLoyers.settingsData.toPeriod : null}
                                            format="dd/MM/yyyy"
                                            onBlur={props.handleToDateChange}
                                            className="datePicker"
                                            disableToolbar
                                            minDate={props.contProps.reducerLoyers.settingsData.fromPeriod != null ? props.contProps.reducerLoyers.settingsData.fromPeriod : new Date()}
                                            variant="inline"
                                            margin="normal"
                                            id="date-picker-inline"
                                            error={false} 
                                        /> */}
                                    </Grid>
                                {/* </MuiPickersUtilsProvider> */}
                            </TableCell>
                            <TableCell id="cal-align-tw" align="right">
                                <textarea type="text" onChange={(e) => props.handleloyersSettings(e.target.value, "beneficiaryInfo")} value={props.contProps.reducerLoyers.settingsData.beneficiaryInfo} cols={30} />
                            </TableCell>
                            <TableCell id="cal-align-th" >
                                <textarea onChange={(e) => props.handleloyersSettings(e.target.value, "location")} value={props.contProps.reducerLoyers.settingsData.location} cols={30} />
                            </TableCell>
                            <TableCell id="cal-align-fr" className="mont-pad" >
                                <input onChange={(e) => props.handleloyersSettings(e.target.value, "amount")} value={props.contProps.reducerLoyers.settingsData.amount} />
                            </TableCell>
                            <TableCell id="cal-align-fv" >
                                <Button size="small" variant="text" color="primary" onClick={props.handleSendData} >
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell variant="footer" ><b>Total</b></TableCell>
                            <TableCell >{props.contProps.reducerLoyers.loyers != null ? amountPrint(props.contProps.reducerLoyers.loyers.account.totalFormatted, props.contProps.reducerLoyers.loyers.account.total) : 0}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>)
}

export default (View)
