import React, { Component } from "react"
import SortableTree from "@nosferatu500/react-sortable-tree"
// import BMSTheme from "react-sortable-tree-theme-bms"
import Button from "@mui/material/Button"
import "./styles.css"

const heigth=(treeData)=> {
    let heigth =0
    if(treeData === null)
    {return 100}
    else  {
        for (let q = 0; q < treeData.expense.length; q++) {
            heigth= heigth+1
            if (treeData.expense[q].children!==undefined) {
                for (let w = 0; w < treeData.expense[q].children.length; w++) {
                    heigth= heigth+1
                    if (treeData.expense[q].children[w].children!==undefined) {
                        for (let e = 0; e < treeData.expense[q].children[w].children; e++) {
                            heigth= heigth+1
                        }

                    }
                    
                }
            }
        }
        return heigth*30
    }
}

const handleChangeTree= (treeData)=> {
    let change = true
    for (let x = 0; x < treeData.length; x++) {
        if (treeData[x].children !== undefined) {
            if(treeData[x].trsType!=="group" && treeData[x].children.length>0) { change = false }
           for (let y = 0; y < treeData[x].children.length; y++) {
              if (treeData[x].children[y].children !== undefined) {
                   change = false
              }
           }
          }
      }
      return change
}

export default class Tree extends Component {
  constructor(props) {
    super(props)

    this.state = {
        treeData: [],
        heigth: heigth(this.props.viewData.data)
    }
  }


  childUpdate = () => {
    for (let x = 0; x < this.props.viewData.data.expense.length; x++) {
        let child = this.props.viewData.data.expense[x]
        if(child.children !== undefined ) {
            let arrayChild = []
            for (let m = 0; m < child.children.length; m++) {
                let secoundChild = child.children[m]
                this.state.treeData[x].children.push({id: secoundChild.id,trsType: secoundChild.trsType,title:secoundChild.description})
            }
        }
    }
  }


  updateArray() {
      if(this.state.treeData.length===0 && this.props.viewData.data !== null) {
          for (let x = 0; x < this.props.viewData.data.expense.length; x++) {
              let child = this.props.viewData.data.expense[x]
                this.state.treeData.push({
                    id: child.id,
                    trsType: child.trsType,
                    title:child.description,
                    [child.children!==undefined && child.children.length!==0?"expanded":""]:true,
                    [child.trsType=== "group" ? "children" : "" ]: []
                })
          }
          this.childUpdate()
      }
  }
    handleChange = (treeData) => {
        if (handleChangeTree(treeData)) { this.setState({ treeData }) } 
        this.props.viewData.contProps.storeArrangeExp(treeData)
    }


  render() {
    if(this.state.treeData.length===0 && this.props.viewData.data !== null) {
        this.updateArray(this.state.treeData)
    }
    return (this.state.treeData.length !==0 ?
      <div className="sortableTree" style={{fontSize:16, height: this.state.heigth}}>
            <SortableTree
            style={{paddingTop:10}}
            treeData={this.state.treeData}
            onChange={treeData => this.handleChange(treeData)}
            // theme={BMSTheme}
            scaffoldBlockPxWidth={16}
            /> 
      </div>: "" 
    )
  }
}