import { API_URL } from '../Constants';
import {startLoading, stopLoading} from './Common'

const xauth = window.xauth ? window.xauth : "MjA6OQ=="

export function storeReports(list) {
    return {
        type: "STORE_REPORTS",
        reports: list
    }
}

export function getReports() {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/report/get", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                dispatch(storeReports(json))
                dispatch(stopLoading())
            })
    }
}

export function createReport(reportName,reportDescription){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/create',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify({
                name:reportName,
                description:reportDescription
            }),
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());              
          });
    }
}



export function updateReport(reportData){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
            body:JSON.stringify(reportData),
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());              
          });
    }
}

export function deleteReport(reportID){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/delete/'+reportID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());              
          });
    }
}

export function duplicateReport(reportID){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/duplicate?rid='+reportID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(stopLoading());
              dispatch(getReports());              
          });
    }
}






export function selectReport(reportID){
    return{
        type:'SHOW_SETTINGS',
        report: reportID
    }
}

export function getReportSettings(reportID){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/get?rid='+reportID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(storeLoadedSettings(json));
              dispatch(stopLoading());
          });
    }
}

export function getReportData(reportID,book,fromP,toP){
    return function(dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/report/data?rid='+reportID+'&b='+book+'&f='+fromP+'&t='+toP,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              "X-Auth-Token": xauth
            },
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
              dispatch(storeLoadedData(json));
              dispatch(stopLoading());
          });
    }
}

export function storeLoadedSettings(data){
    return{
        type:'LOADED_SETTINGS',
        settings: data
    }
}

export function storeLoadedData(data){
    return{
        type:'LOADED_DATA',
        data: data
    }
}

export function resetSettings(){
    return function(dispatch){
        dispatch(selectReport(null));
        dispatch(storeLoadedSettings(null));
    }
}

export function updareReportBasicInfo(fieldName,value){
    return{
        type:'UPDATE_REPORT_BASIC',
        field: fieldName,
        value:value
    }
}

export function addColumn(cType,cOrder,rerpotID){
    return{
        type:'ADD_COLUMN',        
        column:{
                    col_type: cType,
                    column_order: cOrder,                    
                    first_period_id: null,
                    breakdown:0,
                    id: 0,
                    period: 1,
                    report_id: rerpotID,
                    sec_period_id: null,
                    difference_in:null,
                    book:null,
                    difference:0,
                    first_period:0,
                    sec_period:1,
                    difference_in:0,
                }
    }
}

export function removeColumn(colIndex){
    return{
        type:'REMOVE_COLUMN',        
        column:colIndex
    }
}

export function updateColumn(index,field,value){
    return{
        type:'UPDATE_COLUMN',              
        field:field,
        index:index,
        value:value
    }
}
export function moveColumn(startIndex, endIndex){
    return{
        type:'MOVE_COLUMN',              
        start:startIndex,
        end:endIndex,
    }
}

export function updateDetails(details){
    return{
        type:'UPDATE_DETAILS',              
        details:details
    }
}


export function setBook(book,from,to){
    return{
        type:'SET_BOOK',              
        book:book,
        from:from,
        to,to
    }
}

export function storeCompanies(list) {
    return {
        type: "STORE_REPORT_COMPANIES",
        companies: list
    }
}

export function getCompanies() {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/report/companies", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                dispatch(storeCompanies(json))
                dispatch(stopLoading())
            })
    }
}


export function importReports(companyId) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/report/import?cid=" + companyId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
            .then((json) => {
                dispatch(getReports())
                dispatch(stopLoading())
            })
    }
}