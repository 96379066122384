import { IconButton, Paper, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../vf.css';
import { Add, DeleteOutline } from "@mui/icons-material";



function Model6(props) {
  const [sumOfTrAmount, setSumOfTrAmount] = useState(0)

  const formatToEuropeanCurrency = (number) => {
    return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
  };

  const convertToEnglishFormat = (europeanNumber) => {
    if(typeof europeanNumber === 'undefined'){
      return "0.00";
    }
    // check if the . is available
    if (europeanNumber.includes(".")) {
      europeanNumber = europeanNumber.replace(".", "");
    }
    if (europeanNumber.includes(",")) {
      europeanNumber = europeanNumber.replace(",", ".");
    } else {
      europeanNumber = europeanNumber + ".00";
    }

    return europeanNumber;
  };

  useEffect(() => {
    // var total = 0;

    // if (props.data.length > 0) {
    //   total = props.data.reduce((total, transaction) => {
    //     var tr_amount = convertToEnglishFormat(transaction.tr_amount);
    //     tr_amount = parseFloat(tr_amount);
    //     console.log(tr_amount)
    //     return total + tr_amount;
    //   }, 0);
    // }
    // console.log(total);
    // total = isNaN(total) ? 0 : total;
    // setSumOfTrAmount(total);

  });

  function calcTotal(row){
      var initTotal = convertToEnglishFormat(row.tr_debts_init_total);
          initTotal = parseFloat(initTotal);
      var modification = convertToEnglishFormat(row.tr_modification);
          modification = parseFloat(modification);

      return  (initTotal+modification)
  }

  function calcTotalDeduction(row){
    var initTotal = convertToEnglishFormat(row.tr_reduction_init_total);
        initTotal = parseFloat(initTotal);
    var modification = convertToEnglishFormat(row.tr_augmentation);
        modification = parseFloat(modification);

    return  (initTotal+modification)
  }

  function calcVat(row){

    var initTotal = calcTotal(row);       
    var modification = calcTotalDeduction(row);        

    return  (initTotal-modification)
  }

  return <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={3} > 
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          <Button size="small" onClick={()=>props.onSave()} variant="outlined" color="primary"  >
                Save
          </Button>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>      
      <Grid item xs={12} md={12}>
        <table className="audit-model-1 model-table">
          <thead>
          <tr>
              <td class="date" colSpan={4}>&nbsp;</td>              
              <td class="amount" colSpan={3}>créances douteuses</td>
              <td class="amount" colSpan={4}>réductions de valeur</td>
              <td class="amount">&nbsp;</td>
              <td ></td>
            </tr>
            <tr>
              <td class="date">N° d'odre</td>
              <td class="amount" style={{ width: '7%' }}>type</td>
              <td class="libelle" style={{ width: '7%' }}>Nom débiteur</td>
              <td class="account-number" style={{ width: '12%' }}>motif</td>
              <td class="amount" style={{ width: '8%' }}>solde inital</td>
              <td class="amount">modification</td>
              <td class="amount">solde final</td>
              <td class="amount">solde initial</td>
              <td class="amount">réduction</td>
              <td class="amount">augmentation</td>
              <td class="amount">solde final</td>
              <td class="amount">tva</td>
              <td ></td>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr>
                <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_order_num",event.target.value)} value={item.tr_order_num} /></td>
                <td>
                  <select onChange={(event)=>props.changeFields(index,"tr_type",event.target.value)} value={item.tr_type}>
                    <option value={1} >Nouvelle</option>
                    <option value={2}>inchangée</option>
                    <option value={3}>augmentée</option>
                    <option value={4}>diminuée</option>
                  </select>
                </td>
                <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_debtor",event.target.value)} value={item.tr_debtor} /></td>
                <td>
                <input type="text" onChange={(event)=>props.changeFields(index,"tr_motif",event.target.value)} value={item.tr_motif} />
                </td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_debts_init_total",event.target.value)} value={item.tr_debts_init_total} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_modification",event.target.value)} value={item.tr_modification} /></td>
                <td><input type="text" className="amount-field" value={formatToEuropeanCurrency(calcTotal(item))} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_reduction_init_total",event.target.value)} value={item.tr_reduction_init_total} /></td>
                <td><input type="text" className="amount-field" /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_augmentation",event.target.value)} value={item.tr_augmentation}/></td>
                <td><input type="text" className="amount-field" value={formatToEuropeanCurrency(calcTotalDeduction(item))}/></td>
                <td><input type="text" className="amount-field"  value={formatToEuropeanCurrency(calcVat(item))}/></td>
                <td><IconButton onClick={()=>props.onMainRemove(index)} ><DeleteOutline/></IconButton></td>
              </tr>
            ))}
            <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              <td><IconButton onClick={()=>props.addMainRow()} ><Add/></IconButton></td>
            </tr>
          </tbody>
          <tfoot>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tfoot>          
        </table>
      </Grid>
    </Grid>
  </Paper>;
}

export default Model6