//export const API_URL ='http://dash.kavianlanka.com/laravel/api/public/api'
export const API_URL ='https://vf-api.kavianlanka.com/api/audit'
export const xauth = window.xauth ? window.xauth : "MjA6OQ=="
export const company = window.company ? window.company : "company"



// switch to live APIs, and use the key MjA6MzY=  MjA6NDY= MjA6NDAy
// MjA6Mg== MjA6NDY= MjA6MjY2 MjA6NDAy MjA6OQ== 

// 36 MjA6MzY=

// 9 MjA6OQ==


