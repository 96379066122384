import { API_URL, xauth } from "../../../Constants"

export function startLoading() {
    return {
        type: "LOADING_START"
    }
}

export function stopLoading() {
    return {
        type: "LOADING_STOP"
    }
}

export function storeReports(list) {
    return {
        type: "STORE_MV_REPORTS",
        reports: list
    }
}

export function showCreate(show) {
    return {
        type: "MV-SHOW-CREATE",
        show
    }
}

export function showImport(show) {
    return {
        type: "MV-SHOW-IMPORT",
        show
    }
}

export function selectReport(reportId) {
    return {
        type: "MV-SELECT-REPORT",
        reportId
    }
}

export function getReports() {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/get", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeReports(json))
                dispatch(stopLoading())
            })
    }
}

export function handleChangeStatusColor(id) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/status/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReports())
                dispatch(stopLoading())
            })
    }
}


export function handleChange(name, value) {
    return {
        type: "HANDLE_CHANGE_REPORT",
        name,
        value
    }
}

export function createReports(data) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReports())
                dispatch(stopLoading())
            })
    }
}

export function clearData() {
    return {
        type: "CLEAR_DATA"
    }
}


export function deleteReport(id) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/delete/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReports())
                dispatch(stopLoading())
            })
    }
}

export function handleRepoSelect(repSel) {
    return { type: "REPO_DATA_SELECT", repSel
    }
}

export function handleClearRepData() {
    return { type: "CLEAR_DATA_SELECT"
    }
}

export function handleRepoDataUpdate(name, value) {
    return { type: "REPO_DATA_ENTER", name, value }
}

export function handleRepoUpdate(data) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/mvr/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReports())
                dispatch(handleClearRepData())
                dispatch(stopLoading())
            })
    }
}

