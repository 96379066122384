import { IconButton, Paper, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../vf.css';
import { Add, DeleteOutline } from "@mui/icons-material";




function Model2(props) {

  const [sumOfTrAmount,setSumOfTrAmount] = useState(0)

  const formatToEuropeanCurrency = (number) => {
    if(isNaN(number)){

        return number;
    }
    return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(number).toString();
  };
  
  const convertToEnglishFormat = (europeanNumber) => {
    if(typeof europeanNumber === 'undefined'){
      return "0.00";
    }
    // check if the . is available
    if (europeanNumber.includes(".")) {
        europeanNumber = europeanNumber.replace(".", "");        
    }
    if (europeanNumber.includes(",")) {
      europeanNumber = europeanNumber.replace(",", ".");        
    }else{
      europeanNumber = europeanNumber+".00";
    } 

    return europeanNumber; 
  };
  
  useEffect(()=>{
    var total = 0; 
     
    if(props.data.length > 0){
      total = props.data.reduce((total, transaction) => {
        var tr_amount =  convertToEnglishFormat(transaction.tr_amount);
            tr_amount = parseFloat(tr_amount);
            console.log(tr_amount)     
        return total + tr_amount;
      }, 0);
    }
    console.log(total);  
    total =  isNaN(total) ? 0:total;
    setSumOfTrAmount(total);
    
  });

  return <Paper className='widget-paper add_top_margine_50px'>
    <Grid container spacing={3} >
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          <Button size="small" onClick={()=>props.onSave()} variant="outlined" color="primary"  >
                Save
          </Button>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
      <Grid item xs={12} md={12}>
        <h5>Constitution et augmentation</h5>
      </Grid>
      <Grid item xs={12} md={12}>
        <table className="audit-model-1">
          <thead>
            <tr>
              <td class="date" style={{ width: '7%' }}>Date</td>
              <td class="amount" style={{ width: '7%' }}>montant</td>
              <td class="libelle" style={{ width: '7%' }}>n° de suite</td>
              <td class="account-number" style={{ width: '12%' }}>Origine</td>
              <td class="amount" style={{ width: '8%' }}>Nbre d'actions</td>
              <td class="amount">commentaire</td>
              <td class="amount" style={{ width: '7%' }}>solde après opération</td>
              <td class="amount" style={{ width: '7%' }}>solde actions après opération</td>
              <td ></td>
            </tr>
          </thead>
          <tbody>
          {props.data instanceof Array ? props.data.map((item, index) => (
              <tr>
                <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_date",event.target.value)} value={item.tr_date}/></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeFields(index,"tr_amount",event.target.value)} value={item.tr_amount} /></td>
                <td style={{textAlign:"center"}}>{item.tr_number+1}</td>
                <td>
                  <select onChange={(event)=>props.changeFields(index,"tr_origin",event.target.value)} value={item.tr_origin} >
                    <option value={1}>Apport en espèces</option>
                    <option value={2}>Apport en espèces depuis le 1er juillet 2013 (pour PME)</option>
                    <option value={3}>Apport en nature</option>
                    <option value={4}>Incorporation des réserves</option>
                    <option value={5}>Incorporation d'une réserve de liquidation</option>
                    <option value={6}>Art 537 CIR</option>
                    <option value={7}>Incorporation de dettes</option>
                    <option value={8}>incorporation de réserves non taxées</option>
                    <option value={9}>iincorporation de réserves imposables</option>
                    <option value={10}>autre</option>
                  </select>
                </td>
                <td><input type="text" className="tire" onChange={(event)=>props.changeFields(index,"tr_num_actions",event.target.value)} value={item.tr_num_actions} /></td>
                <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_comments",event.target.value)} value={item.tr_comments} /></td>
                <td className="amount-field">{formatToEuropeanCurrency(item.tr_amount)}</td>
                <td className="amount-field"></td>
                <td><IconButton onClick={()=>props.onMainRemove(index)}><DeleteOutline /></IconButton></td>
              </tr>
            )) : null}
            
          </tbody>
          <tfoot>
          <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td className="amount-field">Solde</td>
              <td className="amount-field">{formatToEuropeanCurrency(sumOfTrAmount)}</td>
              <td className="amount-field">{formatToEuropeanCurrency(0)}</td>
              <td><IconButton onClick={()=>props.addMainRow()}><Add /></IconButton></td>
            </tr>
          </tfoot>
        </table>
      </Grid>
      <Grid item xs={12} md={12}>
        <h5>Réductions</h5>
      </Grid>
      <Grid item xs={12} md={12}>
        <table className="audit-model-1 model-table">
          <thead>
            <tr>
              <td class="date">Date</td>
              <td class="amount" style={{ width: '7%' }}>montant</td>
              <td class="libelle" style={{ width: '7%' }}>n° de suite</td>
              <td class="account-number" style={{ width: '12%' }}>Origine</td>
              <td class="amount" style={{ width: '8%' }}>Nbre d'actions</td>
              <td class="amount">commentaire</td>
              <td class="amount">montants soumis au précompte mobilier</td>
              <td class="amount">montant non soumis au précompte mobilier</td>
              <td class="amount">taux préc.mobilier</td>
              <td class="amount">total précompte mobilier</td>
              <td ></td>
            </tr>
          </thead>
          <tbody>
            {props.subData instanceof Array ? props.subData.map((item, index) => (
              <tr>
                <td><input type="text" onChange={(event)=>props.changeSubFields(index,"tr_date",event.target.value)} value={item.tr_date} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeSubFields(index,"tr_amount",event.target.value)} value={item.tr_amount} /></td>
                <td><select onChange={(event)=>props.changeSubFields(index,"tr_number",event.target.value)} value={item.tr_number} >
                {props.data instanceof Array ? props.data.map((item, index) => (
                    <option value={item.tr_number+1}>{item.tr_number+1}</option>
                  )):null}    
                   
                  </select></td>
                <td>
                <select onChange={(event)=>props.changeSubFields(index,"tr_origin",event.target.value)} value={item.tr_origin}>
                    <option value={1}>Apport en espèces</option>
                    <option value={2}>Apport en espèces depuis le 1er juillet 2013 (pour PME)</option>
                    <option value={3}>Apport en nature</option>
                    <option value={4}>Incorporation des réserves</option>
                    <option value={5}>Incorporation d'une réserve de liquidation</option>
                    <option value={6}>Art 537 CIR</option>
                    <option value={7}>Incorporation de dettes</option>
                    <option value={8}>incorporation de réserves non taxées</option>
                    <option value={9}>iincorporation de réserves imposables</option>
                    <option value={10}>autre</option>
                  </select>
                </td>
                <td><input type="text" className="tire" onChange={(event)=>props.changeSubFields(index,"tr_num_actions",event.target.value)} value={item.tr_num_actions} /></td>
                <td><input type="text" onChange={(event)=>props.changeSubFields(index,"tr_comments",event.target.value)} value={item.tr_comments} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeSubFields(index,"tr_wh_tax",event.target.value)} value={item.tr_wh_tax} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeSubFields(index,"tr_wh_not_tax",event.target.value)} value={item.tr_wh_not_tax} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeSubFields(index,"tx_fix_assets",event.target.value)} value={item.tx_fix_assets} /></td>
                <td><input type="text" className="amount-field" onChange={(event)=>props.changeSubFields(index,"tx_wh_tax_total",event.target.value)} value={item.tx_wh_tax_total}/></td>
                <td><IconButton onClick={()=>props.onSubRemove(index)}><DeleteOutline /></IconButton></td>
              </tr>
            )):null}

          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>

              <td><IconButton onClick={()=>props.addSubRow()}><Add /></IconButton></td>
            </tr>
          </tfoot>
        </table>
      </Grid>
    </Grid>





  </Paper>

    ;
}

export default Model2