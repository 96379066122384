import { Button, Grid, IconButton, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../vf.css';
import { Add, DeleteOutline } from "@mui/icons-material";




function Model1(props) {
  
  const [sumOfTrAmount,setSumOfTrAmount] = useState(0)

  const formatToEuropeanCurrency = (number) => {
    return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
  };
  
  const convertToEnglishFormat = (europeanNumber) => {
    if(typeof europeanNumber === 'undefined'){
      return "0.00";
    }
    // check if the . is available
    if (europeanNumber.includes(".")) {
        europeanNumber = europeanNumber.replace(".", "");        
    }
    if (europeanNumber.includes(",")) {
      europeanNumber = europeanNumber.replace(",", ".");        
    }else{
      europeanNumber = europeanNumber+".00";
    } 

    return europeanNumber; 
  };
  
  useEffect(()=>{
    var total = 0; 
     
    if(props.data.length > 0){
      total = props.data.reduce((total, transaction) => {
        var tr_amount =  convertToEnglishFormat(transaction.tr_amount);
            tr_amount = parseFloat(tr_amount);
            console.log(tr_amount)     
        return total + tr_amount;
      }, 0);
    }
    console.log(total);  
    total =  isNaN(total) ? 0:total;
    setSumOfTrAmount(total);
    
  });

  return <Paper className='widget-paper add_top_margine_50px'>
    
    <Grid container spacing={3} >
    <Grid container spacing={2} alignItems="center" style={{marginTop:"20px"}}>
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={7}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
                 
              
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
          <Button size="small" onClick={()=>props.onSave()} variant="outlined" color="primary"  >
                Save
          </Button>
            
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
    <Grid item xs={12} md={12}>
    <table className="audit-model-1 model-table">
      <thead>
        <tr key={'dat-m1-head'}>
          <td class="number" style={{width:"7%"}}>journal</td>
          <td class="date" style={{width:"8%"}}>Date</td>
          <td class="tiers">Tiers</td>
          <td class="libelle">Libellé</td>
          {/* <td class="account-number" >numéro de compte</td> */}
          <td class="amount" style={{width:"10%"}}>montant</td>
          <td ></td>
        </tr>
      </thead>
      <tbody>
        {props.data instanceof Array ? props.data.map((item, index) => (
        <tr key={'dat-m1-row'+index}>
          <td><input type="text" onChange={(event)=>props.changeFields(index,"number",event.target.value)} value={item.number} /></td>
          <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_date",event.target.value)} value={item.tr_date}/></td>
          <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_partner",event.target.value)} value={item.tr_partner}/></td>
          <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_ref",event.target.value)} value={item.tr_ref}/></td>
          {/* <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_account",event.target.value)} value={item.tr_account}/></td> */}
          <td><input type="text" onChange={(event)=>props.changeFields(index,"tr_amount",event.target.value)} value={item.tr_amount} className="amount-field" /></td>
          <td><IconButton onClick={()=>props.onMainRemove(index)} ><DeleteOutline/></IconButton></td>
        </tr>
        )) : null}
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          {/* <td>&nbsp;</td> */}
          <td>&nbsp;</td>
          <td><IconButton onClick={()=>props.addMainRow()} ><Add/></IconButton></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          {/* <td>&nbsp;</td> */}
          <td>Total</td>
          <td className="model-total">{formatToEuropeanCurrency(sumOfTrAmount)}</td>
        </tr>
      </tfoot>
    </table>
    </Grid>
    </Grid>
  </Paper>

    ;
}

export default Model1