import React, { PureComponent } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@mui/material/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "./view"
import { getReports, showCreate, showImport, selectReport, createReports, handleChange, clearData, deleteReport,
    handleClearRepData, handleRepoUpdate, handleRepoSelect, handleRepoDataUpdate, handleChangeStatusColor } from "./actions"

const mapStateToProps = (state) => ({
    loadedReports: state.reducerMvrList.list,
    userRole: state.Common.userRole,
    companies: state.Reports.companies,
    mvrList: state.reducerMvrList
})

const mapDispatchToProps = dispatch => ({
    getReports: () => dispatch(getReports()),
    handleClearRepData: () => dispatch(handleClearRepData()),
    clearData: () => dispatch(clearData()),
    showCreate: (show) => dispatch(showCreate(show)),
    showImport: (show) => dispatch(showImport(show)),
    selectReport: (reportId) => dispatch(selectReport(reportId)),
    createReports: (data) => dispatch(createReports(data)),
    handleChange: (name, value) => dispatch(handleChange(name, value)),
    handleRepoDataUpdate: (name, value) => dispatch(handleRepoDataUpdate(name, value)),
    deleteReport: (id) => dispatch(deleteReport(id)),
    handleRepoUpdate: (data) => dispatch(handleRepoUpdate(data)),
    handleRepoSelect: (data) => dispatch(handleRepoSelect(data)),
    handleChangeStatusColor: (id) => dispatch(handleChangeStatusColor(id))
})

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
})

class ListView extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.props.getReports()
        // this.props.getUserRole()
        // this.props.getBooks()
        // this.props.getCompanies()
    }

    handleClickOpen = () => {
        this.props.showCreate(true)
    };

    handleClickImportOpen = () => {
        this.props.showImport(true)
    }

    handleClose = () => {
        this.props.showCreate(false)
    }

    handleImportClose = () => {
        this.props.showImport(false)
    }

    handleImport = () => {
        if (this.state.importCompany !== null) {
            this.props.importReports(this.state.importCompany)
            this.handleImportClose()
        }
    }

    createReport = () => {
        this.props.createReport(this.state.newname, this.state.newdesciption)
        this.setState({
            open: false
        })
    }

    handleChange = name => event => {
        this.props.handleChange(name, event.target.value)
    }


    handleCompanyChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleReportSettings=(reportId) => {
        this.props.selectReport(reportId)
        this.props.history.push("mvr/settings")
    }

    createReports=() => {
        if (this.props.mvrList.newname === "") {
            alert("Name should be enter")
            return false
        }
        this.props.createReports({ name: this.props.mvrList.newname, description: this.props.mvrList.newdesciption })
        this.handleClose()
        this.props.clearData()
        return false
    }

    deleteReport=(id) => {
        this.props.deleteReport(id)
    }

    handleRepoSelect=(data) => {
        this.props.handleRepoSelect(data)
    }
    handleClearRepData=() => {
        this.props.handleClearRepData()
    }
    handleRepoDataUpdate=(event) => {
        this.props.handleRepoDataUpdate(event.target.name, event.target.value)
    }

    handleRepoUpdate=() => {
        this.props.handleRepoUpdate(this.props.mvrList.repo)
    }


    render() {
        return (
            <View
                handleClickOpen={this.handleClickOpen}
                handleClickImportOpen={this.handleClickImportOpen}
                handleReportSettings={this.handleReportSettings}
                reports={this.props.loadedReports}
                userRole={{ reportModify: true }}
                mvrList={this.props.mvrList}
                handleClose={this.handleClose}
                createReports={this.createReports}
                handleChange={this.handleChange}
                deleteReport={this.deleteReport}
                handleRepoSelect={this.handleRepoSelect}
                handleClearRepData={this.handleClearRepData}
                handleRepoDataUpdate={this.handleRepoDataUpdate}
                handleRepo={this.handleRepoUpdate}
                handleChangeStatusColor={this.props.handleChangeStatusColor}
            />)
    }
}

ListView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps), )(ListView)

