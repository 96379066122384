import React, { Fragment } from "react"
import TextField from "@mui/material/TextField"

export default function(props){

    const [value, setValue] = React.useState(props.value);

    function handleOnChange (e) {
        setValue(e.target.value)
        props.onChange(e)
    }
    
    if( props.ViewProps.contProps.section.inputChange && value!==props.value ) {
        props.ViewProps.contProps.inputChange(false)
        setValue(props.value)
    }
    else if(props.ViewProps.contProps.section.inputChange) {
        props.ViewProps.contProps.inputChange(false)
    }
    
    return  <TextField
                        id="standard-password-input"
                        name={props.name}
                        onChange={(e) => handleOnChange(e)}
                        value={value}
                        className="textValue"
                    />


}