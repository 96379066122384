
import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import HeaderNumberWidget from "./header-widget";

const formatToEuropeanCurrency = (number) => {
  return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
};

function AccountDetails(props) {

  const [showDetails, setShowDetails] = useState(false);


  return <Fragment>
                <Grid item xs={12} md={4}>
                    <div style={{padding:"15px"}}>
                    <HeaderNumberWidget lable="AVANT LES MODIFICATIONS" amount={formatToEuropeanCurrency(props.data.balance)} />
                    </div>
                    
                </Grid>
                
          <Paper className='widget-paper' style={{marginTop:"25px"}}>
    <Grid container spacing={2} alignItems="center">
      {/* Left Side - Icon and Title */}
      <Grid item xs={6} md={8}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6"> Grand livre</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side - Next and Previous Buttons */}
      <Grid item xs={6} md={4}>
        <Grid container justifyContent="flex-end" spacing={2}>

          <Grid item>
            <Button onClick={() => setShowDetails(!showDetails)} size="small">{showDetails ? "Hide Details" : "See Details"}</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>

          <div style={{ display: showDetails ? "inherit" : "none", width: "100%" }}>
            <table className="account-details" width={"100%"}>
              <thead>
                <tr>
                  <td style={{ width: "10%" }}>journal</td>
                  <td style={{ width: "7%" }}>Date</td>
                  <td style={{ width: "13%", textAlign: "left" }}>Tiers</td>
                  <td style={{ width: "20%", textAlign: "left" }}>Commentaires</td>
                  <td style={{ width: "7%" }}>Debit</td>
                  <td style={{ width: "7%" }}>Credit</td>
                  <td style={{ width: "7%" }}>Soldes</td>

                </tr>
              </thead>
              <tbody >
                {props.data.rows.map((item) => <tr >
                  <td style={{ textAlign: "center" }} >{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.date_formatted}</td>
                  <td style={{ textAlign: "left" }}>{item.partner}</td>
                  <td style={{ textAlign: "left" }}>{item.comments}</td>
                  <td style={{ textAlign: "right" }}>{item.debit}</td>
                  <td style={{ textAlign: "right" }}>{item.credit}</td>
                  <td style={{ textAlign: "right" }}>{item.balance}</td>
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ textAlign: "center" }} >&nbsp;</td>
                  <td style={{ textAlign: "center" }}>&nbsp;</td>
                  <td style={{ textAlign: "left" }}>&nbsp;</td>
                  <td style={{ textAlign: "left" }}>&nbsp;</td>
                  <td style={{ textAlign: "right" }}>&nbsp;</td>
                  <td style={{ textAlign: "right" }}>&nbsp;</td>
                  <td style={{ textAlign: "right" }}>{props.data.balance}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Grid>
      </Grid>
    </Grid>




  </Paper>
  </Fragment>;
}

export default AccountDetails