import React, { PureComponent } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@mui/material/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "./view"
import {
    saveArrangeAcc, handleUpdateDna, handleDnaData, handleRepoSelect, handleGroup,
    handleWinBookData, AddWinbookDataArray, createDnaAcoounts, handleWinBookDataEvent,
    addWinbookStore, clearDnaAccounts, changeUpdateData, clearUpdateData, saveUpdateData,
    handleGroupEvent, AddGroupTitle, resetSelectedReport, getReport, getAccounts,
    handleWinbookArrayEvent, storeArrangeAcc, popAccDelete, getVehicles, getColoumn
} from "./actions"
import { refresh } from "../../../Actions/Common"

const mapStateToProps = (state) => ({
    dnaReducer: state.dnaReducer,
    selectedReportId: state.reducerMvrList.selectedReport,
    route: state.PathRouterReducer,
    section: state.reducerMvrSettings,
    reducerMvrList: state.reducerMvrList,
    dnaReport: state.DNAReport.accounts
})

const mapDispatchToProps = dispatch => ({
    getReport: (reportId) => dispatch(getReport(reportId)),
    saveArrangeAcc: (data, auditId, templateId) => dispatch(saveArrangeAcc(data, auditId, templateId)),
    handleUpdateDna: (data) => dispatch(handleUpdateDna(data)),
    handleDnaData: (repoId, revGrpId, bookId, periodId) => dispatch(handleDnaData(repoId, revGrpId, bookId, periodId)),
    handleRepoSelect: (bool) => dispatch(handleRepoSelect(bool)),
    handleGroup: (data) => dispatch(handleGroup(data)),
    handleWinBookData: (data) => dispatch(handleWinBookData(data)),
    AddWinbookDataArray: (data) => dispatch(AddWinbookDataArray(data)),
    createDnaAcoounts: (data, id, auditId, templateId) => dispatch(createDnaAcoounts(data, id,auditId, templateId)),
    handleWinBookDataEvent: (name, value) => dispatch(handleWinBookDataEvent(name, value)),
    addWinbookStore: (data) => dispatch(addWinbookStore(data)),
    clearDnaAccounts: () => dispatch(clearDnaAccounts()),
    changeUpdateData: (value, name) => dispatch(changeUpdateData(value, name)),
    clearUpdateData: () => dispatch(clearUpdateData()),
    saveUpdateData: (data, auditId, templateId) => dispatch(saveUpdateData(data, auditId, templateId)),
    handleGroupEvent: (name, value) => dispatch(handleGroupEvent(name, value)),
    resetSelectedReport: () => dispatch(resetSelectedReport()),
    getAccounts: (auditId) => dispatch(getAccounts(auditId)),
    handleWinbookArrayEvent: (index, name, value) => dispatch(handleWinbookArrayEvent(index, name, value)),
    AddGroupTitle: (data, auditId, templateId) => dispatch(AddGroupTitle(data, auditId, templateId)),
    storeArrangeAcc: (data) => dispatch(storeArrangeAcc(data)),
    popAccDelete: (index) => dispatch(popAccDelete(index)),
    refresh: () => dispatch(refresh()),
    getVehicles: (bk, per) => dispatch(getVehicles(bk, per)),
    getColoumn: (value, bk, per, bool) => dispatch(getColoumn(value, bk, per, bool))

})

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
})

class SettingsView extends PureComponent {

    constructor(props) {

        super(props)

        this.state = {

        }
        this.updateTimer = null;
    }

    componentDidMount = () => {
        
        this.props.getReport(this.props.aid)
        this.props.getAccounts(this.props.aid)
        //this.props.getVehicles(this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    componentDidUpdate = () => {
        if (this.props.section.expensesGroupUpdated) {

            let data = this.props.section.report.expenses_groups[this.props.section.expensesUpdatedGroupIndex].expenses[this.props.section.expensesUpdatedRowIndex]

            this.props.singleChangeUpdate(data, this.props.selectedReportId, this.props.route.selectedBook, this.props.route.selectedPeriod);

        }
    }

    handleEditAraay = (aId) => {
        this.props.getAccounts(this.props.aid)
    }

    handleDnaData = (revGrpId) => {
        this.props.handleDnaData(this.props.selectedReportId, revGrpId, this.props.route.selectedBook, this.props.route.selectedPeriod)
    }

    handleGoBack = () => {
        this.props.resetSelectedReport()
       // this.props.history.goBack()
    }

    addwinBookArraySet = () => {
        for (let x = 0; x < this.props.dnaReducer.winbookStore.length; x++) {
            this.props.AddWinbookDataArray({ id: 0, dna: this.props.dnaReducer.winbook_data.dna, parentId: this.props.dnaReducer.winbook_data.parentId, show: false, trsType: "winbook", description: "", bold: this.props.dnaReducer.winbook_data.bold, source: this.props.dnaReducer.winbookStore[x].NUMBER, value: 0 })
        }
    }

    createDnaAcoounts = () => {
        this.props.createDnaAcoounts(this.props.dnaReducer.winbook_data_array, this.props.selectedReportId, this.props.aid,this.props.model.template_id)
    }


    AddGroupTitle = () => {
        this.props.AddGroupTitle(this.props.dnaReducer.mainTitle, this.props.aid,this.props.model.template_id)
    }

    saveUpdateData = () => {
        this.props.saveUpdateData(this.props.dnaReducer.winbook_data_update, this.props.aid,this.props.model.template_id)
    }
    vehicleChange = (value) => {
        this.props.getColoumn(value, this.props.route.selectedBook, this.props.route.selectedPeriod, 0)
        this.props.handleWinBookDataEvent("vehicle", value)
        this.props.handleWinBookDataEvent("dna", "")
        this.props.handleWinBookDataEvent("source", null)
        this.props.handleWinBookDataEvent("description", "")
        this.props.handleWinBookDataEvent("value", 0)
    }
    handleColumn = (value) => {
        var column = this.props.dnaReducer.vehCol.find((col) => { return col.id === value })
        var dna = this.props.dnaReducer.winbook_data.dna

        this.props.handleWinBookDataEvent("source", value)
        this.props.handleWinBookDataEvent("description", column.label)
        this.props.handleWinBookDataEvent("value", column.amountFormatted)
        if (dna === null || dna === "") {
            this.props.handleWinBookDataEvent("dna", column.dnaFormatted)
        }
    }
    popVehChange = (value, index) => {
        this.props.handleWinbookArrayEvent(index, "vehicle", value)
        this.props.handleWinBookDataEvent(index, "dna", "")
        this.props.handleWinBookDataEvent(index, "description", "")
        this.props.handleWinBookDataEvent(index, "value", 0)
        this.props.getColoumn(value, this.props.route.selectedBook, this.props.route.selectedPeriod, 1)
    }
    handleRowColumn = (index, value) => {
        var column = this.props.dnaReducer.rowVehCol.find((col) => { return col.id === value })
        var dna = this.props.dnaReducer.winbook_data_array[index].dna

        this.props.handleWinbookArrayEvent(index, "source", value)
        this.props.handleWinbookArrayEvent(index, "description", column.label)
        this.props.handleWinbookArrayEvent(index, "value", column.amountFormatted)
        if (dna === null || dna === "") {
            this.props.handleWinBookDataEvent(index, "dna", column.dnaFormatted)
        }
    }
    vehicleChangeUpdate = (value) => {
        this.props.getColoumn(value, this.props.route.selectedBook, this.props.route.selectedPeriod, 0)
        this.props.changeUpdateData(value, "vehicle")
        this.props.changeUpdateData("", "dna")
        this.props.changeUpdateData("", "source")
        this.props.changeUpdateData("", "description")
        this.props.changeUpdateData(0, "value")
    }
    handleColumnUpdate = (value) => {
        var column = this.props.dnaReducer.vehCol.find((col) => { return col.id === value })
        var dna = this.props.dnaReducer.winbook_data_update.dna

        this.props.changeUpdateData(value, "source")
        this.props.changeUpdateData(column.label, "description")
        this.props.changeUpdateData(column.amountFormatted, "value")
        if (dna === null || dna === "") {
            this.props.changeUpdateData(column.dnaFormatted, "dna")
        }
    }
    render() {
        return (
            <View
                contProps={this.props}
                handleEditAraay={this.handleEditAraay}
                handleDnaData={this.handleDnaData}
                handleGoBack={this.handleGoBack}
                handleRepoSelect={this.handleRepoSelect}
                sectionAllData={this.props.section}
                expenseDate={this.props.section.expense}
                addwinBookArraySet={this.addwinBookArraySet}
                createDnaAcoounts={this.createDnaAcoounts}
                AddGroupTitle={this.AddGroupTitle}
                data={this.props.section.report}
                saveUpdateData={this.saveUpdateData}
                vehicleChange={this.vehicleChange}
                handleColumn={this.handleColumn}
                popVehChange={this.popVehChange}
                handleRowColumn={this.handleRowColumn}
                vehicleChangeUpdate={this.vehicleChangeUpdate}
                handleColumnUpdate={this.handleColumnUpdate}
                aid={this.props.aid}
                template_id={this.props.model.template_id}
            />
        )
    }
}

SettingsView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps), )(SettingsView)
