import { Alert, Box, Container, Dialog, DialogContent, Grid, Paper, Snackbar, Tab, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tabs, TextField } from "@mui/material";

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import React, { useEffect, useState } from "react";
import Model1 from "./model-1";
import '../vf.css';

import SidePanel from "./side-panel";
import AccountHeaderBar from "./account-header-bar";
import AccountDetails from "./account-details";
import { API_URL } from "../config";
import AccountActions from "./account-actions";
import Model2 from "./model-2";
import Model3 from "./model-3";
import Model4 from "./model-4";
import Model5 from "./model-5";
import Model6 from "./model-6";
import Model7 from "./model-7";
import { Add, AddOutlined, Check, CheckCircle, CheckCircleOutline, Delete, DeleteOutline, Edit } from "@mui/icons-material";
import AuditTemplate from "./audit-template";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalSettings(props) {

  const [data, setMainData] = useState(null);
  const [layouts, setLayoutData] = useState(null);
  const [modelData, setModelData] = useState([]);
  const [modelSubData, setModelSubData] = useState([]);
  const [totals, setTotals] = useState({
    model_1_total: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null)
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState("");

  const [currentTab, setCurrentTab] = useState(0);
  const [templateview,setTemplateView] = useState(false);
  const [selecteTemplate,setSelectedTemplate] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);

    if (newValue == 1) {
      console.log(newValue);
      loadModelSettings();
    }

    if (newValue == 0) {      
      loadStructureSettings();
    }
  };

  function updateMainData(data){
    updateStructure({data:data});
    //setMainData({data:data})
  }

  function loadLayouts() {
    setIsLoading(true)

    fetch(API_URL + "/audit/get_layouts")
      .then((response) => response.json())
      .then((response) => {
        setLayoutData(response);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function loadStructureSettings(selecteTemplate) {
    setIsLoading(true)

    fetch(API_URL + "/audit/get_structure?id="+selecteTemplate)
      .then((response) => response.json())
      .then((response) => {
        setMainData(response);
        setTemplateView(true)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }


  function loadModelSettings(templateId) {
    setIsLoading(true)

    fetch(API_URL + "/audit/model/settings")
      .then((response) => response.json())
      .then((response) => {
        setMainData(response);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  

 

  function updateModelSetting(data) {

    if (description === null) {
      return false;
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/model/update", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadModelSettings()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function updateLayout(data) {

    if (description === null) {
      return false;
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/update_layout", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadLayouts()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function updateModelSetting(data) {

    if (description === null) {
      return false;
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/model/update", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadModelSettings()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function addChecklistItem() {

    if (description === null) {
      return false;
    }

    setIsLoading(true)

    fetch(API_URL + "/audit/checklist-item?audit_id=" + props.auditId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ description: description, account: account })
    })
      .then((response) => response.json())
      .then((response) => {
        //oadModelData(data);s
        setAccount("");
        setDescription("");
        //loadMainData(props.auditId)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }


  if (props.open === true && layouts === null) {

    if (isLoading === false) {
      if(currentTab == 0){
        loadLayouts()
        //loadStructureSettings()
      }
      
    }

  }




  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function closeView() {
    setMainData(null)
    props.handleClose()
  }


  const updateStructure = (data) => {   

    fetch(API_URL + "/audit/update_structure", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadStructureSettings()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
    
  };


  function addSubItem(data){
    

    fetch(API_URL + "/audit/add_to_structure", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadStructureSettings(data.template_id)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function updatedSubItem(data){
    

    fetch(API_URL + "/audit/update_report_item", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadStructureSettings()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function removeSubItem(data){    

    fetch(API_URL + "/audit/remove_report_item", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        loadStructureSettings(data.template_id)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
        // Display a user-friendly error message
        setError(error)
      });
  }

  function selectTemplate(templateId){
    setSelectedTemplate(templateId);
    loadStructureSettings(templateId);
  }

  function hideTemplate(){
    setSelectedTemplate(0);
    setTemplateView(false);
  }

  return <Dialog
    fullScreen
    open={props.open}
    onClose={closeView}
    PaperProps={{
      sx: {
        backgroundColor: 'rgb(252 252 252)', // Set dialog paper color here
      },
    }}

  >
    <AppBar className="template-app-bar" sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

      </Toolbar>
    </AppBar>

    <Typography id="modal-modal-title" variant="h6" component="h2">

    </Typography>
    <DialogContent>
      <Container maxWidth="lg">

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Templates" {...a11yProps(0)} />
              <Tab label="Account models" {...a11yProps(1)} />
            </Tabs>
          </Box>

        </Box>

        {}

        {isLoading === false && currentTab === 0 && data && templateview ? <AuditTemplate back={hideTemplate}  addItem={addSubItem} removeSubItem={removeSubItem} updatedSubItem={updatedSubItem} setMainData={updateMainData} annexes={data.annexes} data={data.data} auditId={selecteTemplate}
                          />  : null} 
        {isLoading === false && currentTab === 1 ? <ModelSettings data={data} modelupdate={updateModelSetting} /> : null}

        {isLoading === false && currentTab === 0 && layouts && templateview === false ? <LayoutList data={layouts} modelupdate={updateLayout} select={selectTemplate} /> : null}
        
      </Container>



      <Snackbar open={isLoading} autoHideDuration={6000} >
        <Alert

          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Loading...
        </Alert>
      </Snackbar>
    </DialogContent>
  </Dialog>;
}

function LayoutList(props) {

  const [accountGroup, setAccountGroup] = useState(null);
  const [accountTemplate, setAccountTempalte] = useState(null);

  function addRow() {
    props.modelupdate({ template_name: "New layout", id: 0})    
  }

  return <Paper className='widget-paper add_top_margine_50px'>

    <Table aria-label="collapsible table" className="audit-table" style={{ fontSize: "11px" }}>
      <TableHead>               
        <TableCell width={"10%"}></TableCell>
        <TableCell width={"80%"}>Layout name </TableCell>        
        <TableCell width={"10%"}><Button variant="outlined" size="small" onClick={()=>addRow()}>Create</Button></TableCell>
      </TableHead>
      <TableBody>
        {props.data != null ? props.data.map((item, index) => <LayoutRow data={item} index={index} update={props.modelupdate} select={props.select} />) : null}

      </TableBody>            
    </Table>

  </Paper>;
}

function LayoutRow(props) {
  const [mode, setMode] = useState(false);
  const [accountGroup, setAccountGroup] = useState(null);
  const [accountTemplate, setAccountTempalte] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [editData, setEditData] = useState(null);

  function updateRow() {
    props.update(editData)
    setEditData(null)
  }

  return editData != null ? <TableRow>
    <TableCell>{props.data.model_name}</TableCell>    
    <TableCell>
            <input type="text" style={{width:"100%"}} value={editData.template_name} onChange={(event) => setEditData({...editData,template_name:event.target.value})} />
    </TableCell>
    <TableCell>&nbsp;</TableCell>
    <TableCell>&nbsp;</TableCell>
    <TableCell>
      <IconButton size="small" onClick={() => updateRow()}>
        <Check />
      </IconButton>      
    </TableCell>
  </TableRow> : <TableRow>
    <TableCell></TableCell>    
    <TableCell><Button variant="text" size="small" onClick={()=>props.select(props.data.id)}>{props.data.template_name}</Button></TableCell>    
    <TableCell>
      <IconButton size="small" onClick={() => {
        setEditData(props.data)
      }}>
        <Edit />
      </IconButton>      
    </TableCell>
  </TableRow>;

}


function ModelSettings(props) {

  const [accountGroup, setAccountGroup] = useState(null);
  const [accountTemplate, setAccountTempalte] = useState(null);

  function addRow() {
    props.modelupdate({ account_group: accountGroup, template: accountTemplate, id: 0, remove: false })
    setAccountGroup("");
    setAccountTempalte(null);
  }

  return <Paper className='widget-paper add_top_margine_50px'>

    <Table aria-label="collapsible table" className="audit-table" style={{ fontSize: "11px" }}>
      <TableHead>               
        <TableCell width={"10%"}>Model</TableCell>
        <TableCell width={"80%"}>Accounts</TableCell>        
        <TableCell width={"10%"}>&nbsp;</TableCell>
      </TableHead>
      <TableBody>
        {props.data != null ? props.data.map((item, index) => <TemplateModelRow data={item} index={index} update={props.modelupdate} />) : null}

      </TableBody>            
    </Table>

  </Paper>;
}

function TemplateModelRow(props) {
  const [mode, setMode] = useState(false);
  const [accountGroup, setAccountGroup] = useState(null);
  const [accountTemplate, setAccountTempalte] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [editData, setEditData] = useState(null);

  function updateRow() {
    props.update(editData)
    setEditData(null)
  }

  return editData != null ? <TableRow>
    <TableCell>{props.data.model_name}</TableCell>    
    <TableCell>
            <input type="text" style={{width:"100%"}} value={editData.accounts} onChange={(event) => setEditData({...editData,accounts:event.target.value})} />
    </TableCell>
    <TableCell>&nbsp;</TableCell>
    <TableCell>&nbsp;</TableCell>
    <TableCell>
      <IconButton size="small" onClick={() => updateRow()}>
        <Check />
      </IconButton>      
    </TableCell>
  </TableRow> : <TableRow>
    <TableCell>{props.data.model_name}</TableCell>    
    <TableCell>{props.data.accounts}</TableCell>    
    <TableCell>
      <IconButton size="small" onClick={() => {
        setEditData(props.data)
      }}>
        <Edit />
      </IconButton>      
    </TableCell>
  </TableRow>;

}

export default ModalSettings