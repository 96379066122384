import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem" 
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"

const ImportReports = function(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Import Reports</DialogTitle>
            <DialogContent>
                <div style={{width: 300, height: 200}}>
                    <FormControl style={{width:'100%'}} >
                        <InputLabel id="demo-simple-select-label">Import from</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            selected={props.selected}
                            name="importCompany"
                            value={props.selected}
                            onChange={props.handleChange}
                        >
                            {props.companies.map((item)=> <MenuItem value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.handleImport} color="primary">
                    Import
                </Button>
            </DialogActions>
        </Dialog>)
}

export default ImportReports
