import React, { PureComponent } from "react"
import PropTypes from "prop-types"
// import { withStyles } from "@mui/material/styles"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "./view"
import { setBook } from "../../../Actions/Reporting"
import { getCompanies } from "../../../Actions/Dashboard"
import {
    getAccounts, handleloyersSettings, handleSendData, handleEditData,
    handleAccountChange, getLoyers, updateLoyers, updateStart, handleDeleteLoyers
} from "./actions"
import { refresh } from "../../../Actions/Common"

const mapStateToProps = (state) => ({
    reducerLoyers: state.reducerLoyers,
    dnaReport: state.DNAReport.accounts,
    route: state.PathRouterReducer,
    selectedReportId: state.reducerMvrList.selectedReport,
    selectedBook: state.reducerMvrSettings.selectedBook,
    selectedPeriod: state.reducerMvrSettings.selectedPeriod,
    reducerMvrList: state.reducerMvrList,
})

const mapDispatchToProps = dispatch => ({
    refresh: () => dispatch(refresh()),
    handleDeleteLoyers: (index) => dispatch(handleDeleteLoyers(index)),
    handleAccountChange: (event) => dispatch(handleAccountChange(event)),
    getCompanies: () => dispatch(getCompanies()),
    getAccounts: (auditId) => dispatch(getAccounts(auditId)),
    updateLoyers: (data, auditId,templateId) => dispatch(updateLoyers(data, auditId,templateId)),
    setBook: (book, from, to) => { dispatch(setBook(book, from, to)) },
    handleloyersSettings: (value, name) => dispatch(handleloyersSettings(value, name)),
    handleSendData: (data) => dispatch(handleSendData(data)),
    handleEditData: (event, index) => dispatch(handleEditData(event, index)),
    updateStart: (bool) => dispatch(updateStart(bool)),
    getLoyers: (templateId,auditId) => dispatch(getLoyers(templateId,auditId)),
})

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
})


class LoyersView extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            reportName: "",
            reportDescription: ""
        }

        this.updateTimer = null
    }

    handleDateChange = (date) => {
        let dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        this.props.handleDateChange(dateFormat)
    }

    componentDidMount = () => {
        //this.props.getCompanies()

        this.props.getAccounts(this.props.aid)
        this.props.getLoyers(this.props.model.template_id,this.props.aid)

        
    }

    componentDidUpdate = () => {
        if (this.props.reducerLoyers.updated === true) {
            this.props.updateLoyers(this.props.reducerLoyers.loyers, this.props.aid,this.props.model.template_id)
        }
    }

    handleGoBack = () => {
        //this.props.history.goBack()
    }

    handleloyersSettings = (value, name) => {
        this.props.handleloyersSettings(value, name)
    }

    handleSendData = () => {
        
        if(this.props.reducerLoyers.settingsData.fromPeriod==="NaN-NaN-NaN"||this.props.reducerLoyers.settingsData.toPeriod==="NaN-NaN-NaN") {
            return false
        }
        else if (this.props.reducerLoyers.settingsData.fromPeriod===""||this.props.reducerLoyers.settingsData.toPeriod==="") {
            return false
        }
        this.props.handleSendData(this.props.reducerLoyers.settingsData)
    }

    
    handleFromDateChange = (date) => {
        this.props.handleloyersSettings(date, "fromPeriod")
        this.props.handleloyersSettings(date, "fromPeriodFormatted")
        // if (date !== null) {
        //     let dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        //     let fromPeriodFormatted = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear()
        //     this.props.handleloyersSettings(dateFormat, "fromPeriod")
        //     this.props.handleloyersSettings(fromPeriodFormatted, "fromPeriodFormatted")
        // }
        // else {
        //     this.props.handleloyersSettings(null, "fromPeriod")
        //     this.props.handleloyersSettings(null, "fromPeriodFormatted")
        // }
    }
    
    handleToDateChange = (date) => {
        this.props.handleloyersSettings(date, "toPeriod")
        this.props.handleloyersSettings(date, "toPeriodFormatted")
        // if(date !== null ) {
        //     let dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        //     let toPeriodFormatted = date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear()
        //     this.props.handleloyersSettings(dateFormat, "toPeriod")
        //     this.props.handleloyersSettings(toPeriodFormatted, "toPeriodFormatted")
        //     }
        //     else {
        //         this.props.handleloyersSettings(null, "toPeriod")
        //         this.props.handleloyersSettings(null, "toPeriodFormatted")
        //     }

        }

    
    handleRowFromDate = (date,index) => {
        // let dateFormat = null
        // if(date !== null) {
        //      dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        // }
        this.props.handleEditData({ target: { name: "fromPeriod", value: date } }, index)
    }

    handleRowToDate = (date,index) => {
        // let dateFormat = null
        // if(date !== null) {
        //      dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        // }
        this.props.handleEditData({ target: { name: "toPeriod", value: date } }, index)
    }

    render() {
        return (
            <View
                contProps={this.props}
                handleGoBack={this.handleGoBack}
                handleloyersSettings={this.handleloyersSettings}
                handleSendData={this.handleSendData}
                handleFromDateChange={this.handleFromDateChange}
                handleToDateChange={this.handleToDateChange}
                handleRowFromDate={this.handleRowFromDate}
                handleRowToDate={this.handleRowToDate}
            />
        )
    }
}

LoyersView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps), )(LoyersView)
