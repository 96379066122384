import { functions } from "lodash"
import { func } from "prop-types"
import { API_URL, xauth } from "../../../Constants"

export function startLoading() {
    return { type: "LOADING_START" }
}

export function stopLoading() {
    return { type: "LOADING_STOP" }
}
export function apiSlow(num) {
    return { type: "API_GETTING_DELAY", num }
}

export function storeReport(report) {
    return { type: "STORE_DNA_REPORT", report }
}

export function getReport(reportdID) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(apiSlow(1))
        fetch(API_URL + "/dna/get?rid=" + reportdID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeReport(json))
                dispatch(apiSlow(0))
                dispatch(stopLoading())
            })
    }
}

export function storeAccounts(list) {
    return {
        type: "STORE_DNA_ACCOUNTS",
        accounts: list
    }
}

export function getAccounts(auditId) {
    return function (dispatch) {
        
        dispatch(startLoading())
        fetch(API_URL + "/dna/get/accounts?auditid=" + auditId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeAccounts(json))
                dispatch(stopLoading())
            })
    }
}

export function saveArrangeAcc(data, auditId, templateId) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/dna/" + templateId + "/row/organize", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({ data })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(auditId))
                dispatch(getAccounts(auditId))
                dispatch(stopLoading())
            })
    }
}

export function handleUpdateDna(data) {
    return { type: "HANDLE_UPDATE_DNA", data }
}

export function handleDnaData(repoId, revGrpId, bookId, periodId) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/dna/" + repoId + "/row/delete/" + revGrpId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(repoId, bookId, periodId))
                dispatch(getAccounts(bookId, periodId, repoId))
                dispatch(stopLoading())
            })
    }
}

export function handleRepoSelect(bool) {
    return { type: "HANDLE_BOOL_POP", bool }
}

export function handleGroup(data) {
    return { type: "STORE_MAIN_TITLE_DNA", data }
}

export function handleWinBookData(data) {
    return { type: "HANDLE_WINBOOK_DATA", data }
}

export function AddWinbookDataArray(data) {
    return { type: "ADD_WINBOOK_DATA_ARRAY", data }
}

export function clearDnaAccounts() {
    return { type: "CLEAR_DNA_ACCOUNTS" }
}

export function createDnaAcoounts(expencedata, id, auditId, templateId) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(clearDnaAccounts())
        fetch(API_URL + "/dna/" + templateId + "/row/create/accounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify({ data: expencedata })
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(auditId))
                dispatch(getAccounts(auditId))
                dispatch(stopLoading())
            })
    }
}

export function handleWinBookDataEvent(name, value) {
    return { type: "HANDLE_EVENT_WINBOOK_DATA", name, value }
}

export function addWinbookStore(data) {
    return { type: "ADD_WINBOOK_DATA_STORE", data }
}

export function changeUpdateData(value, name) {
    if (name === "trsType") {
        return { type: "CHANGE_UPDATE_DATA_TRS", value, name }

    }
    else {
        return { type: "CHANGE_UPDATE_DATA", value, name }
    }
}

export function clearUpdateData() {
    return { type: "CLEAR_UPDATE_DATA" }
}

export function saveUpdateData(expencedata, auditId, templateId) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(clearUpdateData())
        fetch(API_URL + "/dna/" + templateId + "/row/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(expencedata)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(auditId))
                dispatch(getAccounts(auditId))
                dispatch(stopLoading())
            })
    }
}

export function handleGroupEvent(name, value) {
    return { type: "HANDLE_GROUP_EVENT", name, value }
}

export function AddGroupTitle(data, auditId, templateId) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(handleGroup({ trsType: "", id: 0, bold: 1, description: "", show: false }))
        fetch(API_URL + "/dna/" + templateId + "/row/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getReport(auditId))
                dispatch(getAccounts(auditId))
                dispatch(stopLoading())
            })
    }
}

export function resetSelectedReport() {
    return function (dispatch) {
        dispatch(storeReport(null))
    }
}

export function handleWinbookArrayEvent(index, name, value) {
    return { type: "HANDLE_WINBOOK_ARRAY_EVENT", index, name, value }
}

export function storeArrangeAcc(data) {
    return { type: "STORE_ARRANGE_ACCOUNT_DNA", data }
}

export function popAccDelete(index) {
    return { type: "POP_ACC_DELETE", index }
}

export function storeVehicles(data) {
    return { type: "STORE_VEHICLES", data }
}
export function getVehicles(bk, per) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/vehicle/getVehicale", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())               
            .then((json) => {
                dispatch(storeVehicles(json))
                dispatch(stopLoading())
            })
    }
}

export function storeVehicleColoumn(data) {
    return { type: "STORE_VEHICLE_COLOUMN", data }
}
export function storeVehicleRowColoumn(data) {
    return { type: "STORE_VEHICLE_ROW_COLOUMN", data }
}
export function getColoumn(value, bk, per, num) {
    return function(dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "/vehicle/selectedVehiclesColums?vID=" + value + "&p=" + per + "&b=" + bk, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "X-Auth-Token": xauth
            }
        }).then(response => response.json())               
            .then((json) => {
                if(num===0) {
                    dispatch(storeVehicleColoumn(json))
                }
                else if(num===1) {
                    dispatch(storeVehicleRowColoumn(json))
                }
                dispatch(stopLoading())
            })
    }
}
