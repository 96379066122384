// eslint-disable-next-line complexity
export default function reducer(
    state = {
        mainTitle: { id: 0, parentId: 0, show: false, description: "", bold: 0, trsType: "", source: null, value: 0 },
        winbook_data: { id: 0, dna: null, parentId: 0, show: false, trsType: "", description: "", bold: 0, source: null, vehicle: null, value: 0 },
        winbook_data_update: { id: 0, dna: null, parentId: 0, show: false, trsType: "", description: "", bold: 0, source: null, value: 0 },
        winbook_data_array: [],
        winbookStore:[],
        storeDna: null,
        arrane: [],
        vehicles: [],
        vehCol:[],
        rowVehCol:[]
    }, action) {
    switch (action.type) {
        case "STORE_DNA_REPORT" : {
            return { ...state, storeDna: action.report }
        }
        case "STORE_MAIN_TITLE_DNA": {
            return { ...state, mainTitle: { ...state.mainTitle, id: action.data.id, show: action.data.show, description: action.data.description, bold: action.data.bold, trsType: action.data.trsType } }
        }
        case "HANDLE_GROUP_EVENT": {
            return { ...state, mainTitle: { ...state.mainTitle, [action.name]: action.value } }
        }
        case "HANDLE_WINBOOK_DATA": {
            return { ...state,
                        winbook_data: { 
                            ...state.winbook_data, 
                                id: action.data.id,
                                parentId: action.data.parentId,
                                show: action.data.show,
                                trsType: action.data.trsType,
                                description: action.data.description,
                                bold: action.data.bold,
                                source: action.data.source,
                                value: action.data.value,
                                vehicle: action.data.vehicle
                            } }
        }
        case "HANDLE_EVENT_WINBOOK_DATA": {
            return {
                ...state, winbook_data: {
                    ...state.winbook_data, [action.name]: action.value,
                    [action.name === "trsType" ? "description" : null]: "",
                    [action.name === "trsType" ? "source" : null]: null,
                    [action.name === "trsType" ? "vehicle" : null]: null,
                    [action.name === "trsType" ? "value" : null]: 0,
                    [action.name === "trsType" ? "dna" : null]: ""
                }
            }
        }
        case "ADD_WINBOOK_DATA_ARRAY": {
            return { ...state, 
                winbook_data_array: [...state.winbook_data_array, action.data], 
                winbook_data: { ...state.winbook_data, 
                     id: 0, dna: "", show: true, trsType: "winbook", description: "", bold: 0, source: null, vehicle:null, value: 0 },
                winbookStore:[]
        }
        }
        case "ADD_WINBOOK_DATA_STORE": {
            return { ...state, winbookStore: action.data}
        }
        case "HANDLE_WINBOOK_ARRAY_EVENT": {
            return { ...state, 
                winbook_data_array: Object.values({
                    ...state.winbook_data_array,
                    [action.index]:{
                        ...state.winbook_data_array[action.index],
                        [action.name]:action.value,
                        [action.name === "trsType" ? "description" : null]: "",
                        [action.name === "trsType" ? "source" : null]: null,
                        [action.name === "trsType" ? "value" : null]: 0,
                        [action.name === "trsType" ? "dna" : null]: 0
                    }
                })
            }
        }
        case "CLEAR_DNA_ACCOUNTS": {
            return {
                ...state,
                winbook_data: { id: 0,dna: null,parentId: 0, show: false, trsType: "", description: "", bold: 0, source: null, vehicle:null, value: 0 },
                winbook_data_array: [],
            }
        }
        case "STORE_ARRANGE_ACCOUNT_DNA": {
            return {
                ...state,
                arrane: action.data
            }
        }
        case "HANDLE_UPDATE_DNA": {
            return {
                ...state,
                winbook_data_update: action.data
            }

        }
        case "CLEAR_UPDATE_DATA": {
            return {
                ...state,
                winbook_data_update: { id: 0,dna: null, parentId: 0, show: false, trsType: "", description: "", bold: 0, source: null, value: 0 }
            }
        }
        case "CHANGE_UPDATE_DATA": {
            return {
                ...state,
                winbook_data_update: {
                ...state.winbook_data_update,
                    [action.name]:action.value
                 }
            }
        }
        case "CHANGE_UPDATE_DATA_TRS": {
            return {
                ...state,
                winbook_data_update: {
                    ...state.winbook_data_update,
                    dna: null,
                    show: true,
                    trsType: action.value,
                    description: "",
                    bold: 0, 
                    source: null,
                    value: 0
                } 
            }
        }
        case "POP_ACC_DELETE": {
            return {
                ...state,
                winbook_data_array:[
                    ...state.winbook_data_array.slice(0, action.index),
                    ...state.winbook_data_array.slice(action.index + 1)
                ]
            }
        }
        case "STORE_VEHICLES": {
            return {
                ...state,
                vehicles: action.data
            }
        }
        case "STORE_VEHICLE_COLOUMN": {
            return {
                ...state,
                vehCol: action.data
            } 
        }
        case "STORE_VEHICLE_ROW_COLOUMN": {
            return {
                ...state,
                rowVehCol: action.data
            } 
        }
        default:
            break

    }
    return state
}
